.ngx-loading-logo {
    width: 275px !important;
    height: auto !important;
    top: calc((52% - 20px) - 0px) !important;
}

table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting::after {
    opacity: .3 !important;
}

table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_desc:after {
    opacity: .8 !important;
}

.daterangepicker .applyBtn,
.daterangepicker .cancelBtn {
    background: var(--bs-primary-color);
    border-color: var(--bs-primary-color);
    color: var(--bs-text-white);
    border-radius: 5px;
}

.daterangepicker .applyBtn:hover,
.daterangepicker .cancelBtn:hover {
    background: var(--bs-secondary-color);
    color: var(--bs-text-white);
    border-color: var(--bs-secondary-color);
    border-radius: 5px;
}

#dt-claim-procedure-table tbody tr:nth-child(even),
#dt-claims-table tbody tr:nth-child(even) {
    background: var(--bs-table-row);
}