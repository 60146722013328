@media (min-width: 992px) {
    .feature-box-14 > div:nth-child(even) { border-bottom: 0}
    .feature-box-14 > div:nth-child(odd) { border-bottom: 0}

    /* top logo */
    .navbar-brand-top .navbar-collapse.collapse.display-inline-block { display: inline-block !important; vertical-align: middle;}
    .navbar-brand-top .nav-header-container { text-align: center;}
    .navbar-brand-top .nav-header-container .row { display: block !important; display: block !important; display: block !important;}
    .navbar-brand-top .brand-top-menu-right { vertical-align: middle; position: relative; }
}

@media (min-width: 576px) {
    .feature-box-14 > div:nth-last-child(2) { border-bottom: 0}
}

@media (max-width: 1600px) {
    /* grid */
    .grid.xl-grid-6col li { width: 16.67%; }
    .grid.xl-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.xl-grid-5col li { width: 20%; }
    .grid.xl-grid-5col li.grid-item-double { width: 40%; }
    .grid.xl-grid-4col li { width: 25%; }
    .grid.xl-grid-4col li.grid-item-double { width: 50%; }
    .grid.xl-grid-3col li { width: 33.33%; }
    .grid.xl-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.xl-grid-2col li { width: 50%; }
    .grid.xl-grid-2col li.grid-item-double { width: 100%; }
    .grid.xl-grid-1col li { width: 100%; }
    .grid.xl-grid-1col li.grid-item-double { width: 100%; }

    /* margin */
    .xl-margin-one-all {margin:1%;}
    .xl-margin-one-half-all {margin:1.5%;}
    .xl-margin-two-all {margin:2%;}
    .xl-margin-two-half-all {margin:2.5%;}
    .xl-margin-three-all {margin:3%;}
    .xl-margin-three-half-all {margin:3.5%;}
    .xl-margin-four-all {margin:4%;}
    .xl-margin-four-half-all {margin:4.5%;}
    .xl-margin-five-all {margin:5%;}
    .xl-margin-five-half-all {margin:5.5%;}
    .xl-margin-six-all {margin:6%;}
    .xl-margin-six-half-all {margin:6.5%;}
    .xl-margin-seven-all {margin:7%;}
    .xl-margin-seven-half-all {margin:7.5%;}
    .xl-margin-eight-all {margin:8%;}
    .xl-margin-eight-half-all {margin:8.5%;}
    .xl-margin-nine-all {margin:9%;}
    .xl-margin-nine-half-all {margin:9.5%;}
    .xl-margin-ten-all {margin:10%;}
    .xl-margin-ten-half-all {margin:10.5%;}
    .xl-margin-eleven-all {margin:11%;}
    .xl-margin-eleven-half-all {margin:11.5%;}
    .xl-margin-twelve-all {margin:12%;}
    .xl-margin-twelve-half-all {margin:12.5%;}
    .xl-margin-thirteen-all {margin:13%;}
    .xl-margin-thirteen-half-all {margin:13.5%;}
    .xl-margin-fourteen-all {margin:14%;}
    .xl-margin-fourteen-half-all {margin:14.5%;}
    .xl-margin-fifteen-all {margin:15%;}
    .xl-margin-fifteen-half-all {margin:15.5%;}
    .xl-margin-sixteen-all {margin:16%;}
    .xl-margin-sixteen-half-all {margin:16.5%;}
    .xl-margin-seventeen-all {margin:17%;}
    .xl-margin-seventeen-half-all {margin:17.5%;}
    .xl-margin-eighteen-all {margin:18%;}
    .xl-margin-eighteen-half-all {margin:18.5%;}
    .xl-margin-nineteen-all {margin:19%;}
    .xl-margin-nineteen-half-all {margin:19.5%;}
    .xl-margin-twenty-all {margin:20%;}
    .xl-margin-twenty-half-all {margin:20.5%;}
    .xl-margin-twenty-one-all {margin:21%;}
    .xl-margin-twenty-one-half-all {margin:21.5%;}
    .xl-margin-twenty-two-all {margin:22%;}
    .xl-margin-twenty-two-half-all {margin:22.5%;}
    .xl-margin-twenty-three-all {margin:23%;}
    .xl-margin-twenty-three-half-all {margin:23.5%;}
    .xl-margin-twenty-four-all {margin:24%;}
    .xl-margin-twenty-four-half-all {margin:24.5%;}
    .xl-margin-twenty-five-all {margin:25%;}
    .xl-margin-5px-all {margin:5px;}
    .xl-margin-10px-all {margin:10px;}
    .xl-margin-15px-all {margin:15px;}
    .xl-margin-20px-all {margin:20px;}
    .xl-margin-25px-all {margin:25px;}
    .xl-margin-30px-all {margin:30px;}
    .xl-margin-35px-all {margin:35px;}
    .xl-margin-40px-all {margin:40px;}
    .xl-margin-45px-all {margin:45px;}
    .xl-margin-50px-all {margin:50px;}
    .xl-margin-55px-all {margin:55px;}
    .xl-margin-60px-all {margin:60px;}
    .xl-margin-65px-all {margin:65px;}
    .xl-margin-70px-all {margin:70px;}
    .xl-margin-75px-all {margin:75px;}
    .xl-margin-80px-all {margin:80px;}
    .xl-margin-85px-all {margin:85px;}
    .xl-margin-90px-all {margin:90px;}
    .xl-margin-95px-all {margin:95px;}
    .xl-margin-100px-all {margin:100px;}

    .xl-no-margin {margin:0 !important}
    .xl-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important}
    .xl-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important}
    .xl-no-margin-top {margin-top:0 !important}
    .xl-no-margin-bottom {margin-bottom:0 !important}
    .xl-no-margin-left {margin-left:0 !important}
    .xl-no-margin-right {margin-right:0 !important}
    .xl-margin-lr-auto {margin-left:auto !important; margin-right:auto !important}
    .xl-margin-auto {margin: 0 auto !important;}

    /* margin top */
    .xl-margin-one-top {margin-top:1%;}
    .xl-margin-one-half-top {margin-top:1.5%;}
    .xl-margin-two-top {margin-top:2%;}
    .xl-margin-two-half-top {margin-top:2.5%;}
    .xl-margin-three-top {margin-top:3%;}
    .xl-margin-three-half-top {margin-top:3.5%;}
    .xl-margin-four-top {margin-top:4%;}
    .xl-margin-four-half-top {margin-top:4.5%;}
    .xl-margin-five-top {margin-top:5%;}
    .xl-margin-five-half-top {margin-top:5.5%;}
    .xl-margin-six-top {margin-top:6%;}
    .xl-margin-six-half-top {margin-top:6.5%;}
    .xl-margin-seven-top {margin-top:7%;}
    .xl-margin-seven-half-top {margin-top:7.5%;}
    .xl-margin-eight-top {margin-top:8%;}
    .xl-margin-eight-half-top {margin-top:8.5%;}
    .xl-margin-nine-top {margin-top:9%;}
    .xl-margin-nine-half-top {margin-top:9.5%;}
    .xl-margin-ten-top {margin-top:10%;}
    .xl-margin-ten-half-top {margin-top:10.5%;}
    .xl-margin-eleven-top {margin-top:11%;}
    .xl-margin-eleven-half-top {margin-top:11.5%;}
    .xl-margin-twelve-top {margin-top:12%;}
    .xl-margin-twelve-half-top {margin-top:12.5%;}
    .xl-margin-thirteen-top {margin-top:13%;}
    .xl-margin-thirteen-half-top {margin-top:13.5%;}
    .xl-margin-fourteen-top {margin-top:14%;}
    .xl-margin-fourteen-half-top {margin-top:14.5%;}
    .xl-margin-fifteen-top {margin-top:15%;}
    .xl-margin-fifteen-half-top {margin-top:15.5%;}
    .xl-margin-sixteen-top {margin-top:16%;}
    .xl-margin-sixteen-half-top {margin-top:16.5%;}
    .xl-margin-seventeen-top {margin-top:17%;}
    .xl-margin-seventeen-half-top {margin-top:17.5%;}
    .xl-margin-eighteen-top {margin-top:18%;}
    .xl-margin-eighteen-half-top {margin-top:18.5%;}
    .xl-margin-nineteen-top {margin-top:19%;}
    .xl-margin-nineteen-half-top {margin-top:19.5%;}
    .xl-margin-twenty-top {margin-top:20%;}
    .xl-margin-twenty-half-top {margin-top:20.5%;}
    .xl-margin-twenty-one-top {margin-top:21%;}
    .xl-margin-twenty-one-half-top {margin-top:21.5%;}
    .xl-margin-twenty-two-top {margin-top:22%;}
    .xl-margin-twenty-two-half-top {margin-top:22.5%;}
    .xl-margin-twenty-three-top {margin-top:23%;}
    .xl-margin-twenty-three-half-top {margin-top:23.5%;}
    .xl-margin-twenty-four-top {margin-top:24%;}
    .xl-margin-twenty-four-half-top {margin-top:24.5%;}
    .xl-margin-twenty-five-top {margin-top:25%;}
    .xl-margin-5px-top {margin-top:5px;}
    .xl-margin-10px-top {margin-top:10px;}
    .xl-margin-15px-top {margin-top:15px;}
    .xl-margin-20px-top {margin-top:20px;}
    .xl-margin-25px-top {margin-top:25px;}
    .xl-margin-30px-top {margin-top:30px;}
    .xl-margin-35px-top {margin-top:35px;}
    .xl-margin-40px-top {margin-top:40px;}
    .xl-margin-45px-top {margin-top:45px;}
    .xl-margin-50px-top {margin-top:50px;}
    .xl-margin-55px-top {margin-top:55px;}
    .xl-margin-60px-top {margin-top:60px;}
    .xl-margin-65px-top {margin-top:65px;}
    .xl-margin-70px-top {margin-top:70px;}
    .xl-margin-75px-top {margin-top:75px;}
    .xl-margin-80px-top {margin-top:80px;}
    .xl-margin-85px-top {margin-top:85px;}
    .xl-margin-90px-top {margin-top:90px;}
    .xl-margin-95px-top {margin-top:95px;}
    .xl-margin-100px-top {margin-top:100px;}

    /* margin bottom */
    .xl-margin-one-bottom {margin-bottom:1%;}
    .xl-margin-one-half-bottom {margin-bottom:1.5%;}
    .xl-margin-two-bottom {margin-bottom:2%;}
    .xl-margin-two-half-bottom {margin-bottom:2.5%;}
    .xl-margin-three-bottom {margin-bottom:3%;}
    .xl-margin-three-half-bottom {margin-bottom:3.5%;}
    .xl-margin-four-bottom {margin-bottom:4%;}
    .xl-margin-four-half-bottom {margin-bottom:4.5%;}
    .xl-margin-five-bottom {margin-bottom:5%;}
    .xl-margin-five-half-bottom {margin-bottom:5.5%;}
    .xl-margin-six-bottom {margin-bottom:6%;}
    .xl-margin-six-half-bottom {margin-bottom:6.5%;}
    .xl-margin-seven-bottom {margin-bottom:7%;}
    .xl-margin-seven-half-bottom {margin-bottom:7.5%;}
    .xl-margin-eight-bottom {margin-bottom:8%;}
    .xl-margin-eight-half-bottom {margin-bottom:8.5%;}
    .xl-margin-nine-bottom {margin-bottom:9%;}
    .xl-margin-nine-half-bottom {margin-bottom:9.5%;}
    .xl-margin-ten-bottom {margin-bottom:10%;}
    .xl-margin-ten-half-bottom {margin-bottom:10.5%;}
    .xl-margin-eleven-bottom {margin-bottom:11%;}
    .xl-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .xl-margin-twelve-bottom {margin-bottom:12%;}
    .xl-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .xl-margin-thirteen-bottom {margin-bottom:13%;}
    .xl-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .xl-margin-fourteen-bottom {margin-bottom:14%;}
    .xl-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .xl-margin-fifteen-bottom {margin-bottom:15%;}
    .xl-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .xl-margin-sixteen-bottom {margin-bottom:16%;}
    .xl-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .xl-margin-seventeen-bottom {margin-bottom:17%;}
    .xl-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .xl-margin-eighteen-bottom {margin-bottom:18%;}
    .xl-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .xl-margin-nineteen-bottom {margin-bottom:19%;}
    .xl-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .xl-margin-twenty-bottom {margin-bottom:20%;}
    .xl-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .xl-margin-twenty-one-bottom {margin-bottom:21%;}
    .xl-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .xl-margin-twenty-two-bottom {margin-bottom:22%;}
    .xl-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .xl-margin-twenty-three-bottom {margin-bottom:23%;}
    .xl-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .xl-margin-twenty-four-bottom {margin-bottom:24%;}
    .xl-margin-twenty-four-half-bottom {margin-bottom:24.5%;}
    .xl-margin-twenty-five-bottom {margin-bottom:25%;}
    .xl-margin-5px-bottom {margin-bottom:5px;}
    .xl-margin-10px-bottom {margin-bottom:10px;}
    .xl-margin-15px-bottom {margin-bottom:15px;}
    .xl-margin-20px-bottom {margin-bottom:20px;}
    .xl-margin-25px-bottom {margin-bottom:25px;}
    .xl-margin-30px-bottom {margin-bottom:30px;}
    .xl-margin-35px-bottom {margin-bottom:35px;}
    .xl-margin-40px-bottom {margin-bottom:40px;}
    .xl-margin-45px-bottom {margin-bottom:45px;}
    .xl-margin-50px-bottom {margin-bottom:50px;}
    .xl-margin-55px-bottom {margin-bottom:55px;}
    .xl-margin-60px-bottom {margin-bottom:60px;}
    .xl-margin-65px-bottom {margin-bottom:65px;}
    .xl-margin-70px-bottom {margin-bottom:70px;}
    .xl-margin-75px-bottom {margin-bottom:75px;}
    .xl-margin-80px-bottom {margin-bottom:80px;}
    .xl-margin-85px-bottom {margin-bottom:85px;}
    .xl-margin-90px-bottom {margin-bottom:90px;}
    .xl-margin-95px-bottom {margin-bottom:95px;}
    .xl-margin-100px-bottom {margin-bottom:100px;}

    /* margin right */
    .xl-margin-one-right {margin-right:1%;}
    .xl-margin-one-half-right {margin-right:1.5%;}
    .xl-margin-two-right {margin-right:2%;}
    .xl-margin-two-half-right {margin-right:2.5%;}
    .xl-margin-three-right {margin-right:3%;}
    .xl-margin-three-half-right {margin-right:3.5%;}
    .xl-margin-four-right {margin-right:4%;}
    .xl-margin-four-half-right {margin-right:4.5%;}
    .xl-margin-five-right {margin-right:5%;}
    .xl-margin-five-half-right {margin-right:5.5%;}
    .xl-margin-six-right {margin-right:6%;}
    .xl-margin-six-half-right {margin-right:6.5%;}
    .xl-margin-seven-right {margin-right:7%;}
    .xl-margin-seven-half-right {margin-right:7.5%;}
    .xl-margin-eight-right {margin-right:8%;}
    .xl-margin-eight-half-right {margin-right:8.5%;}
    .xl-margin-nine-right {margin-right:9%;}
    .xl-margin-nine-half-right {margin-right:9.5%;}
    .xl-margin-ten-right {margin-right:10%;}
    .xl-margin-ten-half-right {margin-right:10.5%;}
    .xl-margin-eleven-right {margin-right:11%;}
    .xl-margin-eleven-half-right {margin-right:11.5%;}
    .xl-margin-twelve-right {margin-right:12%;}
    .xl-margin-twelve-half-right {margin-right:12.5%;}
    .xl-margin-thirteen-right {margin-right:13%;}
    .xl-margin-thirteen-half-right {margin-right:13.5%;}
    .xl-margin-fourteen-right {margin-right:14%;}
    .xl-margin-fourteen-half-right {margin-right:14.5%;}
    .xl-margin-fifteen-right {margin-right:15%;}
    .xl-margin-fifteen-half-right {margin-right:15.5%;}
    .xl-margin-sixteen-right {margin-right:16%;}
    .xl-margin-sixteen-half-right {margin-right:16.5%;}
    .xl-margin-seventeen-right {margin-right:17%;}
    .xl-margin-seventeen-half-right {margin-right:17.5%;}
    .xl-margin-eighteen-right {margin-right:18%;}
    .xl-margin-eighteen-half-right {margin-right:18.5%;}
    .xl-margin-nineteen-right {margin-right:19%;}
    .xl-margin-nineteen-half-right {margin-right:19.5%;}
    .xl-margin-twenty-right {margin-right:20%;}
    .xl-margin-twenty-half-right {margin-right:20.5%;}
    .xl-margin-twenty-one-right {margin-right:21%;}
    .xl-margin-twenty-one-half-right {margin-right:21.5%;}
    .xl-margin-twenty-two-right {margin-right:22%;}
    .xl-margin-twenty-two-half-right {margin-right:22.5%;}
    .xl-margin-twenty-three-right {margin-right:23%;}
    .xl-margin-twenty-three-half-right {margin-right:23.5%;}
    .xl-margin-twenty-four-right {margin-right:24%;}
    .xl-margin-twenty-four-half-right {margin-right:24.5%;}
    .xl-margin-twenty-five-right {margin-right:25%;}
    .xl-margin-10px-right {margin-right:10px;}
    .xl-margin-15px-right {margin-right:15px;}
    .xl-margin-20px-right {margin-right:20px;}
    .xl-margin-25px-right {margin-right:25px;}
    .xl-margin-30px-right {margin-right:30px;}
    .xl-margin-35px-right {margin-right:35px;}
    .xl-margin-40px-right {margin-right:40px;}
    .xl-margin-45px-right {margin-right:45px;}
    .xl-margin-50px-right {margin-right:50px;}
    .xl-margin-55px-right {margin-right:55px;}
    .xl-margin-60px-right {margin-right:60px;}
    .xl-margin-65px-right {margin-right:65px;}
    .xl-margin-70px-right {margin-right:70px;}
    .xl-margin-75px-right {margin-right:75px;}
    .xl-margin-80px-right {margin-right:80px;}
    .xl-margin-85px-right {margin-right:85px;}
    .xl-margin-90px-right {margin-right:90px;}
    .xl-margin-95px-right {margin-right:95px;}
    .xl-margin-100px-right {margin-right:100px;}

    /* margin left */
    .xl-margin-one-left {margin-left:1%;}
    .xl-margin-one-half-left {margin-left:1.5%;}
    .xl-margin-two-left {margin-left:2%;}
    .xl-margin-two-half-left {margin-left:2.5%;}
    .xl-margin-three-left {margin-left:3%;}
    .xl-margin-three-half-left {margin-left:3.5%;}
    .xl-margin-four-left {margin-left:4%;}
    .xl-margin-four-half-left {margin-left:4.5%;}
    .xl-margin-five-left {margin-left:5%;}
    .xl-margin-five-half-left {margin-left:5.5%;}
    .xl-margin-six-left {margin-left:6%;}
    .xl-margin-six-half-left {margin-left:6.5%;}
    .xl-margin-seven-left {margin-left:7%;}
    .xl-margin-seven-half-left {margin-left:7.5%;}
    .xl-margin-eight-left {margin-left:8%;}
    .xl-margin-eight-half-left {margin-left:8.5%;}
    .xl-margin-nine-left {margin-left:9%;}
    .xl-margin-nine-half-left {margin-left:9.5%;}
    .xl-margin-ten-left {margin-left:10%;}
    .xl-margin-ten-half-left {margin-left:10.5%;}
    .xl-margin-eleven-left {margin-left:11%;}
    .xl-margin-eleven-half-left {margin-left:11.5%;}
    .xl-margin-twelve-left {margin-left:12%;}
    .xl-margin-twelve-half-left {margin-left:12.5%;}
    .xl-margin-thirteen-left {margin-left:13%;}
    .xl-margin-thirteen-half-left {margin-left:13.5%;}
    .xl-margin-fourteen-left {margin-left:14%;}
    .xl-margin-fourteen-half-left {margin-left:14.5%;}
    .xl-margin-fifteen-left {margin-left:15%;}
    .xl-margin-fifteen-half-left {margin-left:15.5%;}
    .xl-margin-sixteen-left {margin-left:16%;}
    .xl-margin-sixteen-half-left {margin-left:16.5%;}
    .xl-margin-seventeen-left {margin-left:17%;}
    .xl-margin-seventeen-half-left {margin-left:17.5%;}
    .xl-margin-eighteen-left {margin-left:18%;}
    .xl-margin-eighteen-half-left {margin-left:18.5%;}
    .xl-margin-nineteen-left {margin-left:19%;}
    .xl-margin-nineteen-half-left {margin-left:19.5%;}
    .xl-margin-twenty-left {margin-left:20%;}
    .xl-margin-twenty-half-left {margin-left:20.5%;}
    .xl-margin-twenty-one-left {margin-left:21%;}
    .xl-margin-twenty-one-half-left {margin-left:21.5%;}
    .xl-margin-twenty-two-left {margin-left:22%;}
    .xl-margin-twenty-two-half-left {margin-left:22.5%;}
    .xl-margin-twenty-three-left {margin-left:23%;}
    .xl-margin-twenty-three-half-left {margin-left:23.5%;}
    .xl-margin-twenty-four-left {margin-left:24%;}
    .xl-margin-twenty-four-half-left {margin-left:24.5%;}
    .xl-margin-twenty-five-left {margin-left:25%;}
    .xl-margin-5px-left {margin-left:5px;}
    .xl-margin-10px-left {margin-left:10px;}
    .xl-margin-15px-left {margin-left:15px;}
    .xl-margin-20px-left {margin-left:20px;}
    .xl-margin-25px-left {margin-left:25px;}
    .xl-margin-30px-left {margin-left:30px;}
    .xl-margin-35px-left {margin-left:35px;}
    .xl-margin-40px-left {margin-left:40px;}
    .xl-margin-45px-left {margin-left:45px;}
    .xl-margin-50px-left {margin-left:50px;}
    .xl-margin-55px-left {margin-left:55px;}
    .xl-margin-60px-left {margin-left:60px;}
    .xl-margin-65px-left {margin-left:65px;}
    .xl-margin-70px-left {margin-left:70px;}
    .xl-margin-75px-left {margin-left:75px;}
    .xl-margin-80px-left {margin-left:80px;}
    .xl-margin-85px-left {margin-left:85px;}
    .xl-margin-90px-left {margin-left:90px;}
    .xl-margin-95px-left {margin-left:95px;}
    .xl-margin-100px-left {margin-left:100px;}

    /* margin left right */
    .xl-margin-one-lr {margin-left:1%; margin-right:1%;}
    .xl-margin-one-lr {margin-left:1.5%; margin-right:1.5%;}
    .xl-margin-two-lr {margin-left:2%; margin-right:2%;}
    .xl-margin-two-lr {margin-left:2.5%; margin-right:2.5%;}
    .xl-margin-three-lr {margin-left:3%; margin-right:3%;}
    .xl-margin-three-lr {margin-left:3.5%; margin-right:3.5%;}
    .xl-margin-four-lr {margin-left:4%; margin-right:4%;}
    .xl-margin-four-lr {margin-left:4.5%; margin-right:4.5%;}
    .xl-margin-five-lr {margin-left:5%; margin-right:5%;}
    .xl-margin-five-lr {margin-left:5.5%; margin-right:5.5%;}
    .xl-margin-six-lr {margin-left:6%; margin-right:6%;}
    .xl-margin-six-lr {margin-left:6.5%; margin-right:6.5%;}
    .xl-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .xl-margin-seven-lr {margin-left:7.5%; margin-right:7.5%;}
    .xl-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .xl-margin-eight-lr {margin-left:8.5%; margin-right:8.5%;}
    .xl-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .xl-margin-nine-lr {margin-left:9.5%; margin-right:9.5%;}
    .xl-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .xl-margin-ten-lr {margin-left:10.5%; margin-right:10.5%;}
    .xl-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .xl-margin-eleven-lr {margin-left:11.5%; margin-right:11.5%;}
    .xl-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .xl-margin-twelve-lr {margin-left:12.5%; margin-right:12.5%;}
    .xl-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .xl-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .xl-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .xl-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .xl-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .xl-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .xl-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .xl-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .xl-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .xl-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .xl-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .xl-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .xl-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .xl-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .xl-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .xl-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .xl-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .xl-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .xl-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .xl-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .xl-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .xl-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .xl-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .xl-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .xl-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .xl-margin-5px-lr {margin-left:5px; margin-right:5px;}
    .xl-margin-10px-lr {margin-left:10px; margin-right:10px;}
    .xl-margin-15px-lr {margin-left:15px; margin-right:15px;}
    .xl-margin-20px-lr {margin-left:20px; margin-right:20px;}
    .xl-margin-25px-lr {margin-left:25px; margin-right:25px;}
    .xl-margin-30px-lr {margin-left:30px; margin-right:30px;}
    .xl-margin-35px-lr {margin-left:35px; margin-right:35px;}
    .xl-margin-40px-lr {margin-left:40px; margin-right:40px;}
    .xl-margin-45px-lr {margin-left:45px; margin-right:45px;}
    .xl-margin-50px-lr {margin-left:50px; margin-right:50px;}
    .xl-margin-55px-lr {margin-left:55px; margin-right:55px;}
    .xl-margin-60px-lr {margin-left:60px; margin-right:60px;}
    .xl-margin-65px-lr {margin-left:65px; margin-right:65px;}
    .xl-margin-70px-lr {margin-left:70px; margin-right:70px;}
    .xl-margin-75px-lr {margin-left:75px; margin-right:75px;}
    .xl-margin-80px-lr {margin-left:80px; margin-right:80px;}
    .xl-margin-85px-lr {margin-left:85px; margin-right:85px;}
    .xl-margin-90px-lr {margin-left:90px; margin-right:90px;}
    .xl-margin-95px-lr {margin-left:95px; margin-right:95px;}
    .xl-margin-100px-lr {margin-left:100px; margin-right:100px;}

    /* margin top bottom */
    .xl-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .xl-margin-one-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .xl-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .xl-margin-two-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .xl-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .xl-margin-three-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .xl-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .xl-margin-four-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .xl-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .xl-margin-five-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .xl-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .xl-margin-six-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .xl-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .xl-margin-seven-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .xl-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .xl-margin-eight-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .xl-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .xl-margin-nine-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .xl-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .xl-margin-ten-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .xl-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .xl-margin-eleven-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .xl-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .xl-margin-twelve-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .xl-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .xl-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .xl-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .xl-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .xl-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .xl-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .xl-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .xl-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .xl-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .xl-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .xl-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .xl-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .xl-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .xl-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .xl-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .xl-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .xl-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .xl-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .xl-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .xl-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .xl-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .xl-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .xl-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .xl-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .xl-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .xl-margin-5px-tb {margin-top:5px; margin-bottom:5px;}
    .xl-margin-10px-tb {margin-top:10px; margin-bottom:10px;}
    .xl-margin-15px-tb {margin-top:15px; margin-bottom:15px;}
    .xl-margin-20px-tb {margin-top:20px; margin-bottom:20px;}
    .xl-margin-25px-tb {margin-top:25px; margin-bottom:25px;}
    .xl-margin-30px-tb {margin-top:30px; margin-bottom:30px;}
    .xl-margin-35px-tb {margin-top:35px; margin-bottom:35px;}
    .xl-margin-40px-tb {margin-top:40px; margin-bottom:40px;}
    .xl-margin-45px-tb {margin-top:45px; margin-bottom:45px;}
    .xl-margin-50px-tb {margin-top:50px; margin-bottom:50px;}
    .xl-margin-55px-tb {margin-top:55px; margin-bottom:55px;}
    .xl-margin-60px-tb {margin-top:60px; margin-bottom:60px;}
    .xl-margin-65px-tb {margin-top:65px; margin-bottom:65px;}
    .xl-margin-70px-tb {margin-top:70px; margin-bottom:70px;}
    .xl-margin-75px-tb {margin-top:75px; margin-bottom:75px;}
    .xl-margin-80px-tb {margin-top:80px; margin-bottom:80px;}
    .xl-margin-85px-tb {margin-top:85px; margin-bottom:85px;}
    .xl-margin-90px-tb {margin-top:90px; margin-bottom:90px;}
    .xl-margin-95px-tb {margin-top:95px; margin-bottom:95px;}
    .xl-margin-100px-tb {margin-top:100px; margin-bottom:100px;}

    /* padding */
    .xl-no-padding {padding:0 !important}
    .xl-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important}
    .xl-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important}
    .xl-no-padding-top {padding-top:0 !important}
    .xl-no-padding-bottom {padding-bottom:0 !important}
    .xl-no-padding-left {padding-left:0 !important}
    .xl-no-padding-right {padding-right:0 !important}
    .xl-padding-one-all {padding:1%;}
    .xl-padding-one-half-all {padding:1.5%;}
    .xl-padding-two-all {padding:2%;}
    .xl-padding-two-half-all {padding:2.5%;}
    .xl-padding-three-all {padding:3%;}
    .xl-padding-three-half-all {padding:3.5%;}
    .xl-padding-four-all {padding:4%;}
    .xl-padding-four-half-all {padding:4.5%;}
    .xl-padding-five-all {padding:5%;}
    .xl-padding-five-half-all {padding:5.5%;}
    .xl-padding-six-all {padding:6%;}
    .xl-padding-six-half-all {padding:6.5%;}
    .xl-padding-seven-all {padding:7%;}
    .xl-padding-seven-half-all {padding:7.5%;}
    .xl-padding-eight-all {padding:8%;}
    .xl-padding-eight-half-all {padding:8.5%;}
    .xl-padding-nine-all {padding:9%;}
    .xl-padding-nine-half-all {padding:9.5%;}
    .xl-padding-ten-all {padding:10%;}
    .xl-padding-ten-half-all {padding:10.5%;}
    .xl-padding-eleven-all {padding:11%;}
    .xl-padding-eleven-half-all {padding:11.5%;}
    .xl-padding-twelve-all {padding:12%;}
    .xl-padding-twelve-half-all {padding:12.5%;}
    .xl-padding-thirteen-all {padding:13%;}
    .xl-padding-thirteen-half-all {padding:13.5%;}
    .xl-padding-fourteen-all {padding:14%;}
    .xl-padding-fourteen-half-all {padding:14.5%;}
    .xl-padding-fifteen-all {padding:15%;}
    .xl-padding-fifteen-half-all {padding:15.5%;}
    .xl-padding-sixteen-all {padding:16%;}
    .xl-padding-sixteen-half-all {padding:16.5%;}
    .xl-padding-seventeen-all {padding:17%;}
    .xl-padding-seventeen-half-all {padding:17.5%;}
    .xl-padding-eighteen-all {padding:18%;}
    .xl-padding-eighteen-half-all {padding:18.5%;}
    .xl-padding-nineteen-all {padding:19%;}
    .xl-padding-nineteen-half-all {padding:19.5%;}
    .xl-padding-twenty-all {padding:20%;}
    .xl-padding-twenty-half-all {padding:20.5%;}
    .xl-padding-twenty-one-all {padding:21%;}
    .xl-padding-twenty-one-half-all {padding:21.5%;}
    .xl-padding-twenty-two-all {padding:22%;}
    .xl-padding-twenty-two-half-all {padding:22.5%;}
    .xl-padding-twenty-three-all {padding:23%;}
    .xl-padding-twenty-three-half-all {padding:23.5%;}
    .xl-padding-twenty-four-all {padding:24%;}
    .xl-padding-twenty-four-half-all {padding:24.5%;}
    .xl-padding-twenty-five-all {padding:25%;}
    .xl-padding-5px-all {padding:5px;}
    .xl-padding-10px-all {padding:10px;}
    .xl-padding-15px-all {padding:15px;}
    .xl-padding-20px-all {padding:20px;}
    .xl-padding-25px-all {padding:25px;}
    .xl-padding-30px-all {padding:30px;}
    .xl-padding-35px-all {padding:35px;}
    .xl-padding-40px-all {padding:40px;}
    .xl-padding-45px-all {padding:45px;}
    .xl-padding-50px-all {padding:50px;}
    .xl-padding-55px-all {padding:55px;}
    .xl-padding-60px-all {padding:60px;}
    .xl-padding-65px-all {padding:65px;}
    .xl-padding-70px-all {padding:70px;}
    .xl-padding-75px-all {padding:75px;}
    .xl-padding-80px-all {padding:80px;}
    .xl-padding-85px-all {padding:85px;}
    .xl-padding-90px-all {padding:90px;}
    .xl-padding-95px-all {padding:95px;}
    .xl-padding-100px-all {padding:100px;}

    /* padding top */
    .xl-padding-one-top {padding-top:1%;}
    .xl-padding-one-half-top {padding-top:1.5%;}
    .xl-padding-two-top {padding-top:2%;}
    .xl-padding-two-half-top {padding-top:2.5%;}
    .xl-padding-three-top {padding-top:3%;}
    .xl-padding-three-half-top {padding-top:3.5%;}
    .xl-padding-four-top {padding-top:4%;}
    .xl-padding-four-half-top {padding-top:4.5%;}
    .xl-padding-five-top {padding-top:5%;}
    .xl-padding-five-half-top {padding-top:5.5%;}
    .xl-padding-six-top {padding-top:6%;}
    .xl-padding-six-half-top {padding-top:6.5%;}
    .xl-padding-seven-top {padding-top:7%;}
    .xl-padding-seven-half-top {padding-top:7.5%;}
    .xl-padding-eight-top {padding-top:8%;}
    .xl-padding-eight-half-top {padding-top:8.5%;}
    .xl-padding-nine-top {padding-top:9%;}
    .xl-padding-nine-half-top {padding-top:9.5%;}
    .xl-padding-ten-top {padding-top:10%;}
    .xl-padding-ten-half-top {padding-top:10.5%;}
    .xl-padding-eleven-top {padding-top:11%;}
    .xl-padding-eleven-half-top {padding-top:11.5%;}
    .xl-padding-twelve-top {padding-top:12%;}
    .xl-padding-twelve-half-top {padding-top:12.5%;}
    .xl-padding-thirteen-top {padding-top:13%;}
    .xl-padding-thirteen-half-top {padding-top:13.5%;}
    .xl-padding-fourteen-top {padding-top:14%;}
    .xl-padding-fourteen-half-top {padding-top:14.5%;}
    .xl-padding-fifteen-top {padding-top:15%;}
    .xl-padding-fifteen-half-top {padding-top:15.5%;}
    .xl-padding-sixteen-top {padding-top:16%;}
    .xl-padding-sixteen-half-top {padding-top:16.5%;}
    .xl-padding-seventeen-top {padding-top:17%;}
    .xl-padding-seventeen-half-top {padding-top:17.5%;}
    .xl-padding-eighteen-top {padding-top:18%;}
    .xl-padding-eighteen-half-top {padding-top:18.5%;}
    .xl-padding-nineteen-top {padding-top:19%;}
    .xl-padding-nineteen-half-top {padding-top:19.5%;}
    .xl-padding-twenty-top {padding-top:20%;}
    .xl-padding-twenty-half-top {padding-top:20.5%;}
    .xl-padding-twenty-one-top {padding-top:21%;}
    .xl-padding-twenty-one-half-top {padding-top:21.5%;}
    .xl-padding-twenty-two-top {padding-top:22%;}
    .xl-padding-twenty-two-half-top {padding-top:22.5%;}
    .xl-padding-twenty-three-top {padding-top:23%;}
    .xl-padding-twenty-three-half-top {padding-top:23.5%;}
    .xl-padding-twenty-four-top {padding-top:24%;}
    .xl-padding-twenty-four-half-top {padding-top:24.5%;}
    .xl-padding-twenty-five-top {padding-top:25%;}
    .xl-padding-5px-top {padding-top:5px;}
    .xl-padding-10px-top {padding-top:10px;}
    .xl-padding-15px-top {padding-top:15px;}
    .xl-padding-20px-top {padding-top:20px;}
    .xl-padding-25px-top {padding-top:25px;}
    .xl-padding-30px-top {padding-top:30px;}
    .xl-padding-35px-top {padding-top:35px;}
    .xl-padding-40px-top {padding-top:40px;}
    .xl-padding-45px-top {padding-top:45px;}
    .xl-padding-50px-top {padding-top:50px;}
    .xl-padding-55px-top {padding-top:55px;}
    .xl-padding-60px-top {padding-top:60px;}
    .xl-padding-65px-top {padding-top:65px;}
    .xl-padding-70px-top {padding-top:70px;}
    .xl-padding-75px-top {padding-top:75px;}
    .xl-padding-80px-top {padding-top:80px;}
    .xl-padding-85px-top {padding-top:85px;}
    .xl-padding-90px-top {padding-top:90px;}
    .xl-padding-95px-top {padding-top:95px;}
    .xl-padding-100px-top {padding-top:100px;}

    /* padding bottom */
    .xl-padding-one-bottom {padding-bottom:1%;}
    .xl-padding-one-half-bottom {padding-bottom:1.5%;}
    .xl-padding-two-bottom {padding-bottom:2%;}
    .xl-padding-two-half-bottom {padding-bottom:2.5%;}
    .xl-padding-three-bottom {padding-bottom:3%;}
    .xl-padding-three-half-bottom {padding-bottom:3.5%;}
    .xl-padding-four-bottom {padding-bottom:4%;}
    .xl-padding-four-half-bottom {padding-bottom:4.5%;}
    .xl-padding-five-bottom {padding-bottom:5%;}
    .xl-padding-five-half-bottom {padding-bottom:5.5%;}
    .xl-padding-six-bottom {padding-bottom:6%;}
    .xl-padding-six-half-bottom {padding-bottom:6.5%;}
    .xl-padding-seven-bottom {padding-bottom:7%;}
    .xl-padding-seven-half-bottom {padding-bottom:7.5%;}
    .xl-padding-eight-bottom {padding-bottom:8%;}
    .xl-padding-eight-half-bottom {padding-bottom:8.5%;}
    .xl-padding-nine-bottom {padding-bottom:9%;}
    .xl-padding-nine-half-bottom {padding-bottom:9.5%;}
    .xl-padding-ten-bottom {padding-bottom:10%;}
    .xl-padding-ten-half-bottom {padding-bottom:10.5%;}
    .xl-padding-eleven-bottom {padding-bottom:11%;}
    .xl-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .xl-padding-twelve-bottom {padding-bottom:12%;}
    .xl-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .xl-padding-thirteen-bottom {padding-bottom:13%;}
    .xl-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .xl-padding-fourteen-bottom {padding-bottom:14%;}
    .xl-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .xl-padding-fifteen-bottom {padding-bottom:15%;}
    .xl-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .xl-padding-sixteen-bottom {padding-bottom:16%;}
    .xl-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .xl-padding-seventeen-bottom {padding-bottom:17%;}
    .xl-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .xl-padding-eighteen-bottom {padding-bottom:18%;}
    .xl-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .xl-padding-nineteen-bottom {padding-bottom:19%;}
    .xl-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .xl-padding-twenty-bottom {padding-bottom:20%;}
    .xl-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .xl-padding-twenty-one-bottom {padding-bottom:21%;}
    .xl-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .xl-padding-twenty-two-bottom {padding-bottom:22%;}
    .xl-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .xl-padding-twenty-three-bottom {padding-bottom:23%;}
    .xl-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .xl-padding-twenty-four-bottom {padding-bottom:24%;}
    .xl-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .xl-padding-twenty-five-bottom {padding-bottom:25%;}
    .xl-padding-5px-bottom {padding-bottom:5px;}
    .xl-padding-10px-bottom {padding-bottom:10px;}
    .xl-padding-15px-bottom {padding-bottom:15px;}
    .xl-padding-20px-bottom {padding-bottom:20px;}
    .xl-padding-25px-bottom {padding-bottom:25px;}
    .xl-padding-30px-bottom {padding-bottom:30px;}
    .xl-padding-35px-bottom {padding-bottom:35px;}
    .xl-padding-40px-bottom {padding-bottom:40px;}
    .xl-padding-45px-bottom {padding-bottom:45px;}
    .xl-padding-50px-bottom {padding-bottom:50px;}
    .xl-padding-55px-bottom {padding-bottom:55px;}
    .xl-padding-60px-bottom {padding-bottom:60px;}
    .xl-padding-65px-bottom {padding-bottom:65px;}
    .xl-padding-70px-bottom {padding-bottom:70px;}
    .xl-padding-75px-bottom {padding-bottom:75px;}
    .xl-padding-80px-bottom {padding-bottom:80px;}
    .xl-padding-85px-bottom {padding-bottom:85px;}
    .xl-padding-90px-bottom {padding-bottom:90px;}
    .xl-padding-95px-bottom {padding-bottom:95px;}
    .xl-padding-100px-bottom {padding-bottom:100px;}

    /* padding right */
    .xl-padding-one-right {padding-right:1%;}
    .xl-padding-one-half-right {padding-right:1.5%;}
    .xl-padding-two-right {padding-right:2%;}
    .xl-padding-two-half-right {padding-right:2.5%;}
    .xl-padding-three-right {padding-right:3%;}
    .xl-padding-three-half-right {padding-right:3.5%;}
    .xl-padding-four-right {padding-right:4%;}
    .xl-padding-four-half-right {padding-right:4.5%;}
    .xl-padding-five-right {padding-right:5%;}
    .xl-padding-five-half-right {padding-right:5.5%;}
    .xl-padding-six-right {padding-right:6%;}
    .xl-padding-six-half-right {padding-right:6.5%;}
    .xl-padding-seven-right {padding-right:7%;}
    .xl-padding-seven-half-right {padding-right:7.5%;}
    .xl-padding-eight-right {padding-right:8%;}
    .xl-padding-eight-half-right {padding-right:8.5%;}
    .xl-padding-nine-right {padding-right:9%;}
    .xl-padding-nine-half-right {padding-right:9.5%;}
    .xl-padding-ten-right {padding-right:10%;}
    .xl-padding-ten-half-right {padding-right:10.5%;}
    .xl-padding-eleven-right {padding-right:11%;}
    .xl-padding-eleven-half-right {padding-right:11.5%;}
    .xl-padding-twelve-right {padding-right:12%;}
    .xl-padding-twelve-half-right {padding-right:12.5%;}
    .xl-padding-thirteen-right {padding-right:13%;}
    .xl-padding-thirteen-half-right {padding-right:13.5%;}
    .xl-padding-fourteen-right {padding-right:14%;}
    .xl-padding-fourteen-half-right {padding-right:14.5%;}
    .xl-padding-fifteen-right {padding-right:15%;}
    .xl-padding-fifteen-half-right {padding-right:15.5%;}
    .xl-padding-sixteen-right {padding-right:16%;}
    .xl-padding-sixteen-half-right {padding-right:16.5%;}
    .xl-padding-seventeen-right {padding-right:17%;}
    .xl-padding-seventeen-half-right {padding-right:17.5%;}
    .xl-padding-eighteen-right {padding-right:18%;}
    .xl-padding-eighteen-half-right {padding-right:18.5%;}
    .xl-padding-nineteen-right {padding-right:19%;}
    .xl-padding-nineteen-half-right {padding-right:19.5%;}
    .xl-padding-twenty-right {padding-right:20%;}
    .xl-padding-twenty-half-right {padding-right:20.5%;}
    .xl-padding-twenty-one-right {padding-right:21%;}
    .xl-padding-twenty-one-half-right {padding-right:21.5%;}
    .xl-padding-twenty-two-right {padding-right:22%;}
    .xl-padding-twenty-two-half-right {padding-right:22.5%;}
    .xl-padding-twenty-three-right {padding-right:23%;}
    .xl-padding-twenty-three-half-right {padding-right:23.5%;}
    .xl-padding-twenty-four-right {padding-right:24%;}
    .xl-padding-twenty-four-half-right {padding-right:24.5%;}
    .xl-padding-twenty-five-right {padding-right:25%;}
    .xl-padding-5px-right {padding-right:5px;}
    .xl-padding-10px-right {padding-right:10px;}
    .xl-padding-15px-right {padding-right:15px;}
    .xl-padding-20px-right {padding-right:20px;}
    .xl-padding-25px-right {padding-right:25px;}
    .xl-padding-30px-right {padding-right:30px;}
    .xl-padding-35px-right {padding-right:35px;}
    .xl-padding-40px-right {padding-right:40px;}
    .xl-padding-45px-right {padding-right:45px;}
    .xl-padding-50px-right {padding-right:50px;}
    .xl-padding-55px-right {padding-right:55px;}
    .xl-padding-60px-right {padding-right:60px;}
    .xl-padding-65px-right {padding-right:65px;}
    .xl-padding-70px-right {padding-right:70px;}
    .xl-padding-75px-right {padding-right:75px;}
    .xl-padding-80px-right {padding-right:80px;}
    .xl-padding-85px-right {padding-right:85px;}
    .xl-padding-90px-right {padding-right:90px;}
    .xl-padding-95px-right {padding-right:95px;}
    .xl-padding-100px-right {padding-right:100px;}

    /* padding left */
    .xl-padding-one-left {padding-left:1%;}
    .xl-padding-one-half-left {padding-left:1.5%;}
    .xl-padding-two-left {padding-left:2%;}
    .xl-padding-two-half-left {padding-left:2.5%;}
    .xl-padding-three-left {padding-left:3%;}
    .xl-padding-three-half-left {padding-left:3.5%;}
    .xl-padding-four-left {padding-left:4%;}
    .xl-padding-four-half-left {padding-left:4.5%;}
    .xl-padding-five-left {padding-left:5%;}
    .xl-padding-five-half-left {padding-left:5.5%;}
    .xl-padding-six-left {padding-left:6%;}
    .xl-padding-six-half-left {padding-left:6.5%;}
    .xl-padding-seven-left {padding-left:7%;}
    .xl-padding-seven-half-left {padding-left:7.5%;}
    .xl-padding-eight-left {padding-left:8%;}
    .xl-padding-eight-half-left {padding-left:8.5%;}
    .xl-padding-nine-left {padding-left:9%;}
    .xl-padding-nine-half-left {padding-left:9.5%;}
    .xl-padding-ten-left {padding-left:10%;}
    .xl-padding-ten-half-left {padding-left:10.5%;}
    .xl-padding-eleven-left {padding-left:11%;}
    .xl-padding-eleven-half-left {padding-left:11.5%;}
    .xl-padding-twelve-left {padding-left:12%;}
    .xl-padding-twelve-half-left {padding-left:12.5%;}
    .xl-padding-thirteen-left {padding-left:13%;}
    .xl-padding-thirteen-half-left {padding-left:13.5%;}
    .xl-padding-fourteen-left {padding-left:14%;}
    .xl-padding-fourteen-half-left {padding-left:14.5%;}
    .xl-padding-fifteen-left {padding-left:15%;}
    .xl-padding-fifteen-half-left {padding-left:15.5%;}
    .xl-padding-sixteen-left {padding-left:16%;}
    .xl-padding-sixteen-half-left {padding-left:16.5%;}
    .xl-padding-seventeen-left {padding-left:17%;}
    .xl-padding-seventeen-half-left {padding-left:17.5%;}
    .xl-padding-eighteen-left {padding-left:18%;}
    .xl-padding-eighteen-half-left {padding-left:18.5%;}
    .xl-padding-nineteen-left {padding-left:19%;}
    .xl-padding-nineteen-half-left {padding-left:19.5%;}
    .xl-padding-twenty-left {padding-left:20%;}
    .xl-padding-twenty-half-left {padding-left:20.5%;}
    .xl-padding-twenty-one-left {padding-left:21%;}
    .xl-padding-twenty-one-half-left {padding-left:21.5%;}
    .xl-padding-twenty-two-left {padding-left:22%;}
    .xl-padding-twenty-two-half-left {padding-left:22.5%;}
    .xl-padding-twenty-three-left {padding-left:23%;}
    .xl-padding-twenty-three-half-left {padding-left:23.5%;}
    .xl-padding-twenty-four-left {padding-left:24%;}
    .xl-padding-twenty-four-half-left {padding-left:24.5%;}
    .xl-padding-twenty-five-left {padding-left:25%;}
    .xl-padding-5px-left {padding-left:5px;}
    .xl-padding-10px-left {padding-left:10px;}
    .xl-padding-15px-left {padding-left:15px;}
    .xl-padding-20px-left {padding-left:20px;}
    .xl-padding-25px-left {padding-left:25px;}
    .xl-padding-30px-left {padding-left:30px;}
    .xl-padding-35px-left {padding-left:35px;}
    .xl-padding-40px-left {padding-left:40px;}
    .xl-padding-45px-left {padding-left:45px;}
    .xl-padding-50px-left {padding-left:50px;}
    .xl-padding-55px-left {padding-left:55px;}
    .xl-padding-60px-left {padding-left:60px;}
    .xl-padding-65px-left {padding-left:65px;}
    .xl-padding-70px-left {padding-left:70px;}
    .xl-padding-75px-left {padding-left:75px;}
    .xl-padding-80px-left {padding-left:80px;}
    .xl-padding-85px-left {padding-left:85px;}
    .xl-padding-90px-left {padding-left:90px;}
    .xl-padding-95px-left {padding-left:95px;}
    .xl-padding-100px-left {padding-left:100px;}

    /* padding top bottom */
    .xl-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .xl-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .xl-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .xl-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .xl-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .xl-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .xl-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .xl-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .xl-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .xl-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .xl-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .xl-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .xl-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .xl-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .xl-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .xl-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .xl-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .xl-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .xl-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .xl-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .xl-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .xl-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .xl-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .xl-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .xl-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .xl-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .xl-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .xl-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .xl-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .xl-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .xl-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .xl-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .xl-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .xl-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .xl-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .xl-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .xl-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .xl-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .xl-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .xl-padding-twenty-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .xl-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .xl-padding-twenty-one-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .xl-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .xl-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .xl-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .xl-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .xl-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .xl-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .xl-padding-twenty-tb {padding-top:25%; padding-bottom:25%;}
    .xl-padding-5px-tb {padding-top:5px; padding-bottom:5px;}
    .xl-padding-10px-tb {padding-top:10px; padding-bottom:10px;}
    .xl-padding-15px-tb {padding-top:15px; padding-bottom:15px;}
    .xl-padding-20px-tb {padding-top:20px; padding-bottom:20px;}
    .xl-padding-25px-tb {padding-top:25px; padding-bottom:25px;}
    .xl-padding-30px-tb {padding-top:30px; padding-bottom:30px;}
    .xl-padding-35px-tb {padding-top:35px; padding-bottom:35px;}
    .xl-padding-40px-tb {padding-top:40px; padding-bottom:40px;}
    .xl-padding-45px-tb {padding-top:45px; padding-bottom:45px;}
    .xl-padding-50px-tb {padding-top:50px; padding-bottom:50px;}
    .xl-padding-55px-tb {padding-top:55px; padding-bottom:55px;}
    .xl-padding-60px-tb {padding-top:60px; padding-bottom:60px;}
    .xl-padding-65px-tb {padding-top:65px; padding-bottom:65px;}
    .xl-padding-70px-tb {padding-top:70px; padding-bottom:70px;}
    .xl-padding-75px-tb {padding-top:75px; padding-bottom:75px;}
    .xl-padding-80px-tb {padding-top:80px; padding-bottom:80px;}
    .xl-padding-85px-tb {padding-top:85px; padding-bottom:85px;}
    .xl-padding-90px-tb {padding-top:90px; padding-bottom:90px;}
    .xl-padding-95px-tb {padding-top:95px; padding-bottom:95px;}
    .xl-padding-100px-tb {padding-top:100px; padding-bottom:100px;}

    /* padding left right */
    .xl-padding-one-lr {padding-left:1%; padding-right:1%;}
    .xl-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .xl-padding-two-lr {padding-left:2%; padding-right:2%;}
    .xl-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .xl-padding-three-lr {padding-left:3%; padding-right:3%;}
    .xl-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .xl-padding-four-lr {padding-left:4%; padding-right:4%;}
    .xl-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .xl-padding-five-lr {padding-left:5%; padding-right:5%;}
    .xl-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .xl-padding-six-lr {padding-left:6%; padding-right:6%;}
    .xl-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .xl-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .xl-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .xl-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .xl-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .xl-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .xl-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .xl-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .xl-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .xl-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .xl-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .xl-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .xl-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .xl-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .xl-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .xl-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .xl-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .xl-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .xl-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .xl-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .xl-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .xl-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .xl-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .xl-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .xl-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .xl-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .xl-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .xl-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .xl-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .xl-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .xl-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .xl-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .xl-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .xl-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .xl-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .xl-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .xl-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .xl-padding-twenty-five-lr {padding-left:25%; padding-right:25%;}
    .xl-padding-5px-lr {padding-right:5px; padding-left:5px;}
    .xl-padding-10px-lr {padding-right:10px; padding-left:10px;}
    .xl-padding-15px-lr {padding-right:15px; padding-left:15px;}
    .xl-padding-20px-lr {padding-right:20px; padding-left:20px;}
    .xl-padding-25px-lr {padding-right:25px; padding-left:25px;}
    .xl-padding-30px-lr {padding-right:30px; padding-left:30px;}
    .xl-padding-35px-lr {padding-right:35px; padding-left:35px;}
    .xl-padding-40px-lr {padding-right:40px; padding-left:40px;}
    .xl-padding-45px-lr {padding-right:45px; padding-left:45px;}
    .xl-padding-50px-lr {padding-right:50px; padding-left:50px;}
    .xl-padding-55px-lr {padding-right:55px; padding-left:55px;}
    .xl-padding-60px-lr {padding-right:60px; padding-left:60px;}
    .xl-padding-65px-lr {padding-right:65px; padding-left:65px;}
    .xl-padding-70px-lr {padding-right:70px; padding-left:70px;}
    .xl-padding-75px-lr {padding-right:75px; padding-left:75px;}
    .xl-padding-80px-lr {padding-right:80px; padding-left:80px;}
    .xl-padding-85px-lr {padding-right:85px; padding-left:85px;}
    .xl-padding-90px-lr {padding-right:90px; padding-left:90px;}
    .xl-padding-95px-lr {padding-right:95px; padding-left:95px;}
    .xl-padding-100px-lr {padding-right:100px; padding-left:100px;}

    /* width */
    .xl-w-10 {width:10% !important;}
    .xl-w-15 {width:15% !important;}
    .xl-w-20 {width:20% !important;}
    .xl-w-25 {width:25% !important;}
    .xl-w-30 {width:30% !important;}
    .xl-w-35 {width:35% !important;}
    .xl-w-40 {width:40% !important;}
    .xl-w-45 {width:45% !important;}
    .xl-w-50 {width:50% !important;}
    .xl-w-55 {width:55% !important;}
    .xl-w-60 {width:60% !important;}
    .xl-w-65 {width:65% !important;}
    .xl-w-70 {width:70% !important;}
    .xl-w-75 {width:75% !important;}
    .xl-w-80 {width:80% !important;}
    .xl-w-85 {width:85% !important;}
    .xl-w-90 {width:90% !important;}
    .xl-w-95 {width:95% !important;}
    .xl-w-100 {width:100% !important;}
    .xl-w-auto {width: auto !important}
    .xl-w-50px {width:50px !important;}
    .xl-w-70px {width:70px !important;}
    .xl-w-75px {width:75px !important;}
    .xl-w-80px {width:80px !important;}
    .xl-w-85px {width:85px !important;}
    .xl-w-90px {width:90px !important;}
    .xl-w-95px {width:95px !important;}
    .xl-w-100px {width:100px !important;}
    .xl-w-120px {width:120px !important;}
    .xl-w-130px {width:130px !important;}
    .xl-w-150px {width:150px !important;}
    .xl-w-180px {width:180px !important;}
    .xl-w-200px {width:200px !important;}
    .xl-w-250px {width:250px !important;}
    .xl-w-300px {width:300px !important;}
    .xl-w-350px {width:350px !important;}
    .xl-w-400px {width:400px !important;}
    .xl-w-450px {width:450px !important;}
    .xl-w-500px {width:500px !important;}
    .xl-w-550px {width:550px !important;}
    .xl-w-600px {width:600px !important;}
    .xl-w-650px {width:650px !important;}
    .xl-w-700px {width:700px !important;}
    .xl-w-750px {width:750px !important;}
    .xl-w-800px {width:800px !important;}
    .xl-w-850px {width:850px !important;}
    .xl-w-900px {width:900px !important;}
    .xl-w-950px {width:950px !important;}
    .xl-w-1000px {width:1000px !important;}

    /* height */
    .xl-h-50 {height: 50%}
    .xl-h-100 {height: 100%}
    .xl-h-auto {height:auto !important}
    .xl-h-10px {height: 10px !important}
    .xl-h-20px {height: 20px !important}
    .xl-h-30px {height: 30px !important}
    .xl-h-40px {height: 40px !important}
    .xl-h-50px {height: 50px !important}
    .xl-h-60px {height: 60px !important}
    .xl-h-70px {height: 70px !important}
    .xl-h-80px {height: 80px !important}
    .xl-h-90px {height: 90px !important}
    .xl-h-100px {height: 100px !important}
    .xl-h-150px {height: 150px !important}
    .xl-h-200px {height: 200px !important}
    .xl-h-250px {height: 250px !important}
    .xl-h-300px {height: 300px !important}
    .xl-h-350px {height: 350px !important}
    .xl-h-400px {height: 400px !important}
    .xl-h-450px {height: 450px !important}
    .xl-h-500px {height: 500px !important}
    .xl-h-550px {height: 550px !important}
    .xl-h-600px {height: 600px !important}
    .xl-h-650px {height: 650px !important}
    .xl-h-700px {height: 700px !important}

    /* line height */
    .xl-line-height-10 {line-height:10px;}
    .xl-line-height-18 {line-height:18px;}
    .xl-line-height-20 {line-height:20px}
    .xl-line-height-24 {line-height:24px}
    .xl-line-height-22 {line-height:22px}
    .xl-line-height-26 {line-height:26px}
    .xl-line-height-28 {line-height:28px}
    .xl-line-height-30 {line-height:30px}
    .xl-line-height-35 {line-height:35px}
    .xl-line-height-40 {line-height:40px}
    .xl-line-height-45 {line-height:45px}
    .xl-line-height-50 {line-height:50px}
    .xl-line-height-55 {line-height:55px}
    .xl-line-height-60 {line-height:60px}
    .xl-line-height-65 {line-height:65px}
    .xl-line-height-70 {line-height:70px}
    .xl-line-height-75 {line-height:75px}
    .xl-line-height-80 {line-height:80px}
    .xl-line-height-85 {line-height:85px}
    .xl-line-height-90 {line-height:90px}
    .xl-line-height-95 {line-height:95px}
    .xl-line-height-100 {line-height:100px}

    /* top */
    .xl-top-auto {top:auto}
    .xl-top-0 {top:0 !important}
    .xl-top-1 {top:1px}
    .xl-top-2 {top:2px}
    .xl-top-3 {top:3px}
    .xl-top-4 {top:4px}
    .xl-top-5 {top:5px}
    .xl-top-6 {top:6px}
    .xl-top-7 {top:7px}
    .xl-top-8 {top:8px}
    .xl-top-9 {top:9px}
    .xl-top-10 {top:10px}
    .xl-top-minus1 {top:-1px}
    .xl-top-minus2 {top:-2px}
    .xl-top-minus3 {top:-3px}
    .xl-top-minus4 {top:-4px}
    .xl-top-minus5 {top:-5px}
    .xl-top-minus6 {top:-6px}
    .xl-top-minus7 {top:-7px}
    .xl-top-minus8 {top:-8px}
    .xl-top-minus9 {top:-9px}
    .xl-top-minus10 {top:-10px}
    .xl-top-minus15 {top:-15px}
    .xl-top-minus20 {top:-20px}
    .xl-top-minus25 {top:-25px}
    .xl-top-minus30 {top:-30px}
    .xl-top-minus35 {top:-35px}
    .xl-top-minus40 {top:-40px}

    /* bottom */
    .xl-bottom-auto {bottom:auto}
    .xl-bottom-0 {bottom:0}
    .xl-bottom-1 {bottom:1px}
    .xl-bottom-2 {bottom:2px}
    .xl-bottom-3 {bottom:3px}
    .xl-bottom-4 {bottom:4px}
    .xl-bottom-5 {bottom:5px}
    .xl-bottom-6 {bottom:6px}
    .xl-bottom-7 {bottom:7px}
    .xl-bottom-8 {bottom:8px}
    .xl-bottom-9 {bottom:9px}
    .xl-bottom-10 {bottom:10px}
    .xl-bottom-15 {bottom:15px}
    .xl-bottom-20 {bottom:20px}
    .xl-bottom-25 {bottom:25px}
    .xl-bottom-30 {bottom:30px}
    .xl-bottom-35 {bottom:35px}
    .xl-bottom-40 {bottom:40px}
    .xl-bottom-45 {bottom:45px}
    .xl-bottom-50 {bottom:50px}
    .xl-bottom-minus30 {bottom:-30px}
    .xl-bottom-minus50 {bottom:-50px}
    .xl-bottom-minus55 { bottom: -55px; }
    .xl-bottom-minus60 { bottom: -60px; }

    /* left */
    .xl-left-auto {left:auto}
    .xl-left-0 {left:0}
    .xl-left-1 {left:1px}
    .xl-left-2 {left:2px}
    .xl-left-3 {left:3px}
    .xl-left-4 {left:4px}
    .xl-left-5 {left:5px}
    .xl-left-6 {left:6px}
    .xl-left-7 {left:7px}
    .xl-left-8 {left:8px}
    .xl-left-9 {left:9px}
    .xl-left-10 {bottom:10px}
    .xl-left-15 {bottom:15px}
    .xl-left-20 {bottom:20px}
    .xl-left-25 {bottom:25px}
    .xl-left-30 {bottom:30px}
    .xl-left-35 {bottom:35px}
    .xl-left-40 {bottom:40px}

    /* right */
    .xl-right-auto {right:auto}
    .xl-right-0 {right:0}
    .xl-right-1 {right:1px}
    .xl-right-2 {right:2px}
    .xl-right-3 {right:3px}
    .xl-right-4 {right:4px}
    .xl-right-5 {right:5px}
    .xl-right-6 {right:6px}
    .xl-right-7 {right:7px}
    .xl-right-8 {right:8px}
    .xl-right-9 {right:9px}
    .xl-right-10 {right:10px}
    .xl-right-15 {right:15px}
    .xl-right-20 {right:20px}
    .xl-right-25 {right:25px}
    .xl-right-30 {right:30px}
    .xl-right-35 {right:35px}
    .xl-right-40 {right:40px}

    /* border */
    .xl-no-border-top {border-top:0 !important}
    .xl-no-border-bottom {border-bottom:0 !important}
    .xl-no-border-right {border-right:0 !important}
    .xl-no-border-left {border-left:0 !important}
}

@media (max-width: 1500px) {
    /* sidebar navigation */
    .sidebar-wrapper .container {width: 1000px;}

    /* blog post style 2 */
    .blog-post.blog-post-style2 .author span { padding-left: 10px;}

    /* homepage 14 */
    .right-image { width: 70%; }
}

@media (max-width: 1400px) {
    .head-text {font-size: 60px; line-height: 70px;}
    .banner-style2 figcaption {width: 70%;}
    .banner-style1 figure figcaption img {width: 100px;}
    .feature-box.feature-box-17 .box .content {top: 40px;}

    /* tab-style5 */
    .tab-style5 .tab-box { left: 27%; }
    .tab-style5 .nav-tabs li { font-size: 24px; line-height: 52px; }
}

@media (max-width: 1300px) {
    /* sidebar navigation */
    .sidebar-wrapper .container {width: 830px;}

    /* feature box style 4 */
    .grid-item.feature-box-4 figure figcaption {width: 90%; padding: 0 0 15px 30px}

    /* menu center */
    .menu-center .header-social-icon a i, .menu-center .header-searchbar a i {margin: 0 8px;}
    .menu-center .header-social-icon {padding-left: 10px}
    .menu-center .header-searchbar { padding-left: 0; padding-right: 5px;}
    .menu-center .header-social-icon {margin-left: 0}

    /* sidebar nav style 2 */
    .left-nav-sidebar .container {width: 1070px;}

    /* small box-layout */
    .box-layout.box-layout-md { padding: 0; }
}

@media (min-width: 1199px) {
    /* page container */
    .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1170px; }
}

@media (max-width: 1199px) {
    .panel-group * { transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; transition-duration: 0s; -ms-transition-timing-function: none; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; }
    /*section {padding: 90px 0}*/

    /* typography */
    h1, h2, h3, h4, h5, h6 {margin:0 0 22px; padding:0; letter-spacing: 0; font-weight: 400;}
    h1 {font-size:65px; line-height: 65px;}
    h2 {font-size:50px; line-height:55px}
    h3 {font-size:42px; line-height:48px}
    h4 {font-size:35px; line-height:41px}
    h5 {font-size:27px; line-height:32px}
    h6 {font-size:22px; line-height:27px}

    /* image position*/
    .lg-background-image-left { background-position: left;}
    .lg-background-image-right { background-position: right;}
    .lg-background-image-center{ background-position: center;}

    /* margin */
    .lg-margin-one-all {margin:1%;}
    .lg-margin-one-half-all {margin:1.5%;}
    .lg-margin-two-all {margin:2%;}
    .lg-margin-two-half-all {margin:2.5%;}
    .lg-margin-three-all {margin:3%;}
    .lg-margin-three-half-all {margin:3.5%;}
    .lg-margin-four-all {margin:4%;}
    .lg-margin-four-half-all {margin:4.5%;}
    .lg-margin-five-all {margin:5%;}
    .lg-margin-five-half-all {margin:5.5%;}
    .lg-margin-six-all {margin:6%;}
    .lg-margin-six-half-all {margin:6.5%;}
    .lg-margin-seven-all {margin:7%;}
    .lg-margin-seven-half-all {margin:7.5%;}
    .lg-margin-eight-all {margin:8%;}
    .lg-margin-eight-half-all {margin:8.5%;}
    .lg-margin-nine-all {margin:9%;}
    .lg-margin-nine-half-all {margin:9.5%;}
    .lg-margin-ten-all {margin:10%;}
    .lg-margin-ten-half-all {margin:10.5%;}
    .lg-margin-eleven-all {margin:11%;}
    .lg-margin-eleven-half-all {margin:11.5%;}
    .lg-margin-twelve-all {margin:12%;}
    .lg-margin-twelve-half-all {margin:12.5%;}
    .lg-margin-thirteen-all {margin:13%;}
    .lg-margin-thirteen-half-all {margin:13.5%;}
    .lg-margin-fourteen-all {margin:14%;}
    .lg-margin-fourteen-half-all {margin:14.5%;}
    .lg-margin-fifteen-all {margin:15%;}
    .lg-margin-fifteen-half-all {margin:15.5%;}
    .lg-margin-sixteen-all {margin:16%;}
    .lg-margin-sixteen-half-all {margin:16.5%;}
    .lg-margin-seventeen-all {margin:17%;}
    .lg-margin-seventeen-half-all {margin:17.5%;}
    .lg-margin-eighteen-all {margin:18%;}
    .lg-margin-eighteen-half-all {margin:18.5%;}
    .lg-margin-nineteen-all {margin:19%;}
    .lg-margin-nineteen-half-all {margin:19.5%;}
    .lg-margin-twenty-all {margin:20%;}
    .lg-margin-twenty-half-all {margin:20.5%;}
    .lg-margin-twenty-one-all {margin:21%;}
    .lg-margin-twenty-one-half-all {margin:21.5%;}
    .lg-margin-twenty-two-all {margin:22%;}
    .lg-margin-twenty-two-half-all {margin:22.5%;}
    .lg-margin-twenty-three-all {margin:23%;}
    .lg-margin-twenty-three-half-all {margin:23.5%;}
    .lg-margin-twenty-four-all {margin:24%;}
    .lg-margin-twenty-four-half-all {margin:24.5%;}
    .lg-margin-twenty-five-all {margin:25%;}
    .lg-margin-5px-all {margin:5px;}
    .lg-margin-10px-all {margin:10px;}
    .lg-margin-15px-all {margin:15px;}
    .lg-margin-20px-all {margin:20px;}
    .lg-margin-25px-all {margin:25px;}
    .lg-margin-30px-all {margin:30px;}
    .lg-margin-35px-all {margin:35px;}
    .lg-margin-40px-all {margin:40px;}
    .lg-margin-45px-all {margin:45px;}
    .lg-margin-50px-all {margin:50px;}
    .lg-margin-55px-all {margin:55px;}
    .lg-margin-60px-all {margin:60px;}
    .lg-margin-65px-all {margin:65px;}
    .lg-margin-70px-all {margin:70px;}
    .lg-margin-75px-all {margin:75px;}
    .lg-margin-80px-all {margin:80px;}
    .lg-margin-85px-all {margin:85px;}
    .lg-margin-90px-all {margin:90px;}
    .lg-margin-95px-all {margin:95px;}
    .lg-margin-100px-all {margin:100px;}
    .lg-no-margin {margin:0 !important}
    .lg-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important}
    .lg-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important}
    .lg-no-margin-top {margin-top:0 !important}
    .lg-no-margin-bottom {margin-bottom:0 !important}
    .lg-no-margin-left {margin-left:0 !important}
    .lg-no-margin-right {margin-right:0 !important}
    .lg-margin-lr-auto {margin-left:auto !important; margin-right:auto !important}
    .lg-margin-auto {margin: 0 auto !important;}

    /* margin top */
    .lg-margin-one-top {margin-top:1%;}
    .lg-margin-one-half-top {margin-top:1.5%;}
    .lg-margin-two-top {margin-top:2%;}
    .lg-margin-two-half-top {margin-top:2.5%;}
    .lg-margin-three-top {margin-top:3%;}
    .lg-margin-three-half-top {margin-top:3.5%;}
    .lg-margin-four-top {margin-top:4%;}
    .lg-margin-four-half-top {margin-top:4.5%;}
    .lg-margin-five-top {margin-top:5%;}
    .lg-margin-five-half-top {margin-top:5.5%;}
    .lg-margin-six-top {margin-top:6%;}
    .lg-margin-six-half-top {margin-top:6.5%;}
    .lg-margin-seven-top {margin-top:7%;}
    .lg-margin-seven-half-top {margin-top:7.5%;}
    .lg-margin-eight-top {margin-top:8%;}
    .lg-margin-eight-half-top {margin-top:8.5%;}
    .lg-margin-nine-top {margin-top:9%;}
    .lg-margin-nine-half-top {margin-top:9.5%;}
    .lg-margin-ten-top {margin-top:10%;}
    .lg-margin-ten-half-top {margin-top:10.5%;}
    .lg-margin-eleven-top {margin-top:11%;}
    .lg-margin-eleven-half-top {margin-top:11.5%;}
    .lg-margin-twelve-top {margin-top:12%;}
    .lg-margin-twelve-half-top {margin-top:12.5%;}
    .lg-margin-thirteen-top {margin-top:13%;}
    .lg-margin-thirteen-half-top {margin-top:13.5%;}
    .lg-margin-fourteen-top {margin-top:14%;}
    .lg-margin-fourteen-half-top {margin-top:14.5%;}
    .lg-margin-fifteen-top {margin-top:15%;}
    .lg-margin-fifteen-half-top {margin-top:15.5%;}
    .lg-margin-sixteen-top {margin-top:16%;}
    .lg-margin-sixteen-half-top {margin-top:16.5%;}
    .lg-margin-seventeen-top {margin-top:17%;}
    .lg-margin-seventeen-half-top {margin-top:17.5%;}
    .lg-margin-eighteen-top {margin-top:18%;}
    .lg-margin-eighteen-half-top {margin-top:18.5%;}
    .lg-margin-nineteen-top {margin-top:19%;}
    .lg-margin-nineteen-half-top {margin-top:19.5%;}
    .lg-margin-twenty-top {margin-top:20%;}
    .lg-margin-twenty-half-top {margin-top:20.5%;}
    .lg-margin-twenty-one-top {margin-top:21%;}
    .lg-margin-twenty-one-half-top {margin-top:21.5%;}
    .lg-margin-twenty-two-top {margin-top:22%;}
    .lg-margin-twenty-two-half-top {margin-top:22.5%;}
    .lg-margin-twenty-top {margin-top:23%;}
    .lg-margin-twenty-half-top {margin-top:23.5%;}
    .lg-margin-twenty-four-top {margin-top:24%;}
    .lg-margin-twenty-four-half-top {margin-top:24.5%;}
    .lg-margin-twenty-five-top {margin-top:25%;}
    .lg-margin-5px-top {margin-top:5px;}
    .lg-margin-10px-top {margin-top:10px;}
    .lg-margin-15px-top {margin-top:15px;}
    .lg-margin-20px-top {margin-top:20px;}
    .lg-margin-25px-top {margin-top:25px;}
    .lg-margin-30px-top {margin-top:30px;}
    .lg-margin-35px-top {margin-top:35px;}
    .lg-margin-40px-top {margin-top:40px;}
    .lg-margin-45px-top {margin-top:45px;}
    .lg-margin-50px-top {margin-top:50px;}
    .lg-margin-55px-top {margin-top:55px;}
    .lg-margin-60px-top {margin-top:60px;}
    .lg-margin-65px-top {margin-top:65px;}
    .lg-margin-70px-top {margin-top:70px;}
    .lg-margin-75px-top {margin-top:75px;}
    .lg-margin-80px-top {margin-top:80px;}
    .lg-margin-85px-top {margin-top:85px;}
    .lg-margin-90px-top {margin-top:90px;}
    .lg-margin-95px-top {margin-top:95px;}
    .lg-margin-100px-top {margin-top:100px;}

    /* margin bottom */
    .lg-margin-one-bottom {margin-bottom:1%;}
    .lg-margin-one-half-bottom {margin-bottom:1.5%;}
    .lg-margin-two-bottom {margin-bottom:2%;}
    .lg-margin-two-half-bottom {margin-bottom:2.5%;}
    .lg-margin-three-bottom {margin-bottom:3%;}
    .lg-margin-three-half-bottom {margin-bottom:3.5%;}
    .lg-margin-four-bottom {margin-bottom:4%;}
    .lg-margin-four-half-bottom {margin-bottom:4.5%;}
    .lg-margin-five-bottom {margin-bottom:5%;}
    .lg-margin-five-half-bottom {margin-bottom:5.5%;}
    .lg-margin-six-bottom {margin-bottom:6%;}
    .lg-margin-six-half-bottom {margin-bottom:6.5%;}
    .lg-margin-seven-bottom {margin-bottom:7%;}
    .lg-margin-seven-half-bottom {margin-bottom:7.5%;}
    .lg-margin-eight-bottom {margin-bottom:8%;}
    .lg-margin-eight-half-bottom {margin-bottom:8.5%;}
    .lg-margin-nine-bottom {margin-bottom:9%;}
    .lg-margin-nine-half-bottom {margin-bottom:9.5%;}
    .lg-margin-ten-bottom {margin-bottom:10%;}
    .lg-margin-ten-half-bottom {margin-bottom:10.5%;}
    .lg-margin-eleven-bottom {margin-bottom:11%;}
    .lg-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .lg-margin-twelve-bottom {margin-bottom:12%;}
    .lg-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .lg-margin-thirteen-bottom {margin-bottom:13%;}
    .lg-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .lg-margin-fourteen-bottom {margin-bottom:14%;}
    .lg-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .lg-margin-fifteen-bottom {margin-bottom:15%;}
    .lg-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .lg-margin-sixteen-bottom {margin-bottom:16%;}
    .lg-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .lg-margin-seventeen-bottom {margin-bottom:17%;}
    .lg-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .lg-margin-eighteen-bottom {margin-bottom:18%;}
    .lg-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .lg-margin-nineteen-bottom {margin-bottom:19%;}
    .lg-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .lg-margin-twenty-bottom {margin-bottom:20%;}
    .lg-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .lg-margin-twenty-one-bottom {margin-bottom:21%;}
    .lg-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .lg-margin-twenty-two-bottom {margin-bottom:22%;}
    .lg-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .lg-margin-twenty-three-bottom {margin-bottom:23%;}
    .lg-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .lg-margin-twenty-five-bottom {margin-bottom:24%;}
    .lg-margin-twenty-five-half-bottom {margin-bottom:24.5%;}
    .lg-margin-twenty-five-bottom {margin-bottom:25%;}
    .lg-margin-5px-bottom {margin-bottom:5px;}
    .lg-margin-10px-bottom {margin-bottom:10px;}
    .lg-margin-15px-bottom {margin-bottom:15px;}
    .lg-margin-20px-bottom {margin-bottom:20px;}
    .lg-margin-25px-bottom {margin-bottom:25px;}
    .lg-margin-30px-bottom {margin-bottom:30px;}
    .lg-margin-35px-bottom {margin-bottom:35px;}
    .lg-margin-40px-bottom {margin-bottom:40px;}
    .lg-margin-45px-bottom {margin-bottom:45px;}
    .lg-margin-50px-bottom {margin-bottom:50px;}
    .lg-margin-55px-bottom {margin-bottom:55px;}
    .lg-margin-60px-bottom {margin-bottom:60px;}
    .lg-margin-65px-bottom {margin-bottom:65px;}
    .lg-margin-70px-bottom {margin-bottom:70px;}
    .lg-margin-75px-bottom {margin-bottom:75px;}
    .lg-margin-80px-bottom {margin-bottom:80px;}
    .lg-margin-85px-bottom {margin-bottom:85px;}
    .lg-margin-90px-bottom {margin-bottom:90px;}
    .lg-margin-95px-bottom {margin-bottom:95px;}
    .lg-margin-100px-bottom {margin-bottom:100px;}

    /* margin right */
    .lg-margin-one-right {margin-right:1%;}
    .lg-margin-one-half-right {margin-right:1.5%;}
    .lg-margin-two-right {margin-right:2%;}
    .lg-margin-two-half-right {margin-right:2.5%;}
    .lg-margin-three-right {margin-right:3%;}
    .lg-margin-three-half-right {margin-right:3.5%;}
    .lg-margin-four-right {margin-right:4%;}
    .lg-margin-four-half-right {margin-right:4.5%;}
    .lg-margin-five-right {margin-right:5%;}
    .lg-margin-five-half-right {margin-right:5.5%;}
    .lg-margin-six-right {margin-right:6%;}
    .lg-margin-six-half-right {margin-right:6.5%;}
    .lg-margin-seven-right {margin-right:7%;}
    .lg-margin-seven-half-right {margin-right:7.5%;}
    .lg-margin-eight-right {margin-right:8%;}
    .lg-margin-eight-half-right {margin-right:8.5%;}
    .lg-margin-nine-right {margin-right:9%;}
    .lg-margin-nine-half-right {margin-right:9.5%;}
    .lg-margin-ten-right {margin-right:10%;}
    .lg-margin-ten-half-right {margin-right:10.5%;}
    .lg-margin-eleven-right {margin-right:11%;}
    .lg-margin-eleven-half-right {margin-right:11.5%;}
    .lg-margin-twelve-right {margin-right:12%;}
    .lg-margin-twelve-half-right {margin-right:12.5%;}
    .lg-margin-thirteen-right {margin-right:13%;}
    .lg-margin-thirteen-half-right {margin-right:13.5%;}
    .lg-margin-fourteen-right {margin-right:14%;}
    .lg-margin-fourteen-half-right {margin-right:14.5%;}
    .lg-margin-fifteen-right {margin-right:15%;}
    .lg-margin-fifteen-half-right {margin-right:15.5%;}
    .lg-margin-sixteen-right {margin-right:16%;}
    .lg-margin-sixteen-half-right {margin-right:16.5%;}
    .lg-margin-seventeen-right {margin-right:17%;}
    .lg-margin-seventeen-half-right {margin-right:17.5%;}
    .lg-margin-eighteen-right {margin-right:18%;}
    .lg-margin-eighteen-half-right {margin-right:18.5%;}
    .lg-margin-nineteen-right {margin-right:19%;}
    .lg-margin-nineteen-half-right {margin-right:19.5%;}
    .lg-margin-twenty-right {margin-right:20%;}
    .lg-margin-twenty-half-right {margin-right:20.5%;}
    .lg-margin-twenty-one-right {margin-right:21%;}
    .lg-margin-twenty-one-half-right {margin-right:21.5%;}
    .lg-margin-twenty-two-right {margin-right:22%;}
    .lg-margin-twenty-two-half-right {margin-right:22.5%;}
    .lg-margin-twenty-three-right {margin-right:23%;}
    .lg-margin-twenty-three-half-right {margin-right:23.5%;}
    .lg-margin-twenty-four-right {margin-right:24%;}
    .lg-margin-twenty-four-half-right {margin-right:24.5%;}
    .lg-margin-twenty-five-right {margin-right:25%;}
    .lg-margin-10px-right {margin-right:10px;}
    .lg-margin-15px-right {margin-right:15px;}
    .lg-margin-20px-right {margin-right:20px;}
    .lg-margin-25px-right {margin-right:25px;}
    .lg-margin-30px-right {margin-right:30px;}
    .lg-margin-35px-right {margin-right:35px;}
    .lg-margin-40px-right {margin-right:40px;}
    .lg-margin-45px-right {margin-right:45px;}
    .lg-margin-50px-right {margin-right:50px;}
    .lg-margin-55px-right {margin-right:55px;}
    .lg-margin-60px-right {margin-right:60px;}
    .lg-margin-65px-right {margin-right:65px;}
    .lg-margin-70px-right {margin-right:70px;}
    .lg-margin-75px-right {margin-right:75px;}
    .lg-margin-80px-right {margin-right:80px;}
    .lg-margin-85px-right {margin-right:85px;}
    .lg-margin-90px-right {margin-right:90px;}
    .lg-margin-95px-right {margin-right:95px;}
    .lg-margin-100px-right {margin-right:100px;}

    /* margin left */
    .lg-margin-one-left {margin-left:1%;}
    .lg-margin-one-half-left {margin-left:1.5%;}
    .lg-margin-two-left {margin-left:2%;}
    .lg-margin-two-half-left {margin-left:2.5%;}
    .lg-margin-three-left {margin-left:3%;}
    .lg-margin-three-half-left {margin-left:3.5%;}
    .lg-margin-four-left {margin-left:4%;}
    .lg-margin-four-half-left {margin-left:4.5%;}
    .lg-margin-five-left {margin-left:5%;}
    .lg-margin-five-half-left {margin-left:5.5%;}
    .lg-margin-six-left {margin-left:6%;}
    .lg-margin-six-half-left {margin-left:6.5%;}
    .lg-margin-seven-left {margin-left:7%;}
    .lg-margin-seven-half-left {margin-left:7.5%;}
    .lg-margin-eight-left {margin-left:8%;}
    .lg-margin-eight-half-left {margin-left:8.5%;}
    .lg-margin-nine-left {margin-left:9%;}
    .lg-margin-nine-half-left {margin-left:9.5%;}
    .lg-margin-ten-left {margin-left:10%;}
    .lg-margin-ten-half-left {margin-left:10.5%;}
    .lg-margin-eleven-left {margin-left:11%;}
    .lg-margin-eleven-half-left {margin-left:11.5%;}
    .lg-margin-twelve-left {margin-left:12%;}
    .lg-margin-twelve-half-left {margin-left:12.5%;}
    .lg-margin-thirteen-left {margin-left:13%;}
    .lg-margin-thirteen-half-left {margin-left:13.5%;}
    .lg-margin-fourteen-left {margin-left:14%;}
    .lg-margin-fourteen-half-left {margin-left:14.5%;}
    .lg-margin-fifteen-left {margin-left:15%;}
    .lg-margin-fifteen-half-left {margin-left:15.5%;}
    .lg-margin-sixteen-left {margin-left:16%;}
    .lg-margin-sixteen-half-left {margin-left:16.5%;}
    .lg-margin-seventeen-left {margin-left:17%;}
    .lg-margin-seventeen-half-left {margin-left:17.5%;}
    .lg-margin-eighteen-left {margin-left:18%;}
    .lg-margin-eighteen-half-left {margin-left:18.5%;}
    .lg-margin-nineteen-left {margin-left:19%;}
    .lg-margin-nineteen-half-left {margin-left:19.5%;}
    .lg-margin-twenty-left {margin-left:20%;}
    .lg-margin-twenty-half-left {margin-left:20.5%;}
    .lg-margin-twenty-one-left {margin-left:21%;}
    .lg-margin-twenty-one-half-left {margin-left:21.5%;}
    .lg-margin-twenty-two-left {margin-left:22%;}
    .lg-margin-twenty-two-half-left {margin-left:22.5%;}
    .lg-margin-twenty-three-left {margin-left:23%;}
    .lg-margin-twenty-three-half-left {margin-left:23.5%;}
    .lg-margin-twenty-four-left {margin-left:24%;}
    .lg-margin-twenty-four-half-left {margin-left:24.5%;}
    .lg-margin-twenty-five-left {margin-left:25%;}
    .lg-margin-5px-left {margin-left:5px;}
    .lg-margin-10px-left {margin-left:10px;}
    .lg-margin-15px-left {margin-left:15px;}
    .lg-margin-20px-left {margin-left:20px;}
    .lg-margin-25px-left {margin-left:25px;}
    .lg-margin-30px-left {margin-left:30px;}
    .lg-margin-35px-left {margin-left:35px;}
    .lg-margin-40px-left {margin-left:40px;}
    .lg-margin-45px-left {margin-left:45px;}
    .lg-margin-50px-left {margin-left:50px;}
    .lg-margin-55px-left {margin-left:55px;}
    .lg-margin-60px-left {margin-left:60px;}
    .lg-margin-65px-left {margin-left:65px;}
    .lg-margin-70px-left {margin-left:70px;}
    .lg-margin-75px-left {margin-left:75px;}
    .lg-margin-80px-left {margin-left:80px;}
    .lg-margin-85px-left {margin-left:85px;}
    .lg-margin-90px-left {margin-left:90px;}
    .lg-margin-95px-left {margin-left:95px;}
    .lg-margin-100px-left {margin-left:100px;}

    /* margin left right */
    .lg-margin-one-lr {margin-left:1%; margin-right:1%;}
    .lg-margin-one-half-lr {margin-left:1.5%; margin-right:1.5%;}
    .lg-margin-two-lr {margin-left:2%; margin-right:2%;}
    .lg-margin-two-half-lr {margin-left:2.5%; margin-right:2.5%;}
    .lg-margin-three-lr {margin-left:3%; margin-right:3%;}
    .lg-margin-three-half-lr {margin-left:3.5%; margin-right:3.5%;}
    .lg-margin-four-lr {margin-left:4%; margin-right:4%;}
    .lg-margin-four-half-lr {margin-left:4.5%; margin-right:4.5%;}
    .lg-margin-five-lr {margin-left:5%; margin-right:5%;}
    .lg-margin-five-half-lr {margin-left:5.5%; margin-right:5.5%;}
    .lg-margin-six-lr {margin-left:6%; margin-right:6%;}
    .lg-margin-six-half-lr {margin-left:6.5%; margin-right:6.5%;}
    .lg-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .lg-margin-seven-half-lr {margin-left:7.5%; margin-right:7.5%;}
    .lg-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .lg-margin-eight-half-lr {margin-left:8.5%; margin-right:8.5%;}
    .lg-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .lg-margin-nine-half-lr {margin-left:9.5%; margin-right:9.5%;}
    .lg-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .lg-margin-ten-half-lr {margin-left:10.5%; margin-right:10.5%;}
    .lg-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .lg-margin-eleven-half-lr {margin-left:11.5%; margin-right:11.5%;}
    .lg-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .lg-margin-twelve-half-lr {margin-left:12.5%; margin-right:12.5%;}
    .lg-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .lg-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .lg-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .lg-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .lg-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .lg-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .lg-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .lg-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .lg-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .lg-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .lg-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .lg-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .lg-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .lg-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .lg-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .lg-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .lg-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .lg-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .lg-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .lg-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .lg-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .lg-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .lg-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .lg-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .lg-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .lg-margin-5px-lr {margin-left:5px; margin-right:5px;}
    .lg-margin-10px-lr {margin-left:10px; margin-right:10px;}
    .lg-margin-15px-lr {margin-left:15px; margin-right:15px;}
    .lg-margin-20px-lr {margin-left:20px; margin-right:20px;}
    .lg-margin-25px-lr {margin-left:25px; margin-right:25px;}
    .lg-margin-30px-lr {margin-left:30px; margin-right:30px;}
    .lg-margin-35px-lr {margin-left:35px; margin-right:35px;}
    .lg-margin-40px-lr {margin-left:40px; margin-right:40px;}
    .lg-margin-45px-lr {margin-left:45px; margin-right:45px;}
    .lg-margin-50px-lr {margin-left:50px; margin-right:50px;}
    .lg-margin-55px-lr {margin-left:55px; margin-right:55px;}
    .lg-margin-60px-lr {margin-left:60px; margin-right:60px;}
    .lg-margin-65px-lr {margin-left:65px; margin-right:60px;}
    .lg-margin-70px-lr {margin-left:70px; margin-right:65px;}
    .lg-margin-75px-lr {margin-left:75px; margin-right:70px;}
    .lg-margin-80px-lr {margin-left:80px; margin-right:75px;}
    .lg-margin-85px-lr {margin-left:85px; margin-right:80px;}
    .lg-margin-90px-lr {margin-left:90px; margin-right:85px;}
    .lg-margin-95px-lr {margin-left:95px; margin-right:90px;}
    .lg-margin-100px-lr {margin-left:100px; margin-right:100px;}

    /* margin top bottom */
    .lg-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .lg-margin-one-half-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .lg-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .lg-margin-two-half-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .lg-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .lg-margin-three-half-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .lg-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .lg-margin-four-half-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .lg-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .lg-margin-five-half-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .lg-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .lg-margin-six-half-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .lg-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .lg-margin-seven-half-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .lg-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .lg-margin-eight-half-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .lg-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .lg-margin-nine-half-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .lg-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .lg-margin-ten-half-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .lg-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .lg-margin-eleven-half-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .lg-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .lg-margin-twelve-half-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .lg-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .lg-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .lg-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .lg-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .lg-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .lg-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .lg-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .lg-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .lg-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .lg-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .lg-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .lg-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .lg-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .lg-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .lg-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .lg-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .lg-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .lg-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .lg-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .lg-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .lg-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .lg-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .lg-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .lg-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .lg-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .lg-margin-5px-tb {margin-top:5px; margin-bottom:5px;}
    .lg-margin-10px-tb {margin-top:10px; margin-bottom:10px;}
    .lg-margin-15px-tb {margin-top:15px; margin-bottom:15px;}
    .lg-margin-20px-tb {margin-top:20px; margin-bottom:20px;}
    .lg-margin-25px-tb {margin-top:25px; margin-bottom:25px;}
    .lg-margin-30px-tb {margin-top:30px; margin-bottom:30px;}
    .lg-margin-35px-tb {margin-top:35px; margin-bottom:35px;}
    .lg-margin-40px-tb {margin-top:40px; margin-bottom:40px;}
    .lg-margin-45px-tb {margin-top:45px; margin-bottom:45px;}
    .lg-margin-50px-tb {margin-top:50px; margin-bottom:50px;}
    .lg-margin-55px-tb {margin-top:55px; margin-bottom:55px;}
    .lg-margin-60px-tb {margin-top:60px; margin-bottom:60px;}
    .lg-margin-65px-tb {margin-top:65px; margin-bottom:60px;}
    .lg-margin-70px-tb {margin-top:70px; margin-bottom:65px;}
    .lg-margin-75px-tb {margin-top:75px; margin-bottom:70px;}
    .lg-margin-80px-tb {margin-top:80px; margin-bottom:75px;}
    .lg-margin-85px-tb {margin-top:85px; margin-bottom:80px;}
    .lg-margin-90px-tb {margin-top:90px; margin-bottom:85px;}
    .lg-margin-95px-tb {margin-top:95px; margin-bottom:90px;}
    .lg-margin-100px-tb {margin-top:100px; margin-bottom:100px;}

    /* header padding top */
    .lg-header-padding-top { padding-top: 82px;}
    .lg-header-margin-top { margin-top: 80px;}

    /* padding */
    .lg-no-padding {padding:0 !important}
    .lg-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important}
    .lg-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important}
    .lg-no-padding-top {padding-top:0 !important}
    .lg-no-padding-bottom {padding-bottom:0 !important}
    .lg-no-padding-left {padding-left:0 !important}
    .lg-no-padding-right {padding-right:0 !important}
    .lg-padding-one-all {padding:1%;}
    .lg-padding-one-half-all {padding:1.5%;}
    .lg-padding-two-all {padding:2%;}
    .lg-padding-two-half-all {padding:2.5%;}
    .lg-padding-three-all {padding:3%;}
    .lg-padding-three-half-all {padding:3.5%;}
    .lg-padding-four-all {padding:4%;}
    .lg-padding-four-half-all {padding:4.5%;}
    .lg-padding-five-all {padding:5%;}
    .lg-padding-five-half-all {padding:5.5%;}
    .lg-padding-six-all {padding:6%;}
    .lg-padding-six-half-all {padding:6.5%;}
    .lg-padding-seven-all {padding:7%;}
    .lg-padding-seven-half-all {padding:7.5%;}
    .lg-padding-eight-all {padding:8%;}
    .lg-padding-eight-half-all {padding:8.5%;}
    .lg-padding-nine-all {padding:9%;}
    .lg-padding-nine-half-all {padding:9.5%;}
    .lg-padding-ten-all {padding:10%;}
    .lg-padding-ten-half-all {padding:10.5%;}
    .lg-padding-eleven-all {padding:11%;}
    .lg-padding-eleven-half-all {padding:11.5%;}
    .lg-padding-twelve-all {padding:12%;}
    .lg-padding-twelve-half-all {padding:12.5%;}
    .lg-padding-thirteen-all {padding:13%;}
    .lg-padding-thirteen-half-all {padding:13.5%;}
    .lg-padding-fourteen-all {padding:14%;}
    .lg-padding-fourteen-half-all {padding:14.5%;}
    .lg-padding-fifteen-all {padding:15%;}
    .lg-padding-fifteen-half-all {padding:15.5%;}
    .lg-padding-sixteen-all {padding:16%;}
    .lg-padding-sixteen-half-all {padding:16.5%;}
    .lg-padding-seventeen-all {padding:17%;}
    .lg-padding-seventeen-half-all {padding:17.5%;}
    .lg-padding-eighteen-all {padding:18%;}
    .lg-padding-eighteen-half-all {padding:18.5%;}
    .lg-padding-nineteen-all {padding:19%;}
    .lg-padding-nineteen-half-all {padding:19.5%;}
    .lg-padding-twenty-all {padding:20%;}
    .lg-padding-twenty-half-all {padding:20.5%;}
    .lg-padding-twenty-one-all {padding:21%;}
    .lg-padding-twenty-one-half-all {padding:21.5%;}
    .lg-padding-twenty-two-all {padding:22%;}
    .lg-padding-twenty-two-half-all {padding:22.5%;}
    .lg-padding-twenty-three-all {padding:23%;}
    .lg-padding-twenty-three-half-all {padding:23.5%;}
    .lg-padding-twenty-four-all {padding:24%;}
    .lg-padding-twenty-four-half-all {padding:24.5%;}
    .lg-padding-twenty-five-all {padding:25%;}
    .lg-padding-5px-all {padding:5px;}
    .lg-padding-10px-all {padding:10px;}
    .lg-padding-15px-all {padding:15px;}
    .lg-padding-20px-all {padding:20px;}
    .lg-padding-25px-all {padding:25px;}
    .lg-padding-30px-all {padding:30px;}
    .lg-padding-35px-all {padding:35px;}
    .lg-padding-40px-all {padding:40px;}
    .lg-padding-45px-all {padding:45px;}
    .lg-padding-50px-all {padding:50px;}
    .lg-padding-55px-all {padding:55px;}
    .lg-padding-60px-all {padding:60px;}
    .lg-padding-65px-all {padding:65px;}
    .lg-padding-70px-all {padding:70px;}
    .lg-padding-75px-all {padding:75px;}
    .lg-padding-80px-all {padding:80px;}
    .lg-padding-85px-all {padding:85px;}
    .lg-padding-90px-all {padding:90px;}
    .lg-padding-95px-all {padding:95px;}
    .lg-padding-100px-all {padding:100px;}

    /* padding top */
    .lg-padding-one-top {padding-top:1%;}
    .lg-padding-one-half-top {padding-top:1.5%;}
    .lg-padding-two-top {padding-top:2%;}
    .lg-padding-two-half-top {padding-top:2.5%;}
    .lg-padding-three-top {padding-top:3%;}
    .lg-padding-three-half-top {padding-top:3.5%;}
    .lg-padding-four-top {padding-top:4%;}
    .lg-padding-four-half-top {padding-top:4.5%;}
    .lg-padding-five-top {padding-top:5%;}
    .lg-padding-five-half-top {padding-top:5.5%;}
    .lg-padding-six-top {padding-top:6%;}
    .lg-padding-six-half-top {padding-top:6.5%;}
    .lg-padding-seven-top {padding-top:7%;}
    .lg-padding-seven-half-top {padding-top:7.5%;}
    .lg-padding-eight-top {padding-top:8%;}
    .lg-padding-eight-half-top {padding-top:8.5%;}
    .lg-padding-nine-top {padding-top:9%;}
    .lg-padding-nine-half-top {padding-top:9.5%;}
    .lg-padding-ten-top {padding-top:10%;}
    .lg-padding-ten-half-top {padding-top:10.5%;}
    .lg-padding-eleven-top {padding-top:11%;}
    .lg-padding-eleven-half-top {padding-top:11.5%;}
    .lg-padding-twelve-top {padding-top:12%;}
    .lg-padding-twelve-half-top {padding-top:12.5%;}
    .lg-padding-thirteen-top {padding-top:13%;}
    .lg-padding-thirteen-half-top {padding-top:13.5%;}
    .lg-padding-fourteen-top {padding-top:14%;}
    .lg-padding-fourteen-half-top {padding-top:14.5%;}
    .lg-padding-fifteen-top {padding-top:15%;}
    .lg-padding-fifteen-half-top {padding-top:15.5%;}
    .lg-padding-sixteen-top {padding-top:16%;}
    .lg-padding-sixteen-half-top {padding-top:16.5%;}
    .lg-padding-seventeen-top {padding-top:17%;}
    .lg-padding-seventeen-half-top {padding-top:17.5%;}
    .lg-padding-eighteen-top {padding-top:18%;}
    .lg-padding-eighteen-half-top {padding-top:18.5%;}
    .lg-padding-nineteen-top {padding-top:19%;}
    .lg-padding-nineteen-half-top {padding-top:19.5%;}
    .lg-padding-twenty-top {padding-top:20%;}
    .lg-padding-twenty-half-top {padding-top:20.5%;}
    .lg-padding-twenty-one-top {padding-top:21%;}
    .lg-padding-twenty-one-half-top {padding-top:21.5%;}
    .lg-padding-twenty-two-top {padding-top:22%;}
    .lg-padding-twenty-two-half-top {padding-top:22.5%;}
    .lg-padding-twenty-three-top {padding-top:23%;}
    .lg-padding-twenty-three-half-top {padding-top:23.5%;}
    .lg-padding-twenty-four-top {padding-top:24%;}
    .lg-padding-twenty-four-half-top {padding-top:24.5%;}
    .lg-padding-twenty-five-top {padding-top:25%;}
    .lg-padding-5px-top {padding-top:5px;}
    .lg-padding-10px-top {padding-top:10px;}
    .lg-padding-15px-top {padding-top:15px;}
    .lg-padding-20px-top {padding-top:20px;}
    .lg-padding-25px-top {padding-top:25px;}
    .lg-padding-30px-top {padding-top:30px;}
    .lg-padding-35px-top {padding-top:35px;}
    .lg-padding-40px-top {padding-top:40px;}
    .lg-padding-45px-top {padding-top:45px;}
    .lg-padding-50px-top {padding-top:50px;}
    .lg-padding-55px-top {padding-top:55px;}
    .lg-padding-60px-top {padding-top:60px;}
    .lg-padding-65px-top {padding-top:65px;}
    .lg-padding-70px-top {padding-top:70px;}
    .lg-padding-75px-top {padding-top:75px;}
    .lg-padding-80px-top {padding-top:80px;}
    .lg-padding-85px-top {padding-top:85px;}
    .lg-padding-90px-top {padding-top:90px;}
    .lg-padding-95px-top {padding-top:95px;}
    .lg-padding-100px-top {padding-top:100px;}

    /* padding bottom */
    .lg-padding-one-bottom {padding-bottom:1%;}
    .lg-padding-one-half-bottom {padding-bottom:1.5%;}
    .lg-padding-two-bottom {padding-bottom:2%;}
    .lg-padding-two-half-bottom {padding-bottom:2.5%;}
    .lg-padding-three-bottom {padding-bottom:3%;}
    .lg-padding-three-half-bottom {padding-bottom:3.5%;}
    .lg-padding-four-bottom {padding-bottom:4%;}
    .lg-padding-four-half-bottom {padding-bottom:4.5%;}
    .lg-padding-five-bottom {padding-bottom:5%;}
    .lg-padding-five-half-bottom {padding-bottom:5.5%;}
    .lg-padding-six-bottom {padding-bottom:6%;}
    .lg-padding-six-half-bottom {padding-bottom:6.5%;}
    .lg-padding-seven-bottom {padding-bottom:7%;}
    .lg-padding-seven-half-bottom {padding-bottom:7.5%;}
    .lg-padding-eight-bottom {padding-bottom:8%;}
    .lg-padding-eight-half-bottom {padding-bottom:8.5%;}
    .lg-padding-nine-bottom {padding-bottom:9%;}
    .lg-padding-nine-half-bottom {padding-bottom:9.5%;}
    .lg-padding-ten-bottom {padding-bottom:10%;}
    .lg-padding-ten-half-bottom {padding-bottom:10.5%;}
    .lg-padding-eleven-bottom {padding-bottom:11%;}
    .lg-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .lg-padding-twelve-bottom {padding-bottom:12%;}
    .lg-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .lg-padding-thirteen-bottom {padding-bottom:13%;}
    .lg-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .lg-padding-fourteen-bottom {padding-bottom:14%;}
    .lg-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .lg-padding-fifteen-bottom {padding-bottom:15%;}
    .lg-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .lg-padding-sixteen-bottom {padding-bottom:16%;}
    .lg-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .lg-padding-seventeen-bottom {padding-bottom:17%;}
    .lg-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .lg-padding-eighteen-bottom {padding-bottom:18%;}
    .lg-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .lg-padding-nineteen-bottom {padding-bottom:19%;}
    .lg-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .lg-padding-twenty-bottom {padding-bottom:20%;}
    .lg-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .lg-padding-twenty-one-bottom {padding-bottom:21%;}
    .lg-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .lg-padding-twenty-two-bottom {padding-bottom:22%;}
    .lg-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .lg-padding-twenty-three-bottom {padding-bottom:23%;}
    .lg-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .lg-padding-twenty-four-bottom {padding-bottom:24%;}
    .lg-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .lg-padding-twenty-five-bottom {padding-bottom:25%;}
    .lg-padding-5px-bottom {padding-bottom:5px;}
    .lg-padding-10px-bottom {padding-bottom:10px;}
    .lg-padding-15px-bottom {padding-bottom:15px;}
    .lg-padding-20px-bottom {padding-bottom:20px;}
    .lg-padding-25px-bottom {padding-bottom:25px;}
    .lg-padding-30px-bottom {padding-bottom:30px;}
    .lg-padding-35px-bottom {padding-bottom:35px;}
    .lg-padding-40px-bottom {padding-bottom:40px;}
    .lg-padding-45px-bottom {padding-bottom:45px;}
    .lg-padding-50px-bottom {padding-bottom:50px;}
    .lg-padding-55px-bottom {padding-bottom:55px;}
    .lg-padding-60px-bottom {padding-bottom:60px;}
    .lg-padding-65px-bottom {padding-bottom:65px;}
    .lg-padding-70px-bottom {padding-bottom:70px;}
    .lg-padding-75px-bottom {padding-bottom:75px;}
    .lg-padding-80px-bottom {padding-bottom:80px;}
    .lg-padding-85px-bottom {padding-bottom:85px;}
    .lg-padding-90px-bottom {padding-bottom:90px;}
    .lg-padding-95px-bottom {padding-bottom:95px;}
    .lg-padding-100px-bottom {padding-bottom:100px;}

    /* padding right */
    .lg-padding-one-right {padding-right:1%;}
    .lg-padding-one-half-right {padding-right:1.5%;}
    .lg-padding-two-right {padding-right:2%;}
    .lg-padding-two-half-right {padding-right:2.5%;}
    .lg-padding-three-right {padding-right:3%;}
    .lg-padding-three-half-right {padding-right:3.5%;}
    .lg-padding-four-right {padding-right:4%;}
    .lg-padding-four-half-right {padding-right:4.5%;}
    .lg-padding-five-right {padding-right:5%;}
    .lg-padding-five-half-right {padding-right:5.5%;}
    .lg-padding-six-right {padding-right:6%;}
    .lg-padding-six-half-right {padding-right:6.5%;}
    .lg-padding-seven-right {padding-right:7%;}
    .lg-padding-seven-half-right {padding-right:7.5%;}
    .lg-padding-eight-right {padding-right:8%;}
    .lg-padding-eight-half-right {padding-right:8.5%;}
    .lg-padding-nine-right {padding-right:9%;}
    .lg-padding-nine-half-right {padding-right:9.5%;}
    .lg-padding-ten-right {padding-right:10%;}
    .lg-padding-ten-half-right {padding-right:10.5%;}
    .lg-padding-eleven-right {padding-right:11%;}
    .lg-padding-eleven-half-right {padding-right:11.5%;}
    .lg-padding-twelve-right {padding-right:12%;}
    .lg-padding-twelve-half-right {padding-right:12.5%;}
    .lg-padding-thirteen-right {padding-right:13%;}
    .lg-padding-thirteen-half-right {padding-right:13.5%;}
    .lg-padding-fourteen-right {padding-right:14%;}
    .lg-padding-fourteen-half-right {padding-right:14.5%;}
    .lg-padding-fifteen-right {padding-right:15%;}
    .lg-padding-fifteen-half-right {padding-right:15.5%;}
    .lg-padding-sixteen-right {padding-right:16%;}
    .lg-padding-sixteen-half-right {padding-right:16.5%;}
    .lg-padding-seventeen-right {padding-right:17%;}
    .lg-padding-seventeen-half-right {padding-right:17.5%;}
    .lg-padding-eighteen-right {padding-right:18%;}
    .lg-padding-eighteen-half-right {padding-right:18.5%;}
    .lg-padding-nineteen-right {padding-right:19%;}
    .lg-padding-nineteen-half-right {padding-right:19.5%;}
    .lg-padding-twenty-right {padding-right:20%;}
    .lg-padding-twenty-half-right {padding-right:20.5%;}
    .lg-padding-twenty-one-right {padding-right:21%;}
    .lg-padding-twenty-one-half-right {padding-right:21.5%;}
    .lg-padding-twenty-two-right {padding-right:22%;}
    .lg-padding-twenty-two-half-right {padding-right:22.5%;}
    .lg-padding-twenty-three-right {padding-right:23%;}
    .lg-padding-twenty-three-half-right {padding-right:23.5%;}
    .lg-padding-twenty-four-right {padding-right:24%;}
    .lg-padding-twenty-four-half-right {padding-right:24.5%;}
    .lg-padding-twenty-five-right {padding-right:25%;}
    .lg-padding-5px-right {padding-right:5px;}
    .lg-padding-10px-right {padding-right:10px;}
    .lg-padding-15px-right {padding-right:15px;}
    .lg-padding-20px-right {padding-right:20px;}
    .lg-padding-25px-right {padding-right:25px;}
    .lg-padding-30px-right {padding-right:30px;}
    .lg-padding-35px-right {padding-right:35px;}
    .lg-padding-40px-right {padding-right:40px;}
    .lg-padding-45px-right {padding-right:45px;}
    .lg-padding-50px-right {padding-right:50px;}
    .lg-padding-55px-right {padding-right:55px;}
    .lg-padding-60px-right {padding-right:60px;}
    .lg-padding-65px-right {padding-right:65px;}
    .lg-padding-70px-right {padding-right:70px;}
    .lg-padding-75px-right {padding-right:75px;}
    .lg-padding-80px-right {padding-right:80px;}
    .lg-padding-85px-right {padding-right:85px;}
    .lg-padding-90px-right {padding-right:90px;}
    .lg-padding-95px-right {padding-right:95px;}
    .lg-padding-100px-right {padding-right:100px;}

    /* padding left */
    .lg-padding-one-left {padding-left:1%;}
    .lg-padding-one-half-left {padding-left:1.5%;}
    .lg-padding-two-left {padding-left:2%;}
    .lg-padding-two-half-left {padding-left:2.5%;}
    .lg-padding-three-left {padding-left:3%;}
    .lg-padding-three-half-left {padding-left:3.5%;}
    .lg-padding-four-left {padding-left:4%;}
    .lg-padding-four-half-left {padding-left:4.5%;}
    .lg-padding-five-left {padding-left:5%;}
    .lg-padding-five-half-left {padding-left:5.5%;}
    .lg-padding-six-left {padding-left:6%;}
    .lg-padding-six-half-left {padding-left:6.5%;}
    .lg-padding-seven-left {padding-left:7%;}
    .lg-padding-seven-half-left {padding-left:7.5%;}
    .lg-padding-eight-left {padding-left:8%;}
    .lg-padding-eight-half-left {padding-left:8.5%;}
    .lg-padding-nine-left {padding-left:9%;}
    .lg-padding-nine-half-left {padding-left:9.5%;}
    .lg-padding-ten-left {padding-left:10%;}
    .lg-padding-ten-half-left {padding-left:10.5%;}
    .lg-padding-eleven-left {padding-left:11%;}
    .lg-padding-eleven-half-left {padding-left:11.5%;}
    .lg-padding-twelve-left {padding-left:12%;}
    .lg-padding-twelve-half-left {padding-left:12.5%;}
    .lg-padding-thirteen-left {padding-left:13%;}
    .lg-padding-thirteen-half-left {padding-left:13.5%;}
    .lg-padding-fourteen-left {padding-left:14%;}
    .lg-padding-fourteen-half-left {padding-left:14.5%;}
    .lg-padding-fifteen-left {padding-left:15%;}
    .lg-padding-fifteen-half-left {padding-left:15.5%;}
    .lg-padding-sixteen-left {padding-left:16%;}
    .lg-padding-sixteen-half-left {padding-left:16.5%;}
    .lg-padding-seventeen-left {padding-left:17%;}
    .lg-padding-seventeen-half-left {padding-left:17.5%;}
    .lg-padding-eighteen-left {padding-left:18%;}
    .lg-padding-eighteen-half-left {padding-left:18.5%;}
    .lg-padding-nineteen-left {padding-left:19%;}
    .lg-padding-nineteen-half-left {padding-left:19.5%;}
    .lg-padding-twenty-left {padding-left:20%;}
    .lg-padding-twenty-half-left {padding-left:20.5%;}
    .lg-padding-twenty-one-left {padding-left:21%;}
    .lg-padding-twenty-one-half-left {padding-left:21.5%;}
    .lg-padding-twenty-two-left {padding-left:22%;}
    .lg-padding-twenty-two-half-left {padding-left:22.5%;}
    .lg-padding-twenty-three-left {padding-left:23%;}
    .lg-padding-twenty-three-half-left {padding-left:23.5%;}
    .lg-padding-twenty-four-left {padding-left:24%;}
    .lg-padding-twenty-four-half-left {padding-left:24.5%;}
    .lg-padding-twenty-five-left {padding-left:25%;}
    .lg-padding-5px-left {padding-left:5px;}
    .lg-padding-10px-left {padding-left:10px;}
    .lg-padding-15px-left {padding-left:15px;}
    .lg-padding-20px-left {padding-left:20px;}
    .lg-padding-25px-left {padding-left:25px;}
    .lg-padding-30px-left {padding-left:30px;}
    .lg-padding-35px-left {padding-left:35px;}
    .lg-padding-40px-left {padding-left:40px;}
    .lg-padding-45px-left {padding-left:45px;}
    .lg-padding-50px-left {padding-left:50px;}
    .lg-padding-55px-left {padding-left:55px;}
    .lg-padding-60px-left {padding-left:60px;}
    .lg-padding-65px-left {padding-left:65px;}
    .lg-padding-70px-left {padding-left:70px;}
    .lg-padding-75px-left {padding-left:75px;}
    .lg-padding-80px-left {padding-left:80px;}
    .lg-padding-85px-left {padding-left:85px;}
    .lg-padding-90px-left {padding-left:90px;}
    .lg-padding-95px-left {padding-left:95px;}
    .lg-padding-100px-left {padding-left:100px;}

    /* padding top bottom */
    .lg-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .lg-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .lg-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .lg-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .lg-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .lg-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .lg-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .lg-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .lg-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .lg-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .lg-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .lg-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .lg-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .lg-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .lg-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .lg-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .lg-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .lg-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .lg-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .lg-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .lg-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .lg-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .lg-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .lg-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .lg-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .lg-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .lg-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .lg-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .lg-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .lg-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .lg-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .lg-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .lg-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .lg-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .lg-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .lg-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .lg-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .lg-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .lg-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .lg-padding-twenty-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .lg-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .lg-padding-twenty-one-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .lg-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .lg-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .lg-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .lg-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .lg-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .lg-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .lg-padding-twenty-five-tb {padding-top:25%; padding-bottom:25%;}
    .lg-padding-5px-tb {padding-top:5px; padding-bottom:5px;}
    .lg-padding-10px-tb {padding-top:10px; padding-bottom:10px;}
    .lg-padding-15px-tb {padding-top:15px; padding-bottom:15px;}
    .lg-padding-20px-tb {padding-top:20px; padding-bottom:20px;}
    .lg-padding-25px-tb {padding-top:25px; padding-bottom:25px;}
    .lg-padding-30px-tb {padding-top:30px; padding-bottom:30px;}
    .lg-padding-35px-tb {padding-top:35px; padding-bottom:35px;}
    .lg-padding-40px-tb {padding-top:40px; padding-bottom:40px;}
    .lg-padding-45px-tb {padding-top:45px; padding-bottom:45px;}
    .lg-padding-50px-tb {padding-top:50px; padding-bottom:50px;}
    .lg-padding-55px-tb {padding-top:55px; padding-bottom:55px;}
    .lg-padding-60px-tb {padding-top:60px; padding-bottom:60px;}
    .lg-padding-65px-tb {padding-top:65px; padding-bottom:65px;}
    .lg-padding-70px-tb {padding-top:70px; padding-bottom:70px;}
    .lg-padding-75px-tb {padding-top:75px; padding-bottom:75px;}
    .lg-padding-80px-tb {padding-top:80px; padding-bottom:80px;}
    .lg-padding-85px-tb {padding-top:85px; padding-bottom:85px;}
    .lg-padding-90px-tb {padding-top:90px; padding-bottom:90px;}
    .lg-padding-95px-tb {padding-top:95px; padding-bottom:95px;}
    .lg-padding-100px-tb {padding-top:100px; padding-bottom:100px;}

    /* padding left right */
    .lg-padding-one-lr {padding-left:1%; padding-right:1%;}
    .lg-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .lg-padding-two-lr {padding-left:2%; padding-right:2%;}
    .lg-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .lg-padding-three-lr {padding-left:3%; padding-right:3%;}
    .lg-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .lg-padding-four-lr {padding-left:4%; padding-right:4%;}
    .lg-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .lg-padding-five-lr {padding-left:5%; padding-right:5%;}
    .lg-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .lg-padding-six-lr {padding-left:6%; padding-right:6%;}
    .lg-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .lg-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .lg-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .lg-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .lg-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .lg-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .lg-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .lg-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .lg-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .lg-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .lg-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .lg-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .lg-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .lg-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .lg-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .lg-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .lg-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .lg-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .lg-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .lg-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .lg-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .lg-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .lg-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .lg-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .lg-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .lg-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .lg-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .lg-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .lg-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .lg-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .lg-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .lg-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .lg-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .lg-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .lg-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .lg-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .lg-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .lg-padding-twenty-five-lr {padding-left:25%; padding-right:25%;}
    .lg-padding-5px-lr {padding-left:5px; padding-right:5px;}
    .lg-padding-10px-lr {padding-left:10px; padding-right:10px;}
    .lg-padding-15px-lr {padding-left:15px; padding-right:15px;}
    .lg-padding-20px-lr {padding-left:20px; padding-right:20px;}
    .lg-padding-25px-lr {padding-left:25px; padding-right:25px;}
    .lg-padding-30px-lr {padding-left:30px; padding-right:30px;}
    .lg-padding-35px-lr {padding-left:35px; padding-right:35px;}
    .lg-padding-40px-lr {padding-left:40px; padding-right:40px;}
    .lg-padding-45px-lr {padding-left:45px; padding-right:45px;}
    .lg-padding-50px-lr {padding-left:50px; padding-right:50px;}
    .lg-padding-55px-lr {padding-left:55px; padding-right:55px;}
    .lg-padding-60px-lr {padding-left:60px; padding-right:60px;}
    .lg-padding-65px-lr {padding-left:65px; padding-right:65px;}
    .lg-padding-70px-lr {padding-left:70px; padding-right:70px;}
    .lg-padding-75px-lr {padding-left:75px; padding-right:75px;}
    .lg-padding-80px-lr {padding-left:80px; padding-right:80px;}
    .lg-padding-85px-lr {padding-left:85px; padding-right:85px;}
    .lg-padding-90px-lr {padding-left:90px; padding-right:90px;}
    .lg-padding-95px-lr {padding-left:95px; padding-right:95px;}
    .lg-padding-100px-lr {padding-left:100px; padding-right:100px;}

    /* col height  */
    .one-fourth-screen {height:700px;}
    .lg-small-screen {height:300px;}

    /* owl slider height */
    .slider-half-screen { height: 650px; }

    /* top */
    .lg-top-auto {top:auto}
    .lg-top-0 {top:0 !important}
    .lg-top-1 {top:1px}
    .lg-top-2 {top:2px}
    .lg-top-3 {top:3px}
    .lg-top-4 {top:4px}
    .lg-top-5 {top:5px}
    .lg-top-6 {top:6px}
    .lg-top-7 {top:7px}
    .lg-top-8 {top:8px}
    .lg-top-9 {top:9px}
    .lg-top-10 {top:10px}
    .lg-top-minus1 {top:-1px}
    .lg-top-minus2 {top:-2px}
    .lg-top-minus3 {top:-3px}
    .lg-top-minus4 {top:-4px}
    .lg-top-minus5 {top:-5px}
    .lg-top-minus6 {top:-6px}
    .lg-top-minus7 {top:-7px}
    .lg-top-minus8 {top:-8px}
    .lg-top-minus9 {top:-9px}
    .lg-top-minus10 {top:-10px}
    .lg-top-minus15 {top:-15px}
    .lg-top-minus20 {top:-20px}
    .lg-top-minus25 {top:-25px}
    .lg-top-minus30 {top:-30px}
    .lg-top-minus35 {top:-35px}
    .lg-top-minus40 {top:-40px}

    /* bottom */
    .lg-bottom-auto {bottom:auto}
    .lg-bottom-0 {bottom:0}
    .lg-bottom-1 {bottom:1px}
    .lg-bottom-2 {bottom:2px}
    .lg-bottom-3 {bottom:3px}
    .lg-bottom-4 {bottom:4px}
    .lg-bottom-5 {bottom:5px}
    .lg-bottom-6 {bottom:6px}
    .lg-bottom-7 {bottom:7px}
    .lg-bottom-8 {bottom:8px}
    .lg-bottom-9 {bottom:9px}
    .lg-bottom-10 {bottom:10px}
    .lg-bottom-15 {bottom:15px}
    .lg-bottom-20 {bottom:20px}
    .lg-bottom-25 {bottom:25px}
    .lg-bottom-30 {bottom:30px}
    .lg-bottom-35 {bottom:35px}
    .lg-bottom-40 {bottom:40px}
    .lg-bottom-45 {bottom:45px}
    .lg-bottom-50 {bottom:50px}
    .lg-bottom-minus30 {bottom:-30px}
    .lg-bottom-minus50 {bottom:-50px}
    .lg-bottom-minus55 { bottom: -55px; }
    .lg-bottom-minus60 { bottom: -60px; }

    /* left */
    .lg-left-auto {left:auto}
    .lg-left-0 {left:0}
    .lg-left-1 {left:1px}
    .lg-left-2 {left:2px}
    .lg-left-3 {left:3px}
    .lg-left-4 {left:4px}
    .lg-left-5 {left:5px}
    .lg-left-6 {left:6px}
    .lg-left-7 {left:7px}
    .lg-left-8 {left:8px}
    .lg-left-9 {left:9px}
    .lg-left-10 {bottom:10px}
    .lg-left-15 {bottom:15px}
    .lg-left-20 {bottom:20px}
    .lg-left-25 {bottom:25px}
    .lg-left-30 {bottom:30px}
    .lg-left-35 {bottom:35px}
    .lg-left-40 {bottom:40px}

    /* right */
    .lg-right-auto {right:auto}
    .lg-right-0 {right:0}
    .lg-right-1 {right:1px}
    .lg-right-2 {right:2px}
    .lg-right-3 {right:3px}
    .lg-right-4 {right:4px}
    .lg-right-5 {right:5px}
    .lg-right-6 {right:6px}
    .lg-right-7 {right:7px}
    .lg-right-8 {right:8px}
    .lg-right-9 {right:9px}
    .lg-right-10 {right:10px}
    .lg-right-15 {right:15px}
    .lg-right-20 {right:20px}
    .lg-right-25 {right:25px}
    .lg-right-30 {right:30px}
    .lg-right-35 {right:35px}
    .lg-right-40 {right:40px}

    /* width */
    .lg-w-10 {width:10% !important;}
    .lg-w-15 {width:15% !important;}
    .lg-w-20 {width:20% !important;}
    .lg-w-25 {width:25% !important;}
    .lg-w-30 {width:30% !important;}
    .lg-w-35 {width:35% !important;}
    .lg-w-40 {width:40% !important;}
    .lg-w-45 {width:45% !important;}
    .lg-w-50 {width:50% !important;}
    .lg-w-55 {width:55% !important;}
    .lg-w-60 {width:60% !important;}
    .lg-w-65 {width:65% !important;}
    .lg-w-70 {width:70% !important;}
    .lg-w-75 {width:75% !important;}
    .lg-w-80 {width:80% !important;}
    .lg-w-85 {width:85% !important;}
    .lg-w-90 {width:90% !important;}
    .lg-w-95 {width:95% !important;}
    .lg-w-100 {width:100% !important;}
    .lg-w-auto {width: auto !important}

    .lg-w-50px {width:50px !important;}
    .lg-w-70px {width:70px !important;}
    .lg-w-75px {width:75px !important;}
    .lg-w-80px {width:80px !important;}
    .lg-w-85px {width:85px !important;}
    .lg-w-90px {width:90px !important;}
    .lg-w-95px {width:95px !important;}
    .lg-w-100px {width:100px !important;}
    .lg-w-120px {width:120px !important;}
    .lg-w-130px {width:130px !important;}
    .lg-w-150px {width:150px !important;}
    .lg-w-180px {width:180px !important;}
    .lg-w-200px {width:200px !important;}
    .lg-w-250px {width:250px !important;}
    .lg-w-300px {width:300px !important;}
    .lg-w-350px {width:350px !important;}
    .lg-w-400px {width:400px !important;}
    .lg-w-450px {width:450px !important;}
    .lg-w-500px {width:500px !important;}
    .lg-w-550px {width:550px !important;}
    .lg-w-600px {width:600px !important;}
    .lg-w-650px {width:650px !important;}
    .lg-w-700px {width:700px !important;}
    .lg-w-750px {width:750px !important;}
    .lg-w-800px {width:800px !important;}
    .lg-w-850px {width:850px !important;}
    .lg-w-900px {width:900px !important;}
    .lg-w-950px {width:950px !important;}
    .lg-w-1000px {width:1000px !important;}

    /* height */
    .lg-h-100 {height: 100%}
    .lg-h-auto {height:auto}
    .lg-h-10px {height: 10px !important}
    .lg-h-20px {height: 20px !important}
    .lg-h-30px {height: 30px !important}
    .lg-h-40px {height: 40px !important}
    .lg-h-50px {height: 50px !important}
    .lg-h-60px {height: 60px !important}
    .lg-h-70px {height: 70px !important}
    .lg-h-80px {height: 80px !important}
    .lg-h-90px {height: 90px !important}
    .lg-h-100px {height: 100px !important}
    .lg-h-150px {height: 150px !important}
    .lg-h-200px {height: 200px !important}
    .lg-h-250px {height: 250px !important}
    .lg-h-300px {height: 300px !important}
    .lg-h-350px {height: 350px !important}
    .lg-h-400px {height: 400px !important}
    .lg-h-450px {height: 450px !important}
    .lg-h-500px {height: 500px !important}
    .lg-h-550px {height: 550px !important}
    .lg-h-600px {height: 600px !important}
    .lg-h-650px {height: 650px !important}
    .lg-h-700px {height: 700px !important}

    /* line height */
    .lg-line-height-auto {line-height: normal;}
    .lg-line-height-10 {line-height:10px;}
    .lg-line-height-18 {line-height:18px;}
    .lg-line-height-20 {line-height:20px}
    .lg-line-height-24 {line-height:24px}
    .lg-line-height-22 {line-height:22px}
    .lg-line-height-26 {line-height:26px}
    .lg-line-height-28 {line-height:28px}
    .lg-line-height-30 {line-height:30px}
    .lg-line-height-35 {line-height:35px}
    .lg-line-height-40 {line-height:40px}
    .lg-line-height-45 {line-height:45px}
    .lg-line-height-50 {line-height:50px}
    .lg-line-height-55 {line-height:55px}
    .lg-line-height-60 {line-height:60px}
    .lg-line-height-65 {line-height:65px}
    .lg-line-height-70 {line-height:70px}
    .lg-line-height-75 {line-height:75px}
    .lg-line-height-80 {line-height:80px}
    .lg-line-height-85 {line-height:85px}
    .lg-line-height-90 {line-height:90px}
    .lg-line-height-95 {line-height:95px}
    .lg-line-height-100 {line-height:100px}

    /* border */
    .lg-no-border-top {border-top:0 !important}
    .lg-no-border-bottom {border-bottom:0 !important}
    .lg-no-border-right {border-right:0 !important}
    .lg-no-border-left {border-left:0 !important}

    /* box layout */
    .box-layout {padding: 0 0}
    .lg-no-background-img {background-image: none !important}

    /* navigation */
    header nav .navbar-nav > li > a { margin: 0 15px;}

    /* nav box width */
    header nav.nav-box-width { padding: 0 35px;}

    /* sidebar menu */
    nav.navbar.sidebar-nav ul.nav > li > a {padding: 10px 5px;}
    .nav.navbar-right-sidebar .dropdown.on .second-level {left: 230px}
    .sidebar-wrapper {padding-left: 230px;}
    nav.navbar.sidebar-nav {width:230px; padding-left: 0; padding-right:0}
    .nav.navbar-left-sidebar li { padding: 0 20px; }
    .sidebar-nav .dropdown-menu li > a { margin: 0 15px; }

    .sidebar-nav-style-1 .nav.navbar-left-sidebar .dropdown.on .second-level {left: 230px; width: 230px}
    .sidebar-nav-style-1 .nav.navbar-left-sidebar .second-level .dropdown.on .third-level {left: 460px;  width: 230px}

    /* feature-box-4 */
    .feature-box-4 figure figcaption {padding: 20px ;}
    .feature-box-4 figure figcaption .btn {opacity: 1;}
    .feature-box-4 figure:hover figcaption {transform: translateY(0px); -moz-transform: translateY(0px); -webkit-transform: translateY(0px); -o-transform: translateY(0px); -ms-transform: translateY(0px);}

    /* feature-box-10 */
    .feature-box-10 .number-center::before {width: 180%;}
    .feature-box-10 .number-center::after {width: 180%}

    /* feature box style 12 */
    .feature-box-12 .hover-content-box { width: 100%; }

    /* footer */
    .social-style-2 ul li { margin: 0 10px 5px 0}

    /* hamburg menu */
    .hamburger-menu-links {font-size: 40px; line-height: 45px}
    .menu-middle {width: 85%;}
    .side-left-menu-close.active { display: block; }

    /* sidebar nav style 2 */
    .left-nav-sidebar .container {width: 850px;}

    /* next prev button style 3 */
    .swiper-button-prev.swiper-prev-style3,.swiper-button-next.swiper-next-style3 {left:57.8%;}

    /* tab style 4 */
    .tab-style4 .nav-tabs li a {padding: 0 15px;}

    /* tab-style5 */
    .tab-style5 .tab-box { left: 28%; }
    .tab-style5 .nav-tabs li { font-size: 22px; line-height: 48px; }
    .tab-style5 .nav-tabs .nav-link.active { padding-left: 30px; }

    /* homepage 14 */
    .box-separator-line { left: -60px; top: 86px; }
    .right-image { bottom: -250px; width: 70%; right: -130px; }
    .content-right-slider .swiper-button-next {right: 20px;}
    .content-right-slider .swiper-button-prev  {left: 20px;}

    /* font size */
    .title-large { font-size: 85px; line-height: 85px;}
    .text-extra-large { font-size: 18px; line-height: 24px;}
    .percent {line-height: 125px;}

    /* mega menu */
    nav.navbar.bootsnav li.dropdown .mega-menu-full {padding: 25px;}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li {border-right: none;}
    nav.navbar.bootsnav li.dropdown ul li ul li:last-child{border-bottom: none;}
    nav.navbar.bootsnav li.dropdown .menu-back-div > ul {width: 100%;}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a,nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header {padding: 2px 0;}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child img { padding-left: 20px; }

    /* menu center */
    .menu-center .header-social-icon a i, .menu-center .header-searchbar a i {margin: 0 6px;}
    .menu-center .header-social-icon a:last-child i { margin-right: 0; }
    .menu-center .header-social-icon { padding-left: 8px; }

    /* new-menu page */
    .menu-new a:after { margin-left: 5px; }

    /* search form style */
    #search-header { width: 60%; }

    /* counter-box */
    .counter-box-3 .counter-box {display: inline-block; width: 100px;}

    /* counter box style 5 */
    .counter-box-5 .counter-box { width: 18%; }

    /* banner style 01 */
    .banner-style1 figure:hover figcaption p {height: 80px;}
    .banner-style1 figure figcaption img {width: 130px;}
    .swiper-pagination-bottom.swiper-container-horizontal .swiper-wrapper {margin-bottom: 50px;}
    .swiper-pagination-custom, .swiper-pagination-fraction {bottom: 0px;}
    .swiper-slider-third .swiper-pagination-bullets {bottom: 25px;}
    .swiper-full-screen .swiper-pagination, .hover-option3 .swiper-pagination {bottom: 30px;}

    /* hover option 3 */
    .hover-option3 .grid-item a:hover p { bottom: 0; opacity: 1; }
    .hover-option3 .grid-item figure p { bottom: 0; opacity: 1; margin-bottom: 30px;  }
    .hover-option3 .grid-item figure h6 { bottom: 0; }
    .hover-option3 .grid-item figure figcaption { padding: 0 30px; }

    /* next prev button style 2 */
    .swiper-next-style2 {right: 5%;}
    .swiper-prev-style2 {left: 5%;}
    .swiper-auto-slide .swiper-scrollbar { left: 15%; width: 70%; }
    .swiper-auto-slide .swiper-slide.swiper-slide-active .absolute-middle-center h2 { transition: all 0.4s ease-in-out 0.4s; -webkit-transition: all 0.4s ease-in-out 0.4s; -moz-transition: all 0.4s ease-in-out 0.4s; -ms-transition: all 0.4s ease-in-out 0.4s; -o-transition: all 0.4s ease-in-out 0.4s; }

    /* swiper-bottom-scrollbar-full */
    .swiper-bottom-scrollbar-full .swiper-slide img {max-height: 450px; opacity:1; filter: contrast(130%); -webkit-filter: contrast(130%); -moz-filter: contrast(130%); -ms-filter: contrast(130%); -o-filter: contrast(130%);}
    .swiper-bottom-scrollbar-full .swiper-slide:hover img {max-height: 450px; }
    .swiper-bottom-scrollbar-full .bottom-text { opacity: 1; letter-spacing: 3px;}
    .swiper-bottom-scrollbar-full .swiper-slide:hover .bottom-text { opacity: 1; letter-spacing: 3px; left: 0;}
    .swiper-bottom-scrollbar-full .swiper-slide:hover .hover-title-box { right: 0; opacity: 1;}
    .hover-title-box { opacity: 1; right: 0; text-align: center; top: inherit; bottom: 50%; transform: translateY(160px); -moz-transform: translateY(160px); -webkit-transform: translateY(160px); -o-transform: translateY(160px); -ms-transform: translateY(160px); z-index: 99; width: 100% }
    .swiper-bottom-scrollbar-full .swiper-slide .hover-title-box { visibility: visible; }

    /* blog header style1 */
    .blog-header-style1 li figure figcaption {padding: 30px;}
    .demo-heading {font-size: 35px; line-height: 41px;}

    /* grid */
    .grid.lg-grid-6col li { width: 16.67%; }
    .grid.lg-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.lg-grid-5col li { width: 20%; }
    .grid.lg-grid-5col li.grid-item-double { width: 40%; }
    .grid.lg-grid-4col li { width: 25%; }
    .grid.lg-grid-4col li.grid-item-double { width: 50%; }
    .grid.lg-grid-3col li { width: 33.33%; }
    .grid.lg-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.lg-grid-2col li { width: 50%; }
    .grid.lg-grid-2col li.grid-item-double { width: 100%; }
    .grid.lg-grid-1col li { width: 100%; }
    .grid.lg-grid-1col li.grid-item-double { width: 100%; }

    /* aside style */
    .right-sidebar {padding-right:45px;}
    .left-sidebar {padding-left:45px;}
}

@media (max-width: 1150px) {
    /* sidebar navigation */
    .sidebar-wrapper .container {width: 750px;}
}

@media (max-width: 1050px) {
    /* sidebar navigation */
    .sidebar-wrapper .container {width: 100%; max-width: 100%;}
    .parallax {background-attachment: inherit !important }
    .feature-box.feature-box-17 .box .content {top: 50px;}

    .sidebar-nav .logo-holder { min-height: 60px; }
    nav.navbar.bootsnav.sidebar-nav li.dropdown ul.dropdown-menu { padding-top: 112px; }
    .nav.navbar-left-sidebar li a { padding: 17px 0; }
    .sidebar-part2 .sidebar-middle { padding: 90px 0 90px; }
    .sidebar-part2 .sidebar-middle {padding: 110px 0;}
}

@media (max-width: 1024px) and (min-width: 992px) {
    .sidebar-part2 .sidebar-middle, .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu { padding: 90px 0 220px; }
    header .sidebar-part2 nav.navbar.bootsnav ul > li > a { font-size: 30px; line-height: 37px; }
    .sidebar-part2 ul > li { padding: 7px 20px 7px 0; }
    .extra-small-screen {height: 250px;}
}

@media (max-width: 991px) {
    /* typography */
    h1, h2, h3, h4, h5, h6 {margin:0 0 18px; padding:0; letter-spacing: 0; font-weight: 400;}
    h1 {font-size:57px; line-height: 58px;}
    h2 {font-size:43px; line-height:48px}
    h3 {font-size:36px; line-height:41px}
    h4 {font-size:30px; line-height:35px}
    h5 {font-size:24px; line-height:30px}
    h6 {font-size:22px; line-height:26px}
    p { margin: 0 0 20px; }

    .title-large {font-size: 70px; line-height:65px}
    .title-extra-large {font-size: 100px; line-height:95px}
    .percent {line-height: 125px;}

    /* custom */
    /*section {padding: 75px 0;}*/
    section.big-section {padding:120px 0;}
    section.extra-big-section {padding: 150px 0;}
    section.half-section {padding:40px 0;}

    /* header style */
    header * { transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; -ms-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -ms-transition-duration: 0s; -o-transition-duration: 0s; }
    header nav .container { padding-left: 0; padding-right: 0;}
    .dropdown-menu { box-shadow: none; border: none; border-top: 1px solid rgba(255, 255, 255, 0.06);}
    .navbar-collapse {left: 0; padding:0; position: absolute; top: 100%; width: 100%; backface-visibility: hidden;}
    nav.navbar.bootsnav ul.nav > li.dropdown > ul.dropdown-menu, nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu.mega-menu { padding: 10px 40px 0 !important; margin: 0; float: left; top: 0 !important; }
    nav.navbar.bootsnav .navbar-nav { margin: 0; padding: 0; background-color: rgba(23, 23, 23, 0.95); padding-right: 1px; }
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li {border-bottom: none; margin-bottom: 10px}
    nav.navbar.bootsnav li.dropdown.open .mega-menu-full {padding-top: 10px;}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child {margin-bottom: 0}
    nav.navbar.bootsnav .navbar-nav li, nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header { display: block; clear: both; border-bottom: 1px solid rgba(255, 255, 255, 0.06); border-top: 0; }
    .navbar-nav li > a, nav.navbar.navbar-default ul.nav > li > a, nav.navbar-brand-top.navbar.navbar-default ul.nav > li > a, header .navbar-nav li > a, header nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a, header nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a , nav.navbar.bootsnav li.dropdown ul.mega-menu-full li > a,header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a, header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a, nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header{ margin: 0; padding: 12px 40px 11px; color: #939393; display: block; line-height: normal;}
    .navbar-nav li > a, nav.navbar.navbar-default ul.nav > li > a, header .navbar-nav li > a, header nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a,header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a, header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a, header.header-appear nav.bootsnav.header-light .navbar-nav > li > a { color: #fff; }
    nav.navbar.bootsnav ul.nav > li.dropdown > ul.dropdown-menu  li a, nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu.mega-menu li a, nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header { padding-left: 0; padding-right: 0;}
    .simple-dropdown.open > ul > li { border: 0 !important}
    nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header { color: #fff;}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a,nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header {padding: 0; margin: 0;}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li > ul { margin-top: 0;}
    nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu  > li > a {color: #939393; border-bottom: 1px solid rgba(255, 255, 255, 0.1);}
    nav.navbar.bootsnav ul.nav li.dropdown.simple-dropdown ul.dropdown-menu > li.active > a{background-color: transparent; color: #fff;}
    nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu  > li:hover > a {color: #fff;}
    nav.navbar.bootsnav li.dropdown ul.mega-menu-full li ul {margin-top: 0}
    .navbar-nav > li.simple-dropdown ul.dropdown-menu {top: 0; min-width: 0;}
    nav.navbar.bootsnav ul.nav li.dropdown.simple-dropdown > .dropdown-menu {background-color: #232323;}
    nav.navbar.bootsnav .navbar-toggler { top: 3px;}
    .navbar-nav > li.dropdown > .fas { display: block; position: absolute; right: 25px; top: 0; color: #fff; font-size: 16px; cursor: pointer; padding: 12px 15px 12px}
    nav.navbar.bootsnav .navbar-nav > li.dropdown.open > ul, nav.navbar.bootsnav .navbar-nav > li.dropdown.on > ul { display: block !important; opacity: 1 !important}
    nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a{ border: 0; padding: 10px 0}
    nav.navbar.bootsnav ul.nav li.dropdown.simple-dropdown ul.dropdown-menu > li > a{padding: 7px 0; left: 0}
    .dropdown.simple-dropdown.open .dropdown-menu .dropdown .dropdown-menu {display: block !important; opacity: 1 !important}
    nav.navbar.bootsnav ul.nav li.dropdown.simple-dropdown.open ul.dropdown-menu li > a.dropdown-toggle {border-bottom: 1px solid rgba(255, 255, 255, 0.06); color: #fff;}
    nav.navbar.bootsnav ul.nav .simple-dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu {padding-left: 0 !important}
    nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul, nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul > li.dropdown > ul {left : 0;}

    .simple-dropdown .dropdown-menu > li > a.dropdown-toggle .fas {display:none}
    nav .accordion-menu {padding: 26px 0 26px 0;}
    .sticky nav .accordion-menu {padding: 26px 0 26px 0;}
    nav.navbar.bootsnav.menu-center ul.nav.navbar-center {width:100%;}
    .center-logo {left: 0; position: relative; max-width:100%; margin: 0; top: 0; transform:  none; padding: 26px 15px 26px 0;}
    .navbar-nav li {position: relative}
    nav.navbar.bootsnav li.dropdown ul.mega-menu-full > li { padding: 0; border: 0; }
    nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li { padding-left: 0; padding-right: 0; }
    nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown { margin-bottom: 10px}
    nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul:before {display: none;}
    nav.navbar.bootsnav ul.navbar-nav.navbar-left > li:last-child > ul.dropdown-menu { border: 0;}
    header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a:hover, header.header-appear nav.bootsnav.header-light .navbar-nav > li > a:hover, header.header-appear nav.header-light-transparent .header-social-icon a:hover, header.header-appear nav.header-light .header-social-icon a:hover, header.header-appear nav.header-light.white-link .header-social-icon a:hover, header.header-appear nav.header-light-transparent.white-link .header-social-icon a:hover, header.header-appear nav.header-light-transparent.white-link .heder-menu-button a:hover, header.header-appear nav.header-light-transparent.white-link .header-searchbar a:hover, header nav.navbar .navbar-nav > li > a:hover, nav.navbar.bootsnav ul.nav > li > a:hover,header.sticky nav.navbar.white-link .navbar-nav > li.active > a, header.sticky nav.navbar.bootsnav.white-link ul.nav > li.active > a {color: rgba(255,255,255,0.6)}
    header nav.navbar.white-link .navbar-nav > li > a:hover, nav.navbar.bootsnav.white-link ul.nav > li > a:hover, header nav.navbar.white-link .header-social-icon a:hover, nav.navbar.bootsnav.white-link .header-social-icon a:hover, header nav.navbar.white-link .header-searchbar a:hover, nav.navbar.bootsnav.white-link .header-searchbar a:hover, header nav.navbar.bootsnav ul.nav.white-link > li > a:hover, header.header-appear nav.header-dark-transparent .navbar-nav > li > a:hover, header.header-appear nav.header-dark .navbar-nav > li > a:hover, header.sticky nav.header-dark .navbar-nav > li > a:hover, header.sticky nav.header-dark-transparent .navbar-nav > li > a:hover, header.header-appear nav.header-dark-transparent .search-button:hover, header.header-appear nav.header-dark .search-button:hover, header.sticky nav.header-dark .search-button:hover, header.sticky nav.header-dark-transparent .search-button:hover { color: rgba(255,255,255,0.6)}
    header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a:hover, header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-social-icon a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover {color: rgba(255,255,255,0.6)}
    header.sticky nav.navbar.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a:hover, header.sticky nav.navbar.white-link .navbar-nav > li > a.active, header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a.active { color: rgba(255,255,255,0.6);}
    header.sticky nav.navbar.header-dark.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li > a:hover, header.sticky nav.header-dark .header-social-icon a:hover { color: rgba(255,255,255,0.6);}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header { width: 100%; padding-top: 8px; padding-bottom: 8px; border-bottom: 1px solid rgba(255, 255, 255, 0.06);}
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child img {display: none }
    nav.navbar.navbar-default ul li.dropdown .dropdown-menu li > a:hover { left: 0;}
    .dropdown.open > div { display: block !important; opacity: 1 !important}
    header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a { color: #fff;}
    header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a:hover, header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li.active > a { color: #939393;}
    nav.menu-logo-center .accordion-menu, header nav.menu-logo-center .accordion-menu {padding: 26px 0 26px 15px;}
    .sticky nav.menu-logo-center .accordion-menu, header.sticky nav.menu-logo-center .accordion-menu {padding: 26px 0 26px 15px;}
    nav.navbar.sidebar-nav.bootsnav .navbar-left-sidebar li a:hover, nav.navbar.sidebar-nav.bootsnav .navbar-left-sidebar li.active > a {color: #000 !important;}
    header .sidebar-part2 nav.navbar.bootsnav ul > li > a:hover, header .sidebar-part2 nav.navbar.bootsnav ul > li.active > a { color: rgba(0, 0, 0, 0.6) !important; }
    header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active > a, header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active ul li.active > a { color: var(--base-color) !important; }
    header .sidebar-part2 nav.navbar.bootsnav ul > li > a > .fas { top: -4px; }
    nav.navbar .container-fluid { position: initial;}
    .header-with-topbar .navbar-top {top: 34px}
    #search-header {width: 75%}
    header nav.nav-box-width.navbar-default, header nav.nav-box-width { padding: 0 25px; }
    .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl, .top-header-area>.container-lg, .top-header-area>.container-md, .top-header-area>.container-sm, .top-header-area>.container-xl, .top-header-area>.container-xxl { padding-left: 40px; padding-right: 40px; }

    /* sidebar nav style 1 */
    .sidebar-wrapper {padding-left: 0;}
    nav.navbar.sidebar-nav {transition-duration: 0.3s; -webkit-transition-duration: 0.3s; -moz-transition-duration: 0.3s; -ms-transition-duration: 0.3s; -o-transition-duration: 0.3s; transition-property: display; -webkit-transition-property: display; -moz-transition-property: display; -ms-transition-property: display; -o-transition-property: display; left:-280px; width: 280px; z-index: 10005; padding: 60px 15px 15px; display:inline-block; }
    nav.navbar.sidebar-nav.sidebar-nav-style-1 .sidenav-header {position: fixed; top: 0; left: 0; background: #fff; z-index: 1; }
    nav.navbar.sidebar-nav.sidemenu-open {left:0;}
    nav.navbar.sidebar-nav .navbar-toggler .icon-bar {background: #232323;}
    nav.navbar.bootsnav.sidebar-nav .navbar-collapse.collapse, .sidebar-part2 nav.navbar.bootsnav .navbar-collapse.collapse {display:block !important; max-height: 100%; position: relative; top: 0;}
    nav.navbar.bootsnav.sidebar-nav .mobile-scroll {display: block; max-height: 80%; overflow-y: auto; position: absolute}
    .sidebar-nav .logo-holder, .sidebar-nav .footer-holder {padding: 0; text-align: left; display: inline-block;}
    .sidebar-nav .logo-holder {min-height: 0; padding: 15px 0}
    .sidebar-nav.sidemenu-open .footer-holder {width: 100%; padding: 0; margin-right: 0;}
    .sidebar-nav .footer-holder {margin-right: 15px;}
    .sidebar-nav .footer-holder .navbar-form {margin: 0 auto;}
    nav.navbar.bootsnav.sidebar-nav .navbar-toggler { margin-bottom: 0; position: absolute; top: auto; vertical-align: middle; height: 100%; right: 15px;}
    nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar .dropdown li:first-child {margin-top: 0}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown ul.dropdown-menu  li {opacity: 1; visibility: visible}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown ul.dropdown-menu  > li > a {color: #232323; margin: 0;}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown ul.dropdown-menu  > li.active > ul > li.active > a {color: #ff214f;}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown ul.dropdown-menu  > li > a .fas{display: none}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown.open ul.dropdown-menu  > li > a {border-bottom: 1px solid #ededed !important}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown.open ul.dropdown-menu  > li > ul.third-level > li > a {border-bottom: 0 !important; font-size: 11px; padding: 6px 0 0 0;}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown.open ul.dropdown-menu  > li > ul.third-level > li:first-child > a {padding-top: 10px;}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown.open ul.dropdown-menu  > li:last-child > ul.third-level > li:last-child > a {margin-bottom: 15px}
    nav.navbar.bootsnav.sidebar-nav ul.nav li.dropdown.open ul.dropdown-menu  li {margin-bottom: 5px;}
    .sidebar-nav-style-1 .nav.navbar-left-sidebar li {padding: 0;}
    .sidemenu-open .mobile-toggle span:first-child{transform: rotate(45deg) translate(7px); -webkit-transform: rotate(45deg) translate(7px); -moz-transform: rotate(45deg) translate(7px); -o-transform: rotate(45deg) translate(7px); -ms-transform: rotate(45deg) translate(7px); }
    .sidemenu-open .mobile-toggle span:nth-child(2){transform: scale(0); -webkit-transform: scale(0); -moz-transform: scale(0); -o-transform: scale(0); -ms-transform: scale(0); }
    .sidemenu-open .mobile-toggle span:last-child{transform: rotate(-45deg) translate(7px); -webkit-transform: rotate(-45deg) translate(7px); -moz-transform: rotate(-45deg) translate(7px); -o-transform: rotate(-45deg) translate(7px); -ms-transform: rotate(-45deg) translate(7px); }
    .sidebar-part2 nav.navbar.bootsnav li.dropdown.open > a > i {transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -o-transform: rotate(90deg); -ms-transform: rotate(90deg); }
    nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li.dropdown.open > a > i {transform: translateY(-50%) rotate(90deg); -webkit-transform: translateY(-50%) rotate(90deg); -moz-transform: translateY(-50%) rotate(90deg); -o-transform: translateY(-50%) rotate(90deg); -ms-transform: translateY(-50%) rotate(90deg);}
    .sidebar-part2 nav.navbar.bootsnav ul.nav > li.dropdown > ul.dropdown-menu { padding: 10px 15px 0 !important; }

    /* mega menu */
    nav.navbar.bootsnav li.dropdown .menu-back-div > ul {width: 100%; }
    nav.navbar.bootsnav li.dropdown .mega-menu-full {padding: 0 40px; width: 100%; left: 0; position: relative; top: 0; }
    nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a {padding: 8px 0; margin: 0;}
    header nav.navbar .navbar-nav > li.active > a, nav.navbar.bootsnav ul.nav > li.active > a, .dropdown-menu,  header.header-appear nav.bootsnav.header-light .navbar-nav > li.active > a, header nav.navbar .navbar-nav > li.active > a, nav.navbar.bootsnav ul.nav > li.active > a  {color:rgba(255,255,255,0.6) !important;}
    header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li.dropdown.on > a, header.header-appear nav.bootsnav.header-light .navbar-nav > li.dropdown.on > a, header nav.navbar .navbar-nav > li.dropdown.on > a, nav.navbar.bootsnav ul.nav > li.dropdown.on > a  {color:#fff}

    /* sidebar nav style 2 */
    .left-nav-sidebar {padding-left: 0;}
    .left-nav-sidebar .fullscreenbanner { padding-left: 0 !important;}
    .sidebar-part2 .sidebar-middle {padding: 30px 0; display: block;}
    .sidebar-part2 .sidebar-middle-menu {display: block; overflow-y: auto; max-height: calc(100% - 10px);}
    .sidebar-part1 {width: 50px}
    .sidebar-part2 nav.navbar.bootsnav .navbar-nav {background: transparent; padding: 0}
    .sidebar-part2 ul > li {width: 100%; padding: 5px 30px 5px 0;}
    header .sidebar-part2 nav.navbar.bootsnav ul > li > a > .fas {right: -20px; top: 0px; font-size: 22px;}
    .bottom-menu-icon a,.nav-icon span {width: 20px}
    .bottom-menu-icon a { margin-top: 2px;}
    .bottom-menu-icon {width:50px; padding: 13px 10px 11px;}
    .sidebar-part2:before {bottom: 55px; display: none;}
    .sidebar-part2 { background-color: #fff; border-right: 0 solid #dfdfdf; height: calc(100% - 50px); left: -300px; padding: 0 20px; overflow: auto; position: fixed; top: 50px; text-align: center; width:300px; z-index: -1; transition: ease-in-out 0.5s}
    .sidebar-part2 .right-bg { display: none; position: absolute; right: 0; top: 0; transform: translateY(0); -moz-transform: translateY(0px); -webkit-transform: translateY(0px); -o-transform: translateY(0px); left: 0; -ms-transform: rotate(90deg); -webkit-transform: rotate(90deg); transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); }
    .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu:before {display: none;}
    .sidebar-part2 nav.navbar.bootsnav li.dropdown.open ul.dropdown-menu {display: block !important; opacity: 1 !important}
    .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu,.sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu .third-level { top: 0; transform: translateY(0); -moz-transform: translateY(0px); -webkit-transform: translateY(0px); -o-transform: translateY(0px); -ms-transform: translateY(0px); left: 0; background-color: transparent !important; padding-left: 10px !important;}
    header .sidebar-part2 nav.navbar.bootsnav ul li ul li {padding: 0;}
    .sidebar-part2 .dropdown ul.second-level, .sidebar-part2 .dropdown ul.third-level {border-left: none !important;}
    .sidebar-part2 nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu  > li > a {color: #232323; padding: 0; margin-bottom: 2px;}
    .sidebar-part2 nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu  > li > a > .fas{display: none}
    header .sidebar-part2 nav.navbar.bootsnav ul > li > a {font-size: 20px; line-height: 24px; border: 0}
    .sidebar-part2 nav.navbar.bootsnav li.dropdown.open ul.dropdown-menu .third-level {display: inherit; }
    .left-nav-sidebar .container {width: 700px;}
    .sidebar-part2 nav.navbar { text-align: left;}
    header .sidebar-part2 nav.navbar.bootsnav ul li.dropdown .dropdown-menu.second-level > li > a {font-weight: 500; margin-top: 8px; margin-bottom: 0}
    header .sidebar-part2 nav.navbar.bootsnav ul li.dropdown .dropdown-menu.second-level > li:last-child {margin-bottom: 8px}
    header .sidebar-part2 nav.navbar.bootsnav ul li.dropdown .dropdown-menu.second-level > li > .dropdown-menu.third-level > li:first-child > a {margin-top: 8px}

    /* sidebar nav style 1-2 */
    nav.navbar.bootsnav.sidebar-nav .navbar-nav, .sidebar-part2 nav.navbar.bootsnav .navbar-nav {background-color: transparent; padding:0 0px 0 0}
    nav.navbar.bootsnav.sidebar-nav .navbar-nav {padding:0; margin-right: 50px}
    nav.navbar.bootsnav.sidebar-nav.sidemenu-open .navbar-nav {margin-right: 0}
    nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar .dropdown .second-level, .sidebar-part2 nav.navbar.bootsnav .nav.navbar-left-sidebar .dropdown .second-level {display: none !important}
    nav.navbar.bootsnav.sidebar-nav .navbar-left-sidebar > li > a, .sidebar-part2 nav.navbar.bootsnav .navbar-left-sidebar > li > a {margin: 0; padding: 15px 15px 15px 0}
    nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li a, .sidebar-part2 nav.navbar.bootsnav .nav.navbar-left-sidebar li a {border-bottom: 0 !important;}
    nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar .dropdown.open .second-level,nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar .dropdown.open .second-level .dropdown .third-level, .sidebar-part2 nav.navbar.bootsnav .nav.navbar-left-sidebar .dropdown.open .second-level, .sidebar-part2 nav.navbar.bootsnav .nav.navbar-left-sidebar .dropdown.open .second-level .dropdown .third-level {display:block !important; left: 0; width: 100%; height: auto; visibility: visible; opacity: 1 !important; background: transparent; padding: 0 0 0 8px !important}
    header .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover {border: 0; color: #ff214f}
    .sidebar-part1 { position: inherit; width: 50px; float: left; }
    .sidebar-part3 { position: absolute; right: 0; bottom: inherit; top: 0; }
    .left-nav { height: 50px; width: 100%;}

    /* new-menu page */
    .menu-new a:after {margin-top: 0;}
    .nav.navbar-left-sidebar .menu-new a:after { margin-top: 3px; }

    /* full width light transparent navigation */
    .close-button-menu { top: 15px; right: 15px; }

    /* top logo */
    .navbar.navbar-brand-top.bootsnav .navbar-toggler { top: 5px;}
    .navbar-brand-top .brand-top-menu-right { margin-left: 10px;}
    .navbar-brand-top .navbar-brand { text-align: left;}
    .navbar-brand-top .navbar-nav { text-align: left;}

    /* brand center */
    .brand-center .accordion-menu {float: right;}
    .brand-center .center-logo {max-width: 100%;}

    /* image position*/
    .md-background-image-left { background-position: left;}
    .md-background-image-right { background-position: right;}
    .md-background-image-center{ background-position: center;}

    /* margin */
    .md-margin-one-all {margin:1%;}
    .md-margin-one-half-all {margin:1.5%;}
    .md-margin-two-all {margin:2%;}
    .md-margin-two-half-all {margin:2.5%;}
    .md-margin-three-all {margin:3%;}
    .md-margin-three-half-all {margin:3.5%;}
    .md-margin-four-all {margin:4%;}
    .md-margin-four-half-all {margin:4.5%;}
    .md-margin-five-all {margin:5%;}
    .md-margin-five-half-all {margin:5.5%;}
    .md-margin-six-all {margin:6%;}
    .md-margin-six-half-all {margin:6.5%;}
    .md-margin-seven-all {margin:7%;}
    .md-margin-seven-half-all {margin:7.5%;}
    .md-margin-eight-all {margin:8%;}
    .md-margin-eight-half-all {margin:8.5%;}
    .md-margin-nine-all {margin:9%;}
    .md-margin-nine-half-all {margin:9.5%;}
    .md-margin-ten-all {margin:10%;}
    .md-margin-ten-half-all {margin:10.5%;}
    .md-margin-eleven-all {margin:11%;}
    .md-margin-eleven-half-all {margin:11.5%;}
    .md-margin-twelve-all {margin:12%;}
    .md-margin-twelve-half-all {margin:12.5%;}
    .md-margin-thirteen-all {margin:13%;}
    .md-margin-thirteen-half-all {margin:13.5%;}
    .md-margin-fourteen-all {margin:14%;}
    .md-margin-fourteen-half-all {margin:14.5%;}
    .md-margin-fifteen-all {margin:15%;}
    .md-margin-fifteen-half-all {margin:15.5%;}
    .md-margin-sixteen-all {margin:16%;}
    .md-margin-sixteen-half-all {margin:16.5%;}
    .md-margin-seventeen-all {margin:17%;}
    .md-margin-seventeen-half-all {margin:17.5%;}
    .md-margin-eighteen-all {margin:18%;}
    .md-margin-eighteen-half-all {margin:18.5%;}
    .md-margin-nineteen-all {margin:19%;}
    .md-margin-nineteen-half-all {margin:19.5%;}
    .md-margin-twenty-all {margin:20%;}
    .md-margin-twenty-half-all {margin:20.5%;}
    .md-margin-twenty-one-all {margin:21%;}
    .md-margin-twenty-one-half-all {margin:21.5%;}
    .md-margin-twenty-two-all {margin:22%;}
    .md-margin-twenty-two-half-all {margin:22.5%;}
    .md-margin-twenty-three-all {margin:23%;}
    .md-margin-twenty-three-half-all {margin:23.5%;}
    .md-margin-twenty-four-all {margin:24%;}
    .md-margin-twenty-four-half-all {margin:24.5%;}
    .md-margin-twenty-five-all {margin:25%;}
    .md-margin-5px-all {margin:5px;}
    .md-margin-10px-all {margin:10px;}
    .md-margin-15px-all {margin:15px;}
    .md-margin-20px-all {margin:20px;}
    .md-margin-25px-all {margin:25px;}
    .md-margin-30px-all {margin:30px;}
    .md-margin-35px-all {margin:35px;}
    .md-margin-40px-all {margin:40px;}
    .md-margin-45px-all {margin:45px;}
    .md-margin-50px-all {margin:50px;}
    .md-margin-55px-all {margin:55px;}
    .md-margin-60px-all {margin:60px;}
    .md-margin-65px-all {margin:65px;}
    .md-margin-70px-all {margin:70px;}
    .md-margin-75px-all {margin:75px;}
    .md-margin-80px-all {margin:80px;}
    .md-margin-85px-all {margin:85px;}
    .md-margin-90px-all {margin:90px;}
    .md-margin-95px-all {margin:95px;}
    .md-margin-100px-all {margin:100px;}

    .md-no-margin {margin:0 !important}
    .md-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important}
    .md-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important}
    .md-no-margin-top {margin-top:0 !important}
    .md-no-margin-bottom {margin-bottom:0 !important}
    .md-no-margin-left {margin-left:0 !important}
    .md-no-margin-right {margin-right:0 !important}
    .md-margin-lr-auto {margin-left:auto !important; margin-right:auto !important}
    .md-margin-auto {margin: 0 auto !important;}

    /* margin top */
    .md-margin-one-top {margin-top:1%;}
    .md-margin-one-half-top {margin-top:1.5%;}
    .md-margin-two-top {margin-top:2%;}
    .md-margin-two-half-top {margin-top:2.5%;}
    .md-margin-three-top {margin-top:3%;}
    .md-margin-three-half-top {margin-top:3.5%;}
    .md-margin-four-top {margin-top:4%;}
    .md-margin-four-half-top {margin-top:4.5%;}
    .md-margin-five-top {margin-top:5%;}
    .md-margin-five-half-top {margin-top:5.5%;}
    .md-margin-six-top {margin-top:6%;}
    .md-margin-six-half-top {margin-top:6.5%;}
    .md-margin-seven-top {margin-top:7%;}
    .md-margin-seven-half-top {margin-top:7.5%;}
    .md-margin-eight-top {margin-top:8%;}
    .md-margin-eight-half-top {margin-top:8.5%;}
    .md-margin-nine-top {margin-top:9%;}
    .md-margin-nine-half-top {margin-top:9.5%;}
    .md-margin-ten-top {margin-top:10%;}
    .md-margin-ten-half-top {margin-top:10.5%;}
    .md-margin-eleven-top {margin-top:11%;}
    .md-margin-eleven-half-top {margin-top:11.5%;}
    .md-margin-twelve-top {margin-top:12%;}
    .md-margin-twelve-half-top {margin-top:12.5%;}
    .md-margin-thirteen-top {margin-top:13%;}
    .md-margin-thirteen-half-top {margin-top:13.5%;}
    .md-margin-fourteen-top {margin-top:14%;}
    .md-margin-fourteen-half-top {margin-top:14.5%;}
    .md-margin-fifteen-top {margin-top:15%;}
    .md-margin-fifteen-half-top {margin-top:15.5%;}
    .md-margin-sixteen-top {margin-top:16%;}
    .md-margin-sixteen-half-top {margin-top:16.5%;}
    .md-margin-seventeen-top {margin-top:17%;}
    .md-margin-seventeen-half-top {margin-top:17.5%;}
    .md-margin-eighteen-top {margin-top:18%;}
    .md-margin-eighteen-half-top {margin-top:18.5%;}
    .md-margin-nineteen-top {margin-top:19%;}
    .md-margin-nineteen-half-top {margin-top:19.5%;}
    .md-margin-twenty-top {margin-top:20%;}
    .md-margin-twenty-half-top {margin-top:20.5%;}
    .md-margin-twenty-one-top {margin-top:21%;}
    .md-margin-twenty-one-half-top {margin-top:21.5%;}
    .md-margin-twenty-two-top {margin-top:22%;}
    .md-margin-twenty-two-half-top {margin-top:22.5%;}
    .md-margin-twenty-three-top {margin-top:23%;}
    .md-margin-twenty-three-half-top {margin-top:23.5%;}
    .md-margin-twenty-four-top {margin-top:24%;}
    .md-margin-twenty-four-half-top {margin-top:24.5%;}
    .md-margin-twenty-five-top {margin-top:25%;}
    .md-margin-5px-top {margin-top:5px;}
    .md-margin-10px-top {margin-top:10px;}
    .md-margin-15px-top {margin-top:15px;}
    .md-margin-20px-top {margin-top:20px;}
    .md-margin-25px-top {margin-top:25px;}
    .md-margin-30px-top {margin-top:30px;}
    .md-margin-35px-top {margin-top:35px;}
    .md-margin-40px-top {margin-top:40px;}
    .md-margin-45px-top {margin-top:45px;}
    .md-margin-50px-top {margin-top:50px;}
    .md-margin-55px-top {margin-top:55px;}
    .md-margin-60px-top {margin-top:60px;}
    .md-margin-65px-top {margin-top:65px;}
    .md-margin-70px-top {margin-top:70px;}
    .md-margin-75px-top {margin-top:75px;}
    .md-margin-80px-top {margin-top:80px;}
    .md-margin-85px-top {margin-top:85px;}
    .md-margin-90px-top {margin-top:90px;}
    .md-margin-95px-top {margin-top:95px;}
    .md-margin-100px-top {margin-top:100px;}

    /* margin bottom */
    .md-margin-one-bottom {margin-bottom:1%;}
    .md-margin-one-half-bottom {margin-bottom:1.5%;}
    .md-margin-two-bottom {margin-bottom:2%;}
    .md-margin-two-half-bottom {margin-bottom:2.5%;}
    .md-margin-three-bottom {margin-bottom:3%;}
    .md-margin-three-half-bottom {margin-bottom:3.5%;}
    .md-margin-four-bottom {margin-bottom:4%;}
    .md-margin-four-half-bottom {margin-bottom:4.5%;}
    .md-margin-five-bottom {margin-bottom:5%;}
    .md-margin-five-half-bottom {margin-bottom:5.5%;}
    .md-margin-six-bottom {margin-bottom:6%;}
    .md-margin-six-half-bottom {margin-bottom:6.5%;}
    .md-margin-seven-bottom {margin-bottom:7%;}
    .md-margin-seven-half-bottom {margin-bottom:7.5%;}
    .md-margin-eight-bottom {margin-bottom:8%;}
    .md-margin-eight-half-bottom {margin-bottom:8.5%;}
    .md-margin-nine-bottom {margin-bottom:9%;}
    .md-margin-nine-half-bottom {margin-bottom:9.5%;}
    .md-margin-ten-bottom {margin-bottom:10%;}
    .md-margin-ten-half-bottom {margin-bottom:10.5%;}
    .md-margin-eleven-bottom {margin-bottom:11%;}
    .md-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .md-margin-twelve-bottom {margin-bottom:12%;}
    .md-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .md-margin-thirteen-bottom {margin-bottom:13%;}
    .md-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .md-margin-fourteen-bottom {margin-bottom:14%;}
    .md-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .md-margin-fifteen-bottom {margin-bottom:15%;}
    .md-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .md-margin-sixteen-bottom {margin-bottom:16%;}
    .md-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .md-margin-seventeen-bottom {margin-bottom:17%;}
    .md-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .md-margin-eighteen-bottom {margin-bottom:18%;}
    .md-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .md-margin-nineteen-bottom {margin-bottom:19%;}
    .md-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .md-margin-twenty-bottom {margin-bottom:20%;}
    .md-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .md-margin-twenty-one-bottom {margin-bottom:21%;}
    .md-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .md-margin-twenty-two-bottom {margin-bottom:22%;}
    .md-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .md-margin-twenty-three-bottom {margin-bottom:23%;}
    .md-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .md-margin-twenty-four-bottom {margin-bottom:24%;}
    .md-margin-twenty-four-half-bottom {margin-bottom:24.5%;}
    .md-margin-twenty-five-bottom {margin-bottom:25%;}
    .md-margin-5px-bottom {margin-bottom:5px;}
    .md-margin-10px-bottom {margin-bottom:10px;}
    .md-margin-15px-bottom {margin-bottom:15px;}
    .md-margin-20px-bottom {margin-bottom:20px;}
    .md-margin-25px-bottom {margin-bottom:25px;}
    .md-margin-30px-bottom {margin-bottom:30px;}
    .md-margin-35px-bottom {margin-bottom:35px;}
    .md-margin-40px-bottom {margin-bottom:40px;}
    .md-margin-45px-bottom {margin-bottom:45px;}
    .md-margin-50px-bottom {margin-bottom:50px;}
    .md-margin-55px-bottom {margin-bottom:55px;}
    .md-margin-60px-bottom {margin-bottom:60px;}
    .md-margin-65px-bottom {margin-bottom:65px;}
    .md-margin-70px-bottom {margin-bottom:70px;}
    .md-margin-75px-bottom {margin-bottom:75px;}
    .md-margin-80px-bottom {margin-bottom:80px;}
    .md-margin-85px-bottom {margin-bottom:85px;}
    .md-margin-90px-bottom {margin-bottom:90px;}
    .md-margin-95px-bottom {margin-bottom:95px;}
    .md-margin-100px-bottom {margin-bottom:100px;}

    /* margin right */
    .md-margin-one-right {margin-right:1%;}
    .md-margin-one-half-right {margin-right:1.5%;}
    .md-margin-two-right {margin-right:2%;}
    .md-margin-two-half-right {margin-right:2.5%;}
    .md-margin-three-right {margin-right:3%;}
    .md-margin-three-half-right {margin-right:3.5%;}
    .md-margin-four-right {margin-right:4%;}
    .md-margin-four-half-right {margin-right:4.5%;}
    .md-margin-five-right {margin-right:5%;}
    .md-margin-five-half-right {margin-right:5.5%;}
    .md-margin-six-right {margin-right:6%;}
    .md-margin-six-half-right {margin-right:6.5%;}
    .md-margin-seven-right {margin-right:7%;}
    .md-margin-seven-half-right {margin-right:7.5%;}
    .md-margin-eight-right {margin-right:8%;}
    .md-margin-eight-half-right {margin-right:8.5%;}
    .md-margin-nine-right {margin-right:9%;}
    .md-margin-nine-half-right {margin-right:9.5%;}
    .md-margin-ten-right {margin-right:10%;}
    .md-margin-ten-half-right {margin-right:10.5%;}
    .md-margin-eleven-right {margin-right:11%;}
    .md-margin-eleven-half-right {margin-right:11.5%;}
    .md-margin-twelve-right {margin-right:12%;}
    .md-margin-twelve-half-right {margin-right:12.5%;}
    .md-margin-thirteen-right {margin-right:13%;}
    .md-margin-thirteen-half-right {margin-right:13.5%;}
    .md-margin-fourteen-right {margin-right:14%;}
    .md-margin-fourteen-half-right {margin-right:14.5%;}
    .md-margin-fifteen-right {margin-right:15%;}
    .md-margin-fifteen-half-right {margin-right:15.5%;}
    .md-margin-sixteen-right {margin-right:16%;}
    .md-margin-sixteen-half-right {margin-right:16.5%;}
    .md-margin-seventeen-right {margin-right:17%;}
    .md-margin-seventeen-half-right {margin-right:17.5%;}
    .md-margin-eighteen-right {margin-right:18%;}
    .md-margin-eighteen-half-right {margin-right:18.5%;}
    .md-margin-nineteen-right {margin-right:19%;}
    .md-margin-nineteen-half-right {margin-right:19.5%;}
    .md-margin-twenty-right {margin-right:20%;}
    .md-margin-twenty-half-right {margin-right:20.5%;}
    .md-margin-twenty-one-right {margin-right:21%;}
    .md-margin-twenty-one-half-right {margin-right:21.5%;}
    .md-margin-twenty-two-right {margin-right:22%;}
    .md-margin-twenty-two-half-right {margin-right:22.5%;}
    .md-margin-twenty-three-right {margin-right:23%;}
    .md-margin-twenty-three-half-right {margin-right:23.5%;}
    .md-margin-twenty-four-right {margin-right:24%;}
    .md-margin-twenty-four-half-right {margin-right:24.5%;}
    .md-margin-twenty-five-right {margin-right:25%;}
    .md-margin-10px-right {margin-right:10px;}
    .md-margin-15px-right {margin-right:15px;}
    .md-margin-20px-right {margin-right:20px;}
    .md-margin-25px-right {margin-right:25px;}
    .md-margin-30px-right {margin-right:30px;}
    .md-margin-35px-right {margin-right:35px;}
    .md-margin-40px-right {margin-right:40px;}
    .md-margin-45px-right {margin-right:45px;}
    .md-margin-50px-right {margin-right:50px;}
    .md-margin-55px-right {margin-right:55px;}
    .md-margin-60px-right {margin-right:60px;}
    .md-margin-65px-right {margin-right:65px;}
    .md-margin-70px-right {margin-right:70px;}
    .md-margin-75px-right {margin-right:75px;}
    .md-margin-80px-right {margin-right:80px;}
    .md-margin-85px-right {margin-right:85px;}
    .md-margin-90px-right {margin-right:90px;}
    .md-margin-95px-right {margin-right:95px;}
    .md-margin-100px-right {margin-right:100px;}

    /* margin left */
    .md-margin-one-left {margin-left:1%;}
    .md-margin-one-half-left {margin-left:1.5%;}
    .md-margin-two-left {margin-left:2%;}
    .md-margin-two-half-left {margin-left:2.5%;}
    .md-margin-three-left {margin-left:3%;}
    .md-margin-three-half-left {margin-left:3.5%;}
    .md-margin-four-left {margin-left:4%;}
    .md-margin-four-half-left {margin-left:4.5%;}
    .md-margin-five-left {margin-left:5%;}
    .md-margin-five-half-left {margin-left:5.5%;}
    .md-margin-six-left {margin-left:6%;}
    .md-margin-six-half-left {margin-left:6.5%;}
    .md-margin-seven-left {margin-left:7%;}
    .md-margin-seven-half-left {margin-left:7.5%;}
    .md-margin-eight-left {margin-left:8%;}
    .md-margin-eight-half-left {margin-left:8.5%;}
    .md-margin-nine-left {margin-left:9%;}
    .md-margin-nine-half-left {margin-left:9.5%;}
    .md-margin-ten-left {margin-left:10%;}
    .md-margin-ten-half-left {margin-left:10.5%;}
    .md-margin-eleven-left {margin-left:11%;}
    .md-margin-eleven-half-left {margin-left:11.5%;}
    .md-margin-twelve-left {margin-left:12%;}
    .md-margin-twelve-half-left {margin-left:12.5%;}
    .md-margin-thirteen-left {margin-left:13%;}
    .md-margin-thirteen-half-left {margin-left:13.5%;}
    .md-margin-fourteen-left {margin-left:14%;}
    .md-margin-fourteen-half-left {margin-left:14.5%;}
    .md-margin-fifteen-left {margin-left:15%;}
    .md-margin-fifteen-half-left {margin-left:15.5%;}
    .md-margin-sixteen-left {margin-left:16%;}
    .md-margin-sixteen-half-left {margin-left:16.5%;}
    .md-margin-seventeen-left {margin-left:17%;}
    .md-margin-seventeen-half-left {margin-left:17.5%;}
    .md-margin-eighteen-left {margin-left:18%;}
    .md-margin-eighteen-half-left {margin-left:18.5%;}
    .md-margin-nineteen-left {margin-left:19%;}
    .md-margin-nineteen-half-left {margin-left:19.5%;}
    .md-margin-twenty-left {margin-left:20%;}
    .md-margin-twenty-half-left {margin-left:20.5%;}
    .md-margin-twenty-one-left {margin-left:21%;}
    .md-margin-twenty-one-half-left {margin-left:21.5%;}
    .md-margin-twenty-two-left {margin-left:22%;}
    .md-margin-twenty-two-half-left {margin-left:22.5%;}
    .md-margin-twenty-three-left {margin-left:23%;}
    .md-margin-twenty-three-half-left {margin-left:23.5%;}
    .md-margin-twenty-four-left {margin-left:24%;}
    .md-margin-twenty-four-half-left {margin-left:24.5%;}
    .md-margin-twenty-five-left {margin-left:25%;}
    .md-margin-5px-left {margin-left:5px;}
    .md-margin-10px-left {margin-left:10px;}
    .md-margin-15px-left {margin-left:15px;}
    .md-margin-20px-left {margin-left:20px;}
    .md-margin-25px-left {margin-left:25px;}
    .md-margin-30px-left {margin-left:30px;}
    .md-margin-35px-left {margin-left:35px;}
    .md-margin-40px-left {margin-left:40px;}
    .md-margin-45px-left {margin-left:45px;}
    .md-margin-50px-left {margin-left:50px;}
    .md-margin-55px-left {margin-left:55px;}
    .md-margin-60px-left {margin-left:60px;}
    .md-margin-65px-left {margin-left:65px;}
    .md-margin-70px-left {margin-left:70px;}
    .md-margin-75px-left {margin-left:75px;}
    .md-margin-80px-left {margin-left:80px;}
    .md-margin-85px-left {margin-left:85px;}
    .md-margin-90px-left {margin-left:90px;}
    .md-margin-95px-left {margin-left:95px;}
    .md-margin-100px-left {margin-left:100px;}

    /* margin left right */
    .md-margin-one-lr {margin-left:1%; margin-right:1%;}
    .md-margin-one-lr {margin-left:1.5%; margin-right:1.5%;}
    .md-margin-two-lr {margin-left:2%; margin-right:2%;}
    .md-margin-two-lr {margin-left:2.5%; margin-right:2.5%;}
    .md-margin-three-lr {margin-left:3%; margin-right:3%;}
    .md-margin-three-lr {margin-left:3.5%; margin-right:3.5%;}
    .md-margin-four-lr {margin-left:4%; margin-right:4%;}
    .md-margin-four-lr {margin-left:4.5%; margin-right:4.5%;}
    .md-margin-five-lr {margin-left:5%; margin-right:5%;}
    .md-margin-five-lr {margin-left:5.5%; margin-right:5.5%;}
    .md-margin-six-lr {margin-left:6%; margin-right:6%;}
    .md-margin-six-lr {margin-left:6.5%; margin-right:6.5%;}
    .md-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .md-margin-seven-lr {margin-left:7.5%; margin-right:7.5%;}
    .md-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .md-margin-eight-lr {margin-left:8.5%; margin-right:8.5%;}
    .md-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .md-margin-nine-lr {margin-left:9.5%; margin-right:9.5%;}
    .md-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .md-margin-ten-lr {margin-left:10.5%; margin-right:10.5%;}
    .md-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .md-margin-eleven-lr {margin-left:11.5%; margin-right:11.5%;}
    .md-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .md-margin-twelve-lr {margin-left:12.5%; margin-right:12.5%;}
    .md-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .md-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .md-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .md-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .md-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .md-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .md-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .md-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .md-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .md-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .md-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .md-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .md-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .md-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .md-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .md-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .md-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .md-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .md-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .md-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .md-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .md-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .md-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .md-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .md-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .md-margin-5px-lr {margin-left:5px; margin-right:5px;}
    .md-margin-10px-lr {margin-left:10px; margin-right:10px;}
    .md-margin-15px-lr {margin-left:15px; margin-right:15px;}
    .md-margin-20px-lr {margin-left:20px; margin-right:20px;}
    .md-margin-25px-lr {margin-left:25px; margin-right:25px;}
    .md-margin-30px-lr {margin-left:30px; margin-right:30px;}
    .md-margin-35px-lr {margin-left:35px; margin-right:35px;}
    .md-margin-40px-lr {margin-left:40px; margin-right:40px;}
    .md-margin-45px-lr {margin-left:45px; margin-right:45px;}
    .md-margin-50px-lr {margin-left:50px; margin-right:50px;}
    .md-margin-55px-lr {margin-left:55px; margin-right:55px;}
    .md-margin-60px-lr {margin-left:60px; margin-right:60px;}
    .md-margin-65px-lr {margin-left:65px; margin-right:65px;}
    .md-margin-70px-lr {margin-left:70px; margin-right:70px;}
    .md-margin-75px-lr {margin-left:75px; margin-right:75px;}
    .md-margin-80px-lr {margin-left:80px; margin-right:80px;}
    .md-margin-85px-lr {margin-left:85px; margin-right:85px;}
    .md-margin-90px-lr {margin-left:90px; margin-right:90px;}
    .md-margin-95px-lr {margin-left:95px; margin-right:95px;}
    .md-margin-100px-lr {margin-left:100px; margin-right:100px;}

    /* margin top bottom */
    .md-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .md-margin-one-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .md-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .md-margin-two-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .md-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .md-margin-three-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .md-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .md-margin-four-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .md-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .md-margin-five-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .md-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .md-margin-six-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .md-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .md-margin-seven-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .md-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .md-margin-eight-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .md-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .md-margin-nine-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .md-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .md-margin-ten-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .md-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .md-margin-eleven-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .md-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .md-margin-twelve-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .md-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .md-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .md-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .md-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .md-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .md-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .md-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .md-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .md-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .md-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .md-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .md-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .md-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .md-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .md-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .md-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .md-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .md-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .md-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .md-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .md-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .md-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .md-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .md-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .md-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .md-margin-5px-tb {margin-top:5px; margin-bottom:5px;}
    .md-margin-10px-tb {margin-top:10px; margin-bottom:10px;}
    .md-margin-15px-tb {margin-top:15px; margin-bottom:15px;}
    .md-margin-20px-tb {margin-top:20px; margin-bottom:20px;}
    .md-margin-25px-tb {margin-top:25px; margin-bottom:25px;}
    .md-margin-30px-tb {margin-top:30px; margin-bottom:30px;}
    .md-margin-35px-tb {margin-top:35px; margin-bottom:35px;}
    .md-margin-40px-tb {margin-top:40px; margin-bottom:40px;}
    .md-margin-45px-tb {margin-top:45px; margin-bottom:45px;}
    .md-margin-50px-tb {margin-top:50px; margin-bottom:50px;}
    .md-margin-55px-tb {margin-top:55px; margin-bottom:55px;}
    .md-margin-60px-tb {margin-top:60px; margin-bottom:60px;}
    .md-margin-65px-tb {margin-top:65px; margin-bottom:65px;}
    .md-margin-70px-tb {margin-top:70px; margin-bottom:70px;}
    .md-margin-75px-tb {margin-top:75px; margin-bottom:75px;}
    .md-margin-80px-tb {margin-top:80px; margin-bottom:80px;}
    .md-margin-85px-tb {margin-top:85px; margin-bottom:85px;}
    .md-margin-90px-tb {margin-top:90px; margin-bottom:90px;}
    .md-margin-95px-tb {margin-top:95px; margin-bottom:95px;}
    .md-margin-100px-tb {margin-top:100px; margin-bottom:100px;}

    /* header padding top */
    .md-header-padding-top { padding-top: 82px;}
    .md-header-margin-top { margin-top: 80px;}

    /* padding */
    .md-no-padding {padding:0 !important}
    .md-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important}
    .md-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important}
    .md-no-padding-top {padding-top:0 !important}
    .md-no-padding-bottom {padding-bottom:0 !important}
    .md-no-padding-left {padding-left:0 !important}
    .md-no-padding-right {padding-right:0 !important}
    .md-padding-one-all {padding:1%;}
    .md-padding-one-half-all {padding:1.5%;}
    .md-padding-two-all {padding:2%;}
    .md-padding-two-half-all {padding:2.5%;}
    .md-padding-three-all {padding:3%;}
    .md-padding-three-half-all {padding:3.5%;}
    .md-padding-four-all {padding:4%;}
    .md-padding-four-half-all {padding:4.5%;}
    .md-padding-five-all {padding:5%;}
    .md-padding-five-half-all {padding:5.5%;}
    .md-padding-six-all {padding:6%;}
    .md-padding-six-half-all {padding:6.5%;}
    .md-padding-seven-all {padding:7%;}
    .md-padding-seven-half-all {padding:7.5%;}
    .md-padding-eight-all {padding:8%;}
    .md-padding-eight-half-all {padding:8.5%;}
    .md-padding-nine-all {padding:9%;}
    .md-padding-nine-half-all {padding:9.5%;}
    .md-padding-ten-all {padding:10%;}
    .md-padding-ten-half-all {padding:10.5%;}
    .md-padding-eleven-all {padding:11%;}
    .md-padding-eleven-half-all {padding:11.5%;}
    .md-padding-twelve-all {padding:12%;}
    .md-padding-twelve-half-all {padding:12.5%;}
    .md-padding-thirteen-all {padding:13%;}
    .md-padding-thirteen-half-all {padding:13.5%;}
    .md-padding-fourteen-all {padding:14%;}
    .md-padding-fourteen-half-all {padding:14.5%;}
    .md-padding-fifteen-all {padding:15%;}
    .md-padding-fifteen-half-all {padding:15.5%;}
    .md-padding-sixteen-all {padding:16%;}
    .md-padding-sixteen-half-all {padding:16.5%;}
    .md-padding-seventeen-all {padding:17%;}
    .md-padding-seventeen-half-all {padding:17.5%;}
    .md-padding-eighteen-all {padding:18%;}
    .md-padding-eighteen-half-all {padding:18.5%;}
    .md-padding-nineteen-all {padding:19%;}
    .md-padding-nineteen-half-all {padding:19.5%;}
    .md-padding-twenty-all {padding:20%;}
    .md-padding-twenty-half-all {padding:20.5%;}
    .md-padding-twenty-one-all {padding:21%;}
    .md-padding-twenty-one-half-all {padding:21.5%;}
    .md-padding-twenty-two-all {padding:22%;}
    .md-padding-twenty-two-half-all {padding:22.5%;}
    .md-padding-twenty-three-all {padding:23%;}
    .md-padding-twenty-three-half-all {padding:23.5%;}
    .md-padding-twenty-four-all {padding:24%;}
    .md-padding-twenty-four-half-all {padding:24.5%;}
    .md-padding-twenty-five-all {padding:25%;}
    .md-padding-5px-all {padding:5px;}
    .md-padding-10px-all {padding:10px;}
    .md-padding-15px-all {padding:15px;}
    .md-padding-20px-all {padding:20px;}
    .md-padding-25px-all {padding:25px;}
    .md-padding-30px-all {padding:30px;}
    .md-padding-35px-all {padding:35px;}
    .md-padding-40px-all {padding:40px;}
    .md-padding-45px-all {padding:45px;}
    .md-padding-50px-all {padding:50px;}
    .md-padding-55px-all {padding:55px;}
    .md-padding-60px-all {padding:60px;}
    .md-padding-65px-all {padding:65px;}
    .md-padding-70px-all {padding:70px;}
    .md-padding-75px-all {padding:75px;}
    .md-padding-80px-all {padding:80px;}
    .md-padding-85px-all {padding:85px;}
    .md-padding-90px-all {padding:90px;}
    .md-padding-95px-all {padding:95px;}
    .md-padding-100px-all {padding:100px;}

    /* padding top */
    .md-padding-one-top {padding-top:1%;}
    .md-padding-one-half-top {padding-top:1.5%;}
    .md-padding-two-top {padding-top:2%;}
    .md-padding-two-half-top {padding-top:2.5%;}
    .md-padding-three-top {padding-top:3%;}
    .md-padding-three-half-top {padding-top:3.5%;}
    .md-padding-four-top {padding-top:4%;}
    .md-padding-four-half-top {padding-top:4.5%;}
    .md-padding-five-top {padding-top:5%;}
    .md-padding-five-half-top {padding-top:5.5%;}
    .md-padding-six-top {padding-top:6%;}
    .md-padding-six-half-top {padding-top:6.5%;}
    .md-padding-seven-top {padding-top:7%;}
    .md-padding-seven-half-top {padding-top:7.5%;}
    .md-padding-eight-top {padding-top:8%;}
    .md-padding-eight-half-top {padding-top:8.5%;}
    .md-padding-nine-top {padding-top:9%;}
    .md-padding-nine-half-top {padding-top:9.5%;}
    .md-padding-ten-top {padding-top:10%;}
    .md-padding-ten-half-top {padding-top:10.5%;}
    .md-padding-eleven-top {padding-top:11%;}
    .md-padding-eleven-half-top {padding-top:11.5%;}
    .md-padding-twelve-top {padding-top:12%;}
    .md-padding-twelve-half-top {padding-top:12.5%;}
    .md-padding-thirteen-top {padding-top:13%;}
    .md-padding-thirteen-half-top {padding-top:13.5%;}
    .md-padding-fourteen-top {padding-top:14%;}
    .md-padding-fourteen-half-top {padding-top:14.5%;}
    .md-padding-fifteen-top {padding-top:15%;}
    .md-padding-fifteen-half-top {padding-top:15.5%;}
    .md-padding-sixteen-top {padding-top:16%;}
    .md-padding-sixteen-half-top {padding-top:16.5%;}
    .md-padding-seventeen-top {padding-top:17%;}
    .md-padding-seventeen-half-top {padding-top:17.5%;}
    .md-padding-eighteen-top {padding-top:18%;}
    .md-padding-eighteen-half-top {padding-top:18.5%;}
    .md-padding-nineteen-top {padding-top:19%;}
    .md-padding-nineteen-half-top {padding-top:19.5%;}
    .md-padding-twenty-top {padding-top:20%;}
    .md-padding-twenty-half-top {padding-top:20.5%;}
    .md-padding-twenty-one-top {padding-top:21%;}
    .md-padding-twenty-one-half-top {padding-top:21.5%;}
    .md-padding-twenty-two-top {padding-top:22%;}
    .md-padding-twenty-two-half-top {padding-top:22.5%;}
    .md-padding-twenty-three-top {padding-top:23%;}
    .md-padding-twenty-three-half-top {padding-top:23.5%;}
    .md-padding-twenty-four-top {padding-top:24%;}
    .md-padding-twenty-four-half-top {padding-top:24.5%;}
    .md-padding-twenty-five-top {padding-top:25%;}
    .md-padding-5px-top {padding-top:5px;}
    .md-padding-10px-top {padding-top:10px;}
    .md-padding-15px-top {padding-top:15px;}
    .md-padding-20px-top {padding-top:20px;}
    .md-padding-25px-top {padding-top:25px;}
    .md-padding-30px-top {padding-top:30px;}
    .md-padding-35px-top {padding-top:35px;}
    .md-padding-40px-top {padding-top:40px;}
    .md-padding-45px-top {padding-top:45px;}
    .md-padding-50px-top {padding-top:50px;}
    .md-padding-55px-top {padding-top:55px;}
    .md-padding-60px-top {padding-top:60px;}
    .md-padding-65px-top {padding-top:65px;}
    .md-padding-70px-top {padding-top:70px;}
    .md-padding-75px-top {padding-top:75px;}
    .md-padding-80px-top {padding-top:80px;}
    .md-padding-85px-top {padding-top:85px;}
    .md-padding-90px-top {padding-top:90px;}
    .md-padding-95px-top {padding-top:95px;}
    .md-padding-100px-top {padding-top:100px;}

    /* padding bottom */
    .md-padding-one-bottom {padding-bottom:1%;}
    .md-padding-one-half-bottom {padding-bottom:1.5%;}
    .md-padding-two-bottom {padding-bottom:2%;}
    .md-padding-two-half-bottom {padding-bottom:2.5%;}
    .md-padding-three-bottom {padding-bottom:3%;}
    .md-padding-three-half-bottom {padding-bottom:3.5%;}
    .md-padding-four-bottom {padding-bottom:4%;}
    .md-padding-four-half-bottom {padding-bottom:4.5%;}
    .md-padding-five-bottom {padding-bottom:5%;}
    .md-padding-five-half-bottom {padding-bottom:5.5%;}
    .md-padding-six-bottom {padding-bottom:6%;}
    .md-padding-six-half-bottom {padding-bottom:6.5%;}
    .md-padding-seven-bottom {padding-bottom:7%;}
    .md-padding-seven-half-bottom {padding-bottom:7.5%;}
    .md-padding-eight-bottom {padding-bottom:8%;}
    .md-padding-eight-half-bottom {padding-bottom:8.5%;}
    .md-padding-nine-bottom {padding-bottom:9%;}
    .md-padding-nine-half-bottom {padding-bottom:9.5%;}
    .md-padding-ten-bottom {padding-bottom:10%;}
    .md-padding-ten-half-bottom {padding-bottom:10.5%;}
    .md-padding-eleven-bottom {padding-bottom:11%;}
    .md-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .md-padding-twelve-bottom {padding-bottom:12%;}
    .md-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .md-padding-thirteen-bottom {padding-bottom:13%;}
    .md-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .md-padding-fourteen-bottom {padding-bottom:14%;}
    .md-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .md-padding-fifteen-bottom {padding-bottom:15%;}
    .md-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .md-padding-sixteen-bottom {padding-bottom:16%;}
    .md-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .md-padding-seventeen-bottom {padding-bottom:17%;}
    .md-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .md-padding-eighteen-bottom {padding-bottom:18%;}
    .md-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .md-padding-nineteen-bottom {padding-bottom:19%;}
    .md-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .md-padding-twenty-bottom {padding-bottom:20%;}
    .md-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .md-padding-twenty-one-bottom {padding-bottom:21%;}
    .md-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .md-padding-twenty-two-bottom {padding-bottom:22%;}
    .md-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .md-padding-twenty-three-bottom {padding-bottom:23%;}
    .md-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .md-padding-twenty-four-bottom {padding-bottom:24%;}
    .md-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .md-padding-twenty-five-bottom {padding-bottom:25%;}
    .md-padding-5px-bottom {padding-bottom:5px;}
    .md-padding-10px-bottom {padding-bottom:10px;}
    .md-padding-15px-bottom {padding-bottom:15px;}
    .md-padding-20px-bottom {padding-bottom:20px;}
    .md-padding-25px-bottom {padding-bottom:25px;}
    .md-padding-30px-bottom {padding-bottom:30px;}
    .md-padding-35px-bottom {padding-bottom:35px;}
    .md-padding-40px-bottom {padding-bottom:40px;}
    .md-padding-45px-bottom {padding-bottom:45px;}
    .md-padding-50px-bottom {padding-bottom:50px;}
    .md-padding-55px-bottom {padding-bottom:55px;}
    .md-padding-60px-bottom {padding-bottom:60px;}
    .md-padding-65px-bottom {padding-bottom:65px;}
    .md-padding-70px-bottom {padding-bottom:70px;}
    .md-padding-75px-bottom {padding-bottom:75px;}
    .md-padding-80px-bottom {padding-bottom:80px;}
    .md-padding-85px-bottom {padding-bottom:85px;}
    .md-padding-90px-bottom {padding-bottom:90px;}
    .md-padding-95px-bottom {padding-bottom:95px;}
    .md-padding-100px-bottom {padding-bottom:100px;}

    /* padding right */
    .md-padding-one-right {padding-right:1%;}
    .md-padding-one-half-right {padding-right:1.5%;}
    .md-padding-two-right {padding-right:2%;}
    .md-padding-two-half-right {padding-right:2.5%;}
    .md-padding-three-right {padding-right:3%;}
    .md-padding-three-half-right {padding-right:3.5%;}
    .md-padding-four-right {padding-right:4%;}
    .md-padding-four-half-right {padding-right:4.5%;}
    .md-padding-five-right {padding-right:5%;}
    .md-padding-five-half-right {padding-right:5.5%;}
    .md-padding-six-right {padding-right:6%;}
    .md-padding-six-half-right {padding-right:6.5%;}
    .md-padding-seven-right {padding-right:7%;}
    .md-padding-seven-half-right {padding-right:7.5%;}
    .md-padding-eight-right {padding-right:8%;}
    .md-padding-eight-half-right {padding-right:8.5%;}
    .md-padding-nine-right {padding-right:9%;}
    .md-padding-nine-half-right {padding-right:9.5%;}
    .md-padding-ten-right {padding-right:10%;}
    .md-padding-ten-half-right {padding-right:10.5%;}
    .md-padding-eleven-right {padding-right:11%;}
    .md-padding-eleven-half-right {padding-right:11.5%;}
    .md-padding-twelve-right {padding-right:12%;}
    .md-padding-twelve-half-right {padding-right:12.5%;}
    .md-padding-thirteen-right {padding-right:13%;}
    .md-padding-thirteen-half-right {padding-right:13.5%;}
    .md-padding-fourteen-right {padding-right:14%;}
    .md-padding-fourteen-half-right {padding-right:14.5%;}
    .md-padding-fifteen-right {padding-right:15%;}
    .md-padding-fifteen-half-right {padding-right:15.5%;}
    .md-padding-sixteen-right {padding-right:16%;}
    .md-padding-sixteen-half-right {padding-right:16.5%;}
    .md-padding-seventeen-right {padding-right:17%;}
    .md-padding-seventeen-half-right {padding-right:17.5%;}
    .md-padding-eighteen-right {padding-right:18%;}
    .md-padding-eighteen-half-right {padding-right:18.5%;}
    .md-padding-nineteen-right {padding-right:19%;}
    .md-padding-nineteen-half-right {padding-right:19.5%;}
    .md-padding-twenty-right {padding-right:20%;}
    .md-padding-twenty-half-right {padding-right:20.5%;}
    .md-padding-twenty-one-right {padding-right:21%;}
    .md-padding-twenty-one-half-right {padding-right:21.5%;}
    .md-padding-twenty-two-right {padding-right:22%;}
    .md-padding-twenty-two-half-right {padding-right:22.5%;}
    .md-padding-twenty-three-right {padding-right:23%;}
    .md-padding-twenty-three-half-right {padding-right:23.5%;}
    .md-padding-twenty-four-right {padding-right:24%;}
    .md-padding-twenty-four-half-right {padding-right:24.5%;}
    .md-padding-twenty-five-right {padding-right:25%;}
    .md-padding-5px-right {padding-right:5px;}
    .md-padding-10px-right {padding-right:10px;}
    .md-padding-15px-right {padding-right:15px;}
    .md-padding-20px-right {padding-right:20px;}
    .md-padding-25px-right {padding-right:25px;}
    .md-padding-30px-right {padding-right:30px;}
    .md-padding-35px-right {padding-right:35px;}
    .md-padding-40px-right {padding-right:40px;}
    .md-padding-45px-right {padding-right:45px;}
    .md-padding-50px-right {padding-right:50px;}
    .md-padding-55px-right {padding-right:55px;}
    .md-padding-60px-right {padding-right:60px;}
    .md-padding-65px-right {padding-right:65px;}
    .md-padding-70px-right {padding-right:70px;}
    .md-padding-75px-right {padding-right:75px;}
    .md-padding-80px-right {padding-right:80px;}
    .md-padding-85px-right {padding-right:85px;}
    .md-padding-90px-right {padding-right:90px;}
    .md-padding-95px-right {padding-right:95px;}
    .md-padding-100px-right {padding-right:100px;}

    /* padding left */
    .md-padding-one-left {padding-left:1%;}
    .md-padding-one-half-left {padding-left:1.5%;}
    .md-padding-two-left {padding-left:2%;}
    .md-padding-two-half-left {padding-left:2.5%;}
    .md-padding-three-left {padding-left:3%;}
    .md-padding-three-half-left {padding-left:3.5%;}
    .md-padding-four-left {padding-left:4%;}
    .md-padding-four-half-left {padding-left:4.5%;}
    .md-padding-five-left {padding-left:5%;}
    .md-padding-five-half-left {padding-left:5.5%;}
    .md-padding-six-left {padding-left:6%;}
    .md-padding-six-half-left {padding-left:6.5%;}
    .md-padding-seven-left {padding-left:7%;}
    .md-padding-seven-half-left {padding-left:7.5%;}
    .md-padding-eight-left {padding-left:8%;}
    .md-padding-eight-half-left {padding-left:8.5%;}
    .md-padding-nine-left {padding-left:9%;}
    .md-padding-nine-half-left {padding-left:9.5%;}
    .md-padding-ten-left {padding-left:10%;}
    .md-padding-ten-half-left {padding-left:10.5%;}
    .md-padding-eleven-left {padding-left:11%;}
    .md-padding-eleven-half-left {padding-left:11.5%;}
    .md-padding-twelve-left {padding-left:12%;}
    .md-padding-twelve-half-left {padding-left:12.5%;}
    .md-padding-thirteen-left {padding-left:13%;}
    .md-padding-thirteen-half-left {padding-left:13.5%;}
    .md-padding-fourteen-left {padding-left:14%;}
    .md-padding-fourteen-half-left {padding-left:14.5%;}
    .md-padding-fifteen-left {padding-left:15%;}
    .md-padding-fifteen-half-left {padding-left:15.5%;}
    .md-padding-sixteen-left {padding-left:16%;}
    .md-padding-sixteen-half-left {padding-left:16.5%;}
    .md-padding-seventeen-left {padding-left:17%;}
    .md-padding-seventeen-half-left {padding-left:17.5%;}
    .md-padding-eighteen-left {padding-left:18%;}
    .md-padding-eighteen-half-left {padding-left:18.5%;}
    .md-padding-nineteen-left {padding-left:19%;}
    .md-padding-nineteen-half-left {padding-left:19.5%;}
    .md-padding-twenty-left {padding-left:20%;}
    .md-padding-twenty-half-left {padding-left:20.5%;}
    .md-padding-twenty-one-left {padding-left:21%;}
    .md-padding-twenty-one-half-left {padding-left:21.5%;}
    .md-padding-twenty-two-left {padding-left:22%;}
    .md-padding-twenty-two-half-left {padding-left:22.5%;}
    .md-padding-twenty-three-left {padding-left:23%;}
    .md-padding-twenty-three-half-left {padding-left:23.5%;}
    .md-padding-twenty-four-left {padding-left:24%;}
    .md-padding-twenty-four-half-left {padding-left:24.5%;}
    .md-padding-twenty-five-left {padding-left:25%;}
    .md-padding-5px-left {padding-left:5px;}
    .md-padding-10px-left {padding-left:10px;}
    .md-padding-15px-left {padding-left:15px;}
    .md-padding-20px-left {padding-left:20px;}
    .md-padding-25px-left {padding-left:25px;}
    .md-padding-30px-left {padding-left:30px;}
    .md-padding-35px-left {padding-left:35px;}
    .md-padding-40px-left {padding-left:40px;}
    .md-padding-45px-left {padding-left:45px;}
    .md-padding-50px-left {padding-left:50px;}
    .md-padding-55px-left {padding-left:55px;}
    .md-padding-60px-left {padding-left:60px;}
    .md-padding-65px-left {padding-left:65px;}
    .md-padding-70px-left {padding-left:70px;}
    .md-padding-75px-left {padding-left:75px;}
    .md-padding-80px-left {padding-left:80px;}
    .md-padding-85px-left {padding-left:85px;}
    .md-padding-90px-left {padding-left:90px;}
    .md-padding-95px-left {padding-left:95px;}
    .md-padding-100px-left {padding-left:100px;}

    /* padding top bottom */
    .md-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .md-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .md-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .md-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .md-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .md-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .md-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .md-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .md-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .md-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .md-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .md-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .md-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .md-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .md-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .md-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .md-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .md-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .md-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .md-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .md-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .md-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .md-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .md-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .md-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .md-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .md-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .md-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .md-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .md-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .md-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .md-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .md-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .md-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .md-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .md-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .md-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .md-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .md-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .md-padding-twenty-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .md-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .md-padding-twenty-one-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .md-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .md-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .md-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .md-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .md-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .md-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .md-padding-twenty-tb {padding-top:25%; padding-bottom:25%;}
    .md-padding-5px-tb {padding-top:5px; padding-bottom:5px;}
    .md-padding-10px-tb {padding-top:10px; padding-bottom:10px;}
    .md-padding-15px-tb {padding-top:15px; padding-bottom:15px;}
    .md-padding-20px-tb {padding-top:20px; padding-bottom:20px;}
    .md-padding-25px-tb {padding-top:25px; padding-bottom:25px;}
    .md-padding-30px-tb {padding-top:30px; padding-bottom:30px;}
    .md-padding-35px-tb {padding-top:35px; padding-bottom:35px;}
    .md-padding-40px-tb {padding-top:40px; padding-bottom:40px;}
    .md-padding-45px-tb {padding-top:45px; padding-bottom:45px;}
    .md-padding-50px-tb {padding-top:50px; padding-bottom:50px;}
    .md-padding-55px-tb {padding-top:55px; padding-bottom:55px;}
    .md-padding-60px-tb {padding-top:60px; padding-bottom:60px;}
    .md-padding-65px-tb {padding-top:65px; padding-bottom:65px;}
    .md-padding-70px-tb {padding-top:70px; padding-bottom:70px;}
    .md-padding-75px-tb {padding-top:75px; padding-bottom:75px;}
    .md-padding-80px-tb {padding-top:80px; padding-bottom:80px;}
    .md-padding-85px-tb {padding-top:85px; padding-bottom:85px;}
    .md-padding-90px-tb {padding-top:90px; padding-bottom:90px;}
    .md-padding-95px-tb {padding-top:95px; padding-bottom:95px;}
    .md-padding-100px-tb {padding-top:100px; padding-bottom:100px;}

    /* padding left right */
    .md-padding-one-lr {padding-left:1%; padding-right:1%;}
    .md-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .md-padding-two-lr {padding-left:2%; padding-right:2%;}
    .md-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .md-padding-three-lr {padding-left:3%; padding-right:3%;}
    .md-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .md-padding-four-lr {padding-left:4%; padding-right:4%;}
    .md-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .md-padding-five-lr {padding-left:5%; padding-right:5%;}
    .md-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .md-padding-six-lr {padding-left:6%; padding-right:6%;}
    .md-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .md-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .md-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .md-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .md-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .md-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .md-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .md-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .md-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .md-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .md-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .md-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .md-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .md-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .md-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .md-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .md-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .md-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .md-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .md-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .md-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .md-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .md-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .md-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .md-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .md-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .md-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .md-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .md-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .md-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .md-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .md-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .md-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .md-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .md-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .md-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .md-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .md-padding-twenty-five-lr {padding-left:25%; padding-right:25%;}
    .md-padding-5px-lr {padding-right:5px; padding-left:5px;}
    .md-padding-10px-lr {padding-right:10px; padding-left:10px;}
    .md-padding-15px-lr {padding-right:15px; padding-left:15px;}
    .md-padding-20px-lr {padding-right:20px; padding-left:20px;}
    .md-padding-25px-lr {padding-right:25px; padding-left:25px;}
    .md-padding-30px-lr {padding-right:30px; padding-left:30px;}
    .md-padding-35px-lr {padding-right:35px; padding-left:35px;}
    .md-padding-40px-lr {padding-right:40px; padding-left:40px;}
    .md-padding-45px-lr {padding-right:45px; padding-left:45px;}
    .md-padding-50px-lr {padding-right:50px; padding-left:50px;}
    .md-padding-55px-lr {padding-right:55px; padding-left:55px;}
    .md-padding-60px-lr {padding-right:60px; padding-left:60px;}
    .md-padding-65px-lr {padding-right:65px; padding-left:65px;}
    .md-padding-70px-lr {padding-right:70px; padding-left:70px;}
    .md-padding-75px-lr {padding-right:75px; padding-left:75px;}
    .md-padding-80px-lr {padding-right:80px; padding-left:80px;}
    .md-padding-85px-lr {padding-right:85px; padding-left:85px;}
    .md-padding-90px-lr {padding-right:90px; padding-left:90px;}
    .md-padding-95px-lr {padding-right:95px; padding-left:95px;}
    .md-padding-100px-lr {padding-right:100px; padding-left:100px;}

    /* top */
    .md-top-auto {top:auto}
    .md-top-0 {top:0 !important}
    .md-top-inherit {top: inherit !important;}
    .md-top-1 {top:1px}
    .md-top-2 {top:2px}
    .md-top-3 {top:3px}
    .md-top-4 {top:4px}
    .md-top-5 {top:5px}
    .md-top-6 {top:6px}
    .md-top-7 {top:7px}
    .md-top-8 {top:8px}
    .md-top-9 {top:9px}
    .md-top-10 {top:10px}
    .md-top-12 {top:12px}
    .md-top-15 {top:15px}
    .md-top-17 {top:17px}
    .md-top-20 {top:20px}
    .md-top-25 {top:25px}
    .md-top-30 {top:30px}
    .md-top-35 {top:35px}
    .md-top-40 {top:40px}
    .md-top-45 {top:45px}
    .md-top-50 {top:50px}
    .md-top-minus1 {top:-1px}
    .md-top-minus2 {top:-2px}
    .md-top-minus3 {top:-3px}
    .md-top-minus4 {top:-4px}
    .md-top-minus5 {top:-5px}
    .md-top-minus6 {top:-6px}
    .md-top-minus7 {top:-7px}
    .md-top-minus8 {top:-8px}
    .md-top-minus9 {top:-9px}
    .md-top-minus10 {top:-10px}
    .md-top-minus15 {top:-15px}
    .md-top-minus20 {top:-20px}
    .md-top-minus25 {top:-25px}
    .md-top-minus30 {top:-30px}
    .md-top-minus35 {top:-35px}
    .md-top-minus40 {top:-40px}
    .md-top-minus45 {top:-45px}
    .md-top-minus50 {top:-50px}

    /* bottom */
    .md-bottom-auto {bottom:auto}
    .md-bottom-0 {bottom:0}
    .md-bottom-1 {bottom:1px}
    .md-bottom-2 {bottom:2px}
    .md-bottom-3 {bottom:3px}
    .md-bottom-4 {bottom:4px}
    .md-bottom-5 {bottom:5px}
    .md-bottom-6 {bottom:6px}
    .md-bottom-7 {bottom:7px}
    .md-bottom-8 {bottom:8px}
    .md-bottom-9 {bottom:9px}
    .md-bottom-10 {bottom:10px}
    .md-bottom-20 {bottom:20px}
    .md-bottom-25 {bottom:25px}
    .md-bottom-30 {bottom:30px}
    .md-bottom-35 {bottom:35px}
    .md-bottom-40 {bottom:40px}
    .md-bottom-45 {bottom:45px}
    .md-bottom-50 {bottom:50px}
    .md-bottom-minus30 {bottom:-30px}
    .md-bottom-minus50 {bottom:-50px}
    .md-bottom-minus55 { bottom: -55px; }
    .md-bottom-minus60 { bottom: -60px; }

    /* left */
    .md-left-auto {left:auto}
    .md-left-0 {left:0}
    .md-left-1 {left:1px}
    .md-left-2 {left:2px}
    .md-left-3 {left:3px}
    .md-left-4 {left:4px}
    .md-left-5 {left:5px}
    .md-left-6 {left:6px}
    .md-left-7 {left:7px}
    .md-left-8 {left:8px}
    .md-left-9 {left:9px}
    .md-left-10 {left:10px}

    /* right */
    .md-right-auto {right:auto}
    .md-right-0 {right:0}
    .md-right-1 {right:1px}
    .md-right-2 {right:2px}
    .md-right-3 {right:3px}
    .md-right-4 {right:4px}
    .md-right-5 {right:5px}
    .md-right-6 {right:6px}
    .md-right-7 {right:7px}
    .md-right-8 {right:8px}
    .md-right-9 {right:9px}
    .md-right-10 {right:10px}

    /* width */
    .md-w-10 {width:10% !important;}
    .md-w-15 {width:15% !important;}
    .md-w-20 {width:20% !important;}
    .md-w-25 {width:25% !important;}
    .md-w-30 {width:30% !important;}
    .md-w-35 {width:35% !important;}
    .md-w-40 {width:40% !important;}
    .md-w-45 {width:45% !important;}
    .md-w-50 {width:50% !important;}
    .md-w-55 {width:55% !important;}
    .md-w-60 {width:60% !important;}
    .md-w-65 {width:65% !important;}
    .md-w-70 {width:70% !important;}
    .md-w-75 {width:75% !important;}
    .md-w-80 {width:80% !important;}
    .md-w-85 {width:85% !important;}
    .md-w-90 {width:90% !important;}
    .md-w-95 {width:95% !important;}
    .md-w-100 {width:100% !important;}
    .md-w-auto {width: auto !important}
    .md-w-50px {width:50px !important;}
    .md-w-70px {width:70px !important;}
    .md-w-75px {width:75px !important;}
    .md-w-80px {width:80px !important;}
    .md-w-85px {width:85px !important;}
    .md-w-90px {width:90px !important;}
    .md-w-95px {width:95px !important;}
    .md-w-100px {width:100px !important;}
    .md-w-120px {width:120px !important;}
    .md-w-130px {width:130px !important;}
    .md-w-150px {width:150px !important;}
    .md-w-180px {width:180px !important;}
    .md-w-200px {width:200px !important;}
    .md-w-250px {width:250px !important;}
    .md-w-300px {width:300px !important;}
    .md-w-350px {width:350px !important;}
    .md-w-400px {width:400px !important;}
    .md-w-450px {width:450px !important;}
    .md-w-500px {width:500px !important;}
    .md-w-550px {width:550px !important;}
    .md-w-600px {width:600px !important;}
    .md-w-650px {width:650px !important;}
    .md-w-700px {width:700px !important;}
    .md-w-750px {width:750px !important;}
    .md-w-800px {width:800px !important;}
    .md-w-850px {width:850px !important;}
    .md-w-900px {width:900px !important;}
    .md-w-950px {width:950px !important;}
    .md-w-1000px {width:1000px !important;}

    /* height */
    .md-h-50 {height: 50%}
    .md-h-100 {height: 100%}
    .md-h-auto {height:auto !important}
    .md-h-10px {height: 10px !important}
    .md-h-20px {height: 20px !important}
    .md-h-30px {height: 30px !important}
    .md-h-40px {height: 40px !important}
    .md-h-50px {height: 50px !important}
    .md-h-60px {height: 60px !important}
    .md-h-70px {height: 70px !important}
    .md-h-80px {height: 80px !important}
    .md-h-90px {height: 90px !important}
    .md-h-100px {height: 100px !important}
    .md-h-150px {height: 150px !important}
    .md-h-200px {height: 200px !important}
    .md-h-250px {height: 250px !important}
    .md-h-300px {height: 300px !important}
    .md-h-350px {height: 350px !important}
    .md-h-400px {height: 400px !important}
    .md-h-450px {height: 450px !important}
    .md-h-500px {height: 500px !important}
    .md-h-550px {height: 550px !important}
    .md-h-600px {height: 600px !important}
    .md-h-650px {height: 650px !important}
    .md-h-700px {height: 700px !important}

    /* line height */
    .md-line-height-10 {line-height:10px;}
    .md-line-height-18 {line-height:18px;}
    .md-line-height-20 {line-height:20px}
    .md-line-height-24 {line-height:24px}
    .md-line-height-22 {line-height:22px}
    .md-line-height-26 {line-height:26px}
    .md-line-height-28 {line-height:28px}
    .md-line-height-30 {line-height:30px}
    .md-line-height-35 {line-height:35px}
    .md-line-height-40 {line-height:40px}
    .md-line-height-45 {line-height:45px}
    .md-line-height-50 {line-height:50px}
    .md-line-height-55 {line-height:55px}
    .md-line-height-60 {line-height:60px}
    .md-line-height-65 {line-height:65px}
    .md-line-height-70 {line-height:70px}
    .md-line-height-75 {line-height:75px}
    .md-line-height-80 {line-height:80px}
    .md-line-height-85 {line-height:85px}
    .md-line-height-90 {line-height:90px}
    .md-line-height-95 {line-height:95px}
    .md-line-height-100 {line-height:100px}

    /* border */
    .md-no-border-top {border-top:0 !important}
    .md-no-border-bottom {border-bottom:0 !important}
    .md-no-border-right {border-right:0 !important}
    .md-no-border-left {border-left:0 !important}
    .md-no-border { border: 0 !important}

    /* parallax */
    .parallax {background-position: center;}
    .portfolio-filter-tab-1 li {margin: 10px 20px;}
    .portfolio-filter-tab-2 li.nav-item a.nav-link {padding: 7px 18px;}

    /* feature-box-4 */
    .feature-box-4 figure figcaption {width: 100%; display: table-cell; margin: 0 ; padding: 15px ; top: 0; height: 100%;}
    .grid-item.feature-box-4 figure figcaption .btn {opacity: 1;}
    .feature-box-4 figure:hover figcaption {transform: translateY(0px); -moz-transform: translateY(0px); -ms-transform: translateY(0px); -webkit-transform: translateY(0px); -o-transform: translateY(0px);}

    /* homepage option-3 */
    .md-swap-block {display: flex; flex-flow: column;}
    .md-swap-block > div:first-child {order: 2;}
    .md-swap-block .arrow-bottom::after{top: -29px; border: solid transparent; border-bottom-color: #f5f5f5; border-width: 15px; margin-left: -15px;}

    /* blog post style 3 */
    .md-no-background-img {background-image: none !important}

    /* blog post style 3 */
    .blog-post-style3 .grid-item .blog-post .blog-post-images .blog-hover-icon i {font-size: 36px;}

    /* swiper button 3 */
    .swiper-button-prev.swiper-prev-style3, .swiper-button-next.swiper-next-style3 {left: 64.5%}

    /* feature box style 8 */
    .parallax-feature-box-bottom {position: relative;}

    /* homepage 14 */
    .right-image {position: relative; width: 100%; bottom:0; padding: 0 15px 0; right: 0; clear: both;}
    .box-separator-line { left: -45px; top: 66px; }
    .content-right-slider .swiper-button-next, .content-right-slider .swiper-button-prev, .swiper-button-prev.slider-long-arrow-white, .swiper-container-rtl .swiper-button-next.slider-long-arrow-white, .swiper-button-next.slider-long-arrow-white, .swiper-container-rtl .swiper-button-prev.slider-long-arrow-white { background-size: 25px auto; width: 30px; height: 30px;}

    /* full width menu nav */
    .full-width-pull-menu .link-style-2 ul li a {/*font-size: 36px; line-height: 70px*/}
    .full-width-pull-menu .dropdown .dropdown-toggle {top: 35px}

    /* feature box 10 */
    .feature-box-10 .number-center::before {width: 100%;}
    .feature-box-10 .number-center::after {width: 100%}

    /* feature box 4 */
    .grid-item.feature-box-4 figure:hover figcaption { transform: translateY(-10px); -moz-transform: translateY(-10px); -webkit-transform: translateY(-10px); -ms-transform: translateY(-10px); -o-transform: translateY(-10px);}
    .grid-item.feature-box-4 figure figcaption { width: 80%; padding: 0 0 30px 30px}

    /* hamburger menu */
    .hamburger-menu-wrepper { padding: 35px 36px; }
    .hamburger-menu-links {font-size: 32px; line-height: 32px}
    .menu-middle {width: 95%;}

    /* homepage 10 */
    .swiper-auto-slide { padding: 0 15px;}
    .swiper-auto-slide .swiper-slide { width: 100%; }

    /* feature-box-11 */
    .feature-box-11:nth-child(2) .progress-line:after {visibility: hidden;}

    /* feature box style 14 */
    .feature-box-14 > div:nth-child(even) { border-right: 0}
    .feature-box-14 > div { border-bottom-width: 1px}
    .feature-box-14 > div:last-child { border-bottom-width: 0 }

    /* homepage 6 */
    .icon-box .icon-box-holder { height: 90px; }
    .icon-box .icon-box-holder i {  padding-top: 5px; }

    /* swiper pagination */
    .swiper-vertical-pagination > .swiper-pagination-bullets { right: 30px; }
    .right-image img { max-width: 100%;}

    /* banner style 01 */
    .banner-style1 figure:hover figcaption p {height: 100px;}

    /* counter box style 5 */
    .counter-box-5 .counter-box .number {font-size: 50px; line-height: 50px;}
    .counter-box-5 .counter-box { width: 15%; }

    /* aside style */
    .left-sidebar, .right-sidebar {padding: 0 15px 15px;}

    /* scroll to top */
    .scroll-top-arrow {display: none !important; opacity: 0 !important}

    /* text property */
    .md-text-middle-line::before { display: none;}

    /* hover-option 3 */
    .hover-option3 .grid-item figure figcaption {padding: 0 30px}

    .mfp-close, .mfp-close:active {top: 0; right: 0;}
    button.mfp-arrow {background-color: #000; height: 80px; width: 80px; opacity: 1}
    .mfp-arrow:before {margin: 0 auto; width: 100%; height: 100%; display: inline-block; vertical-align: middle; text-align: center; line-height: 80px; border: 0}
    .mfp-arrow-left, .left-nav-sidebar .mfp-arrow-left {left: 6px}
    .mfp-arrow-right {right: 6px}
    .banner-style2 figcaption {width: 65%;}
    .list-style-6 li { padding: 0 0 8px 0;}
    .search-form .search-input {font-size: 24px; padding-top: 15px; padding-bottom: 15px;}
    .search-form .search-button {font-size: 15px;}
    .mfp-container, .left-nav-sidebar .mfp-container { padding-left: 15px; padding-right: 15px; }

    /* next prev button style 2 */
    .swiper-next-style2 {right: 15px;}
    .swiper-prev-style2 {left: 15px;}
    .swiper-auto-slide .swiper-scrollbar { left: 12%; width: 76%; }

    /* opacity */
    .md-opacity1 {opacity:.1}
    .md-opacity2 {opacity:.2}
    .md-opacity3 {opacity:.3}
    .md-opacity4 {opacity:.4}
    .md-opacity5 {opacity:.5}
    .md-opacity6 {opacity:.6}
    .md-opacity7 {opacity:.7}
    .md-opacity8 {opacity:.8}
    .md-opacity9 {opacity:.9}
    .md-opacity10 {opacity:1}

    /* blog comments list */
    .blog-comment li {padding: 30px 0}

    /* blog post style4 */
    .blog-post-style4 figure figcaption {padding: 30px}

    .blog-header-style1 { display: block; }
    .blog-header-style1 li {width: 100%; margin-bottom: 15px; }
    .blog-header-style1 li.blog-column-active {width: 100%;}

    /* feature box */
    .feature-box.feature-box-17 .box .content {top: 30px;}

    /* icon type */
    .elements-social .extra-small-icon li, .elements-social .small-icon li, .elements-social .medium-icon li, .elements-social .large-icon li, .elements-social .extra-large-icon li {margin-bottom: 10px;}

    /* pricing table */
    .highlight .pricing-box {transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1);}

    .demo-heading {font-size: 30px; line-height: 35px;}
    .nav.navbar-left-sidebar > li > a { line-height: normal; }
    .nav.navbar-left-sidebar > li .second-level li a { line-height: 20px; }

    /* latest post */
    aside .latest-post li { padding: 0 0 5px; }
    div .counter-box-4:nth-child(2n) .counter:after { display: none; }

    /* tab style 5 */
    .tab-style5 .nav-tabs li { font-size: 19px; line-height: 40px; }
    .tab-style5 .nav-tabs .nav-link.active { padding-left: 25px; }
    .tab-style5 .tab-box { left: 30px; transform: translate(0, -50%); }

    /* play icon */
    .icon-play-style-2 { left: 50%;}

    /* grid */
    .grid.md-grid-6col li { width: 16.67%; }
    .grid.md-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.md-grid-5col li { width: 20%; }
    .grid.md-grid-5col li.grid-item-double { width: 40%; }
    .grid.md-grid-4col li { width: 25%; }
    .grid.md-grid-4col li.grid-item-double { width: 50%; }
    .grid.md-grid-3col li { width: 33.33%; }
    .grid.md-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.md-grid-2col li { width: 50%; }
    .grid.md-grid-2col li.grid-item-double { width: 100%; }
    .grid.md-grid-1col li { width: 100%; }
    .grid.md-grid-1col li.grid-item-double { width: 100%; }

    /* footer */
    footer .small-icon li { margin: 0 6px; }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
    /* height */
    .md-landscape-h-300px { height: 300px !important; }
    .md-landscape-h-350px { height: 350px !important; }
    .md-landscape-h-400px { height: 400px !important; }
    .md-landscape-h-450px { height: 450px !important; }
    .md-landscape-h-500px { height: 500px !important; }
    .md-landscape-h-520px { height: 520px !important; }
    .md-landscape-h-550px { height: 550px !important; }
    .md-landscape-h-580px { height: 580px !important; }
    .md-landscape-h-600px { height: 600px !important; }
    .md-landscape-h-650px { height: 650px !important; }
    .md-landscape-h-700px { height: 700px !important; }
}

@media only screen and (max-width: 991px) and (orientation: portrait) {
    /* height */
    .extra-small-screen {height:175px;}
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
    /* height */
    .extra-small-screen {height:200px;}
}

@media (min-width: 767px) {
    .section_hide_min-w {display: none !important;}
}

@media (max-width: 767px) {
    h1, h2, h3, h4, h5, h6 {margin:0 0 17px; padding:0; letter-spacing: 0; font-weight: 400;}
    h1 {font-size:37px; line-height: 42px;}
    h2 {font-size:30px; line-height:36px}
    h3 {font-size:28px; line-height:34px}
    h4 {font-size:24px; line-height:30px}
    h5 {font-size:20px; line-height:28px}
    h6 {font-size:18px; line-height:23px}

    .sm-text-center {
        text-align: center !important;
    }

    /* opacity */
    .sm-opacity1 {opacity:.1}
    .sm-opacity2 {opacity:.2}
    .sm-opacity3 {opacity:.3}
    .sm-opacity4 {opacity:.4}
    .sm-opacity5 {opacity:.5}
    .sm-opacity6 {opacity:.6}
    .sm-opacity7 {opacity:.7}
    .sm-opacity8 {opacity:.8}
    .sm-opacity9 {opacity:.9}
    .sm-opacity10 {opacity:1}

    /* image position*/
    .sm-background-image-left { background-position: left !important;}
    .sm-background-image-right { background-position: right !important;}
    .sm-background-image-center { background-position: center !important;}

    /* margin */
    .sm-margin-one-all {margin:1%;}
    .sm-margin-one-half-all {margin:1.5%;}
    .sm-margin-two-all {margin:2%;}
    .sm-margin-two-half-all {margin:2.5%;}
    .sm-margin-three-all {margin:3%;}
    .sm-margin-three-half-all {margin:3.5%;}
    .sm-margin-four-all {margin:4%;}
    .sm-margin-four-half-all {margin:4.5%;}
    .sm-margin-five-all {margin:5%;}
    .sm-margin-five-half-all {margin:5.5%;}
    .sm-margin-six-all {margin:6%;}
    .sm-margin-six-half-all {margin:6.5%;}
    .sm-margin-seven-all {margin:7%;}
    .sm-margin-seven-half-all {margin:7.5%;}
    .sm-margin-eight-all {margin:8%;}
    .sm-margin-eight-half-all {margin:8.5%;}
    .sm-margin-nine-all {margin:9%;}
    .sm-margin-nine-half-all {margin:9.5%;}
    .sm-margin-ten-all {margin:10%;}
    .sm-margin-ten-half-all {margin:10.5%;}
    .sm-margin-eleven-all {margin:11%;}
    .sm-margin-eleven-half-all {margin:11.5%;}
    .sm-margin-twelve-all {margin:12%;}
    .sm-margin-twelve-half-all {margin:12.5%;}
    .sm-margin-thirteen-all {margin:13%;}
    .sm-margin-thirteen-half-all {margin:13.5%;}
    .sm-margin-fourteen-all {margin:14%;}
    .sm-margin-fourteen-half-all {margin:14.5%;}
    .sm-margin-fifteen-all {margin:15%;}
    .sm-margin-fifteen-half-all {margin:15.5%;}
    .sm-margin-sixteen-all {margin:16%;}
    .sm-margin-sixteen-half-all {margin:16.5%;}
    .sm-margin-seventeen-all {margin:17%;}
    .sm-margin-seventeen-half-all {margin:17.5%;}
    .sm-margin-eighteen-all {margin:18%;}
    .sm-margin-eighteen-half-all {margin:18.5%;}
    .sm-margin-nineteen-all {margin:19%;}
    .sm-margin-nineteen-half-all {margin:19.5%;}
    .sm-margin-twenty-all {margin:20%;}
    .sm-margin-twenty-half-all {margin:20.5%;}
    .sm-margin-twenty-one-all {margin:21%;}
    .sm-margin-twenty-one-half-all {margin:21.5%;}
    .sm-margin-twenty-two-all {margin:22%;}
    .sm-margin-twenty-two-half-all {margin:22.5%;}
    .sm-margin-twenty-three-all {margin:23%;}
    .sm-margin-twenty-three-half-all {margin:23.5%;}
    .sm-margin-twenty-four-all {margin:24%;}
    .sm-margin-twenty-four-half-all {margin:24.5%;}
    .sm-margin-twenty-five-all {margin:25%;}
    .sm-no-margin {margin:0 !important}
    .sm-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important}
    .sm-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important}
    .sm-no-margin-top {margin-top:0 !important}
    .sm-no-margin-bottom {margin-bottom:0 !important}
    .sm-no-margin-left {margin-left:0 !important}
    .sm-no-margin-right {margin-right:0 !important}
    .sm-margin-lr-auto {margin-left:auto !important; margin-right:auto !important}
    .sm-margin-auto {margin: 0 auto !important;}

    /* margin top */
    .sm-margin-one-top {margin-top:1%;}
    .sm-margin-one-half-top {margin-top:1.5%;}
    .sm-margin-two-top {margin-top:2%;}
    .sm-margin-two-half-top {margin-top:2.5%;}
    .sm-margin-three-top {margin-top:3%;}
    .sm-margin-three-half-top {margin-top:3.5%;}
    .sm-margin-four-top {margin-top:4%;}
    .sm-margin-four-half-top {margin-top:4.5%;}
    .sm-margin-five-top {margin-top:5%;}
    .sm-margin-five-half-top {margin-top:5.5%;}
    .sm-margin-six-top {margin-top:6%;}
    .sm-margin-six-half-top {margin-top:6.5%;}
    .sm-margin-seven-top {margin-top:7%;}
    .sm-margin-seven-half-top {margin-top:7.5%;}
    .sm-margin-eight-top {margin-top:8%;}
    .sm-margin-eight-half-top {margin-top:8.5%;}
    .sm-margin-nine-top {margin-top:9%;}
    .sm-margin-nine-half-top {margin-top:9.5%;}
    .sm-margin-ten-top {margin-top:10%;}
    .sm-margin-ten-half-top {margin-top:10.5%;}
    .sm-margin-eleven-top {margin-top:11%;}
    .sm-margin-eleven-half-top {margin-top:11.5%;}
    .sm-margin-twelve-top {margin-top:12%;}
    .sm-margin-twelve-half-top {margin-top:12.5%;}
    .sm-margin-thirteen-top {margin-top:13%;}
    .sm-margin-thirteen-half-top {margin-top:13.5%;}
    .sm-margin-fourteen-top {margin-top:14%;}
    .sm-margin-fourteen-half-top {margin-top:14.5%;}
    .sm-margin-fifteen-top {margin-top:15%;}
    .sm-margin-fifteen-half-top {margin-top:15.5%;}
    .sm-margin-sixteen-top {margin-top:16%;}
    .sm-margin-sixteen-half-top {margin-top:16.5%;}
    .sm-margin-seventeen-top {margin-top:17%;}
    .sm-margin-seventeen-half-top {margin-top:17.5%;}
    .sm-margin-eighteen-top {margin-top:18%;}
    .sm-margin-eighteen-half-top {margin-top:18.5%;}
    .sm-margin-nineteen-top {margin-top:19%;}
    .sm-margin-nineteen-half-top {margin-top:19.5%;}
    .sm-margin-twenty-top {margin-top:20%;}
    .sm-margin-twenty-half-top {margin-top:20.5%;}
    .sm-margin-twenty-one-top {margin-top:21%;}
    .sm-margin-twenty-one-half-top {margin-top:21.5%;}
    .sm-margin-twenty-two-top {margin-top:22%;}
    .sm-margin-twenty-two-half-top {margin-top:22.5%;}
    .sm-margin-twenty-three-top {margin-top:23%;}
    .sm-margin-twenty-three-half-top {margin-top:23.5%;}
    .sm-margin-twenty-four-top {margin-top:24%;}
    .sm-margin-twenty-four-half-top {margin-top:24.5%;}
    .sm-margin-twenty-five-top {margin-top:25%;}
    .sm-margin-5px-top {margin-top:5px;}
    .sm-margin-10px-top {margin-top:10px;}
    .sm-margin-15px-top {margin-top:15px;}
    .sm-margin-20px-top {margin-top:20px;}
    .sm-margin-25px-top {margin-top:25px;}
    .sm-margin-30px-top {margin-top:30px;}
    .sm-margin-35px-top {margin-top:35px;}
    .sm-margin-40px-top {margin-top:40px;}
    .sm-margin-45px-top {margin-top:45px;}
    .sm-margin-50px-top {margin-top:50px;}
    .sm-margin-55px-top {margin-top:55px;}
    .sm-margin-60px-top {margin-top:60px;}
    .sm-margin-65px-top {margin-top:65px;}
    .sm-margin-70px-top {margin-top:70px;}
    .sm-margin-75px-top {margin-top:75px;}
    .sm-margin-80px-top {margin-top:80px;}
    .sm-margin-85px-top {margin-top:85px;}
    .sm-margin-90px-top {margin-top:90px;}
    .sm-margin-95px-top {margin-top:95px;}
    .sm-margin-100px-top {margin-top:100px;}

    /* margin bottom */
    .sm-margin-one-bottom {margin-bottom:1%;}
    .sm-margin-one-half-bottom {margin-bottom:1.5%;}
    .sm-margin-two-bottom {margin-bottom:2%;}
    .sm-margin-two-half-bottom {margin-bottom:2.5%;}
    .sm-margin-three-bottom {margin-bottom:3%;}
    .sm-margin-three-half-bottom {margin-bottom:3.5%;}
    .sm-margin-four-bottom {margin-bottom:4%;}
    .sm-margin-four-half-bottom {margin-bottom:4.5%;}
    .sm-margin-five-bottom {margin-bottom:5%;}
    .sm-margin-five-half-bottom {margin-bottom:5.5%;}
    .sm-margin-six-bottom {margin-bottom:6%;}
    .sm-margin-six-half-bottom {margin-bottom:6.5%;}
    .sm-margin-seven-bottom {margin-bottom:7%;}
    .sm-margin-seven-half-bottom {margin-bottom:7.5%;}
    .sm-margin-eight-bottom {margin-bottom:8%;}
    .sm-margin-eight-half-bottom {margin-bottom:8.5%;}
    .sm-margin-nine-bottom {margin-bottom:9%;}
    .sm-margin-nine-half-bottom {margin-bottom:9.5%;}
    .sm-margin-ten-bottom {margin-bottom:10%;}
    .sm-margin-ten-half-bottom {margin-bottom:10.5%;}
    .sm-margin-eleven-bottom {margin-bottom:11%;}
    .sm-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .sm-margin-twelve-bottom {margin-bottom:12%;}
    .sm-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .sm-margin-thirteen-bottom {margin-bottom:13%;}
    .sm-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .sm-margin-fourteen-bottom {margin-bottom:14%;}
    .sm-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .sm-margin-fifteen-bottom {margin-bottom:15%;}
    .sm-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .sm-margin-sixteen-bottom {margin-bottom:16%;}
    .sm-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .sm-margin-seventeen-bottom {margin-bottom:17%;}
    .sm-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .sm-margin-eighteen-bottom {margin-bottom:18%;}
    .sm-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .sm-margin-nineteen-bottom {margin-bottom:19%;}
    .sm-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .sm-margin-twenty-bottom {margin-bottom:20%;}
    .sm-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .sm-margin-twenty-one-bottom {margin-bottom:21%;}
    .sm-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .sm-margin-twenty-two-bottom {margin-bottom:22%;}
    .sm-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .sm-margin-twenty-three-bottom {margin-bottom:23%;}
    .sm-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .sm-margin-twenty-four-bottom {margin-bottom:24%;}
    .sm-margin-twenty-four-half-bottom {margin-bottom:24.5%;}
    .sm-margin-twenty-five-bottom {margin-bottom:25%;}
    .sm-margin-5px-bottom {margin-bottom:5px;}
    .sm-margin-10px-bottom {margin-bottom:10px;}
    .sm-margin-15px-bottom {margin-bottom:15px;}
    .sm-margin-20px-bottom {margin-bottom:20px;}
    .sm-margin-25px-bottom {margin-bottom:25px;}
    .sm-margin-30px-bottom {margin-bottom:30px;}
    .sm-margin-35px-bottom {margin-bottom:35px;}
    .sm-margin-40px-bottom {margin-bottom:40px;}
    .sm-margin-45px-bottom {margin-bottom:45px;}
    .sm-margin-50px-bottom {margin-bottom:50px;}
    .sm-margin-55px-bottom {margin-bottom:55px;}
    .sm-margin-60px-bottom {margin-bottom:60px;}
    .sm-margin-65px-bottom {margin-bottom:65px;}
    .sm-margin-70px-bottom {margin-bottom:70px;}
    .sm-margin-75px-bottom {margin-bottom:75px;}
    .sm-margin-80px-bottom {margin-bottom:80px;}
    .sm-margin-85px-bottom {margin-bottom:85px;}
    .sm-margin-90px-bottom {margin-bottom:90px;}
    .sm-margin-95px-bottom {margin-bottom:95px;}
    .sm-margin-100px-bottom {margin-bottom:100px;}

    /* margin right */
    .sm-margin-one-right {margin-right:1%;}
    .sm-margin-one-half-right {margin-right:1.5%;}
    .sm-margin-two-right {margin-right:2%;}
    .sm-margin-two-half-right {margin-right:2.5%;}
    .sm-margin-three-right {margin-right:3%;}
    .sm-margin-three-half-right {margin-right:3.5%;}
    .sm-margin-four-right {margin-right:4%;}
    .sm-margin-four-half-right {margin-right:4.5%;}
    .sm-margin-five-right {margin-right:5%;}
    .sm-margin-five-half-right {margin-right:5.5%;}
    .sm-margin-six-right {margin-right:6%;}
    .sm-margin-six-half-right {margin-right:6.5%;}
    .sm-margin-seven-right {margin-right:7%;}
    .sm-margin-seven-half-right {margin-right:7.5%;}
    .sm-margin-eight-right {margin-right:8%;}
    .sm-margin-eight-half-right {margin-right:8.5%;}
    .sm-margin-nine-right {margin-right:9%;}
    .sm-margin-nine-half-right {margin-right:9.5%;}
    .sm-margin-ten-right {margin-right:10%;}
    .sm-margin-ten-half-right {margin-right:10.5%;}
    .sm-margin-eleven-right {margin-right:11%;}
    .sm-margin-eleven-half-right {margin-right:11.5%;}
    .sm-margin-twelve-right {margin-right:12%;}
    .sm-margin-twelve-half-right {margin-right:12.5%;}
    .sm-margin-thirteen-right {margin-right:13%;}
    .sm-margin-thirteen-half-right {margin-right:13.5%;}
    .sm-margin-fourteen-right {margin-right:14%;}
    .sm-margin-fourteen-half-right {margin-right:14.5%;}
    .sm-margin-fifteen-right {margin-right:15%;}
    .sm-margin-fifteen-half-right {margin-right:15.5%;}
    .sm-margin-sixteen-right {margin-right:16%;}
    .sm-margin-sixteen-half-right {margin-right:16.5%;}
    .sm-margin-seventeen-right {margin-right:17%;}
    .sm-margin-seventeen-half-right {margin-right:17.5%;}
    .sm-margin-eighteen-right {margin-right:18%;}
    .sm-margin-eighteen-half-right {margin-right:18.5%;}
    .sm-margin-nineteen-right {margin-right:19%;}
    .sm-margin-nineteen-half-right {margin-right:19.5%;}
    .sm-margin-twenty-right {margin-right:20%;}
    .sm-margin-twenty-half-right {margin-right:20.5%;}
    .sm-margin-twenty-one-right {margin-right:21%;}
    .sm-margin-twenty-one-half-right {margin-right:21.5%;}
    .sm-margin-twenty-two-right {margin-right:22%;}
    .sm-margin-twenty-two-half-right {margin-right:22.5%;}
    .sm-margin-twenty-three-right {margin-right:23%;}
    .sm-margin-twenty-three-half-right {margin-right:23.5%;}
    .sm-margin-twenty-four-right {margin-right:24%;}
    .sm-margin-twenty-four-half-right {margin-right:24.5%;}
    .sm-margin-twenty-five-right {margin-right:25%;}
    .sm-margin-10px-right {margin-right:10px;}
    .sm-margin-15px-right {margin-right:15px;}
    .sm-margin-20px-right {margin-right:20px;}
    .sm-margin-25px-right {margin-right:25px;}
    .sm-margin-30px-right {margin-right:30px;}
    .sm-margin-35px-right {margin-right:35px;}
    .sm-margin-40px-right {margin-right:40px;}
    .sm-margin-45px-right {margin-right:45px;}
    .sm-margin-50px-right {margin-right:50px;}
    .sm-margin-55px-right {margin-right:55px;}
    .sm-margin-60px-right {margin-right:60px;}
    .sm-margin-65px-right {margin-right:65px;}
    .sm-margin-70px-right {margin-right:70px;}
    .sm-margin-75px-right {margin-right:75px;}
    .sm-margin-80px-right {margin-right:80px;}
    .sm-margin-85px-right {margin-right:85px;}
    .sm-margin-90px-right {margin-right:90px;}
    .sm-margin-95px-right {margin-right:95px;}
    .sm-margin-100px-right {margin-right:100px;}

    /* margin left */
    .sm-margin-one-left {margin-left:1%;}
    .sm-margin-one-half-left {margin-left:1.5%;}
    .sm-margin-two-left {margin-left:2%;}
    .sm-margin-two-half-left {margin-left:2.5%;}
    .sm-margin-three-left {margin-left:3%;}
    .sm-margin-three-half-left {margin-left:3.5%;}
    .sm-margin-four-left {margin-left:4%;}
    .sm-margin-four-half-left {margin-left:4.5%;}
    .sm-margin-five-left {margin-left:5%;}
    .sm-margin-five-half-left {margin-left:5.5%;}
    .sm-margin-six-left {margin-left:6%;}
    .sm-margin-six-half-left {margin-left:6.5%;}
    .sm-margin-seven-left {margin-left:7%;}
    .sm-margin-seven-half-left {margin-left:7.5%;}
    .sm-margin-eight-left {margin-left:8%;}
    .sm-margin-eight-half-left {margin-left:8.5%;}
    .sm-margin-nine-left {margin-left:9%;}
    .sm-margin-nine-half-left {margin-left:9.5%;}
    .sm-margin-ten-left {margin-left:10%;}
    .sm-margin-ten-half-left {margin-left:10.5%;}
    .sm-margin-eleven-left {margin-left:11%;}
    .sm-margin-eleven-half-left {margin-left:11.5%;}
    .sm-margin-twelve-left {margin-left:12%;}
    .sm-margin-twelve-half-left {margin-left:12.5%;}
    .sm-margin-thirteen-left {margin-left:13%;}
    .sm-margin-thirteen-half-left {margin-left:13.5%;}
    .sm-margin-fourteen-left {margin-left:14%;}
    .sm-margin-fourteen-half-left {margin-left:14.5%;}
    .sm-margin-fifteen-left {margin-left:15%;}
    .sm-margin-fifteen-half-left {margin-left:15.5%;}
    .sm-margin-sixteen-left {margin-left:16%;}
    .sm-margin-sixteen-half-left {margin-left:16.5%;}
    .sm-margin-seventeen-left {margin-left:17%;}
    .sm-margin-seventeen-half-left {margin-left:17.5%;}
    .sm-margin-eighteen-left {margin-left:18%;}
    .sm-margin-eighteen-half-left {margin-left:18.5%;}
    .sm-margin-nineteen-left {margin-left:19%;}
    .sm-margin-nineteen-half-left {margin-left:19.5%;}
    .sm-margin-twenty-left {margin-left:20%;}
    .sm-margin-twenty-half-left {margin-left:20.5%;}
    .sm-margin-twenty-one-left {margin-left:21%;}
    .sm-margin-twenty-one-half-left {margin-left:21.5%;}
    .sm-margin-twenty-two-left {margin-left:22%;}
    .sm-margin-twenty-two-half-left {margin-left:22.5%;}
    .sm-margin-twenty-three-left {margin-left:23%;}
    .sm-margin-twenty-three-half-left {margin-left:23.5%;}
    .sm-margin-twenty-four-left {margin-left:24%;}
    .sm-margin-twenty-four-half-left {margin-left:24.5%;}
    .sm-margin-twenty-five-left {margin-left:25%;}
    .sm-margin-5px-left {margin-left:5px;}
    .sm-margin-10px-left {margin-left:10px;}
    .sm-margin-15px-left {margin-left:15px;}
    .sm-margin-20px-left {margin-left:20px;}
    .sm-margin-25px-left {margin-left:25px;}
    .sm-margin-30px-left {margin-left:30px;}
    .sm-margin-35px-left {margin-left:35px;}
    .sm-margin-40px-left {margin-left:40px;}
    .sm-margin-45px-left {margin-left:45px;}
    .sm-margin-50px-left {margin-left:50px;}
    .sm-margin-55px-left {margin-left:55px;}
    .sm-margin-60px-left {margin-left:60px;}
    .sm-margin-65px-left {margin-left:65px;}
    .sm-margin-70px-left {margin-left:70px;}
    .sm-margin-75px-left {margin-left:75px;}
    .sm-margin-80px-left {margin-left:80px;}
    .sm-margin-85px-left {margin-left:85px;}
    .sm-margin-90px-left {margin-left:90px;}
    .sm-margin-95px-left {margin-left:95px;}
    .sm-margin-100px-left {margin-left:100px;}

    /* margin left right */
    .sm-margin-one-lr {margin-left:1%; margin-right:1%;}
    .sm-margin-one-lr {margin-left:1.5%; margin-right:1.5%;}
    .sm-margin-two-lr {margin-left:2%; margin-right:2%;}
    .sm-margin-two-lr {margin-left:2.5%; margin-right:2.5%;}
    .sm-margin-three-lr {margin-left:3%; margin-right:3%;}
    .sm-margin-three-lr {margin-left:3.5%; margin-right:3.5%;}
    .sm-margin-four-lr {margin-left:4%; margin-right:4%;}
    .sm-margin-four-lr {margin-left:4.5%; margin-right:4.5%;}
    .sm-margin-five-lr {margin-left:5%; margin-right:5%;}
    .sm-margin-five-lr {margin-left:5.5%; margin-right:5.5%;}
    .sm-margin-six-lr {margin-left:6%; margin-right:6%;}
    .sm-margin-six-lr {margin-left:6.5%; margin-right:6.5%;}
    .sm-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .sm-margin-seven-lr {margin-left:7.5%; margin-right:7.5%;}
    .sm-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .sm-margin-eight-lr {margin-left:8.5%; margin-right:8.5%;}
    .sm-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .sm-margin-nine-lr {margin-left:9.5%; margin-right:9.5%;}
    .sm-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .sm-margin-ten-lr {margin-left:10.5%; margin-right:10.5%;}
    .sm-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .sm-margin-eleven-lr {margin-left:11.5%; margin-right:11.5%;}
    .sm-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .sm-margin-twelve-lr {margin-left:12.5%; margin-right:12.5%;}
    .sm-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .sm-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .sm-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .sm-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .sm-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .sm-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .sm-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .sm-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .sm-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .sm-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .sm-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .sm-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .sm-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .sm-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .sm-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .sm-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .sm-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .sm-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .sm-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .sm-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .sm-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .sm-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .sm-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .sm-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .sm-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .sm-margin-5px-lr {margin-left:5px; margin-right:5px;}
    .sm-margin-10px-lr {margin-left:10px; margin-right:10px;}
    .sm-margin-15px-lr {margin-left:15px; margin-right:15px;}
    .sm-margin-20px-lr {margin-left:20px; margin-right:20px;}
    .sm-margin-25px-lr {margin-left:25px; margin-right:25px;}
    .sm-margin-30px-lr {margin-left:30px; margin-right:30px;}
    .sm-margin-35px-lr {margin-left:35px; margin-right:35px;}
    .sm-margin-40px-lr {margin-left:40px; margin-right:40px;}
    .sm-margin-45px-lr {margin-left:45px; margin-right:45px;}
    .sm-margin-50px-lr {margin-left:50px; margin-right:50px;}
    .sm-margin-55px-lr {margin-left:55px; margin-right:55px;}
    .sm-margin-60px-lr {margin-left:60px; margin-right:60px;}
    .sm-margin-65px-lr {margin-left:65px; margin-right:65px;}
    .sm-margin-70px-lr {margin-left:70px; margin-right:70px;}
    .sm-margin-75px-lr {margin-left:75px; margin-right:75px;}
    .sm-margin-80px-lr {margin-left:80px; margin-right:80px;}
    .sm-margin-85px-lr {margin-left:85px; margin-right:85px;}
    .sm-margin-90px-lr {margin-left:90px; margin-right:90px;}
    .sm-margin-95px-lr {margin-left:95px; margin-right:95px;}
    .sm-margin-100px-lr {margin-left:100px; margin-right:100px;}

    /* margin top bottom */
    .sm-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .sm-margin-one-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .sm-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .sm-margin-two-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .sm-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .sm-margin-three-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .sm-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .sm-margin-four-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .sm-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .sm-margin-five-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .sm-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .sm-margin-six-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .sm-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .sm-margin-seven-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .sm-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .sm-margin-eight-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .sm-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .sm-margin-nine-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .sm-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .sm-margin-ten-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .sm-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .sm-margin-eleven-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .sm-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .sm-margin-twelve-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .sm-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .sm-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .sm-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .sm-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .sm-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .sm-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .sm-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .sm-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .sm-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .sm-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .sm-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .sm-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .sm-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .sm-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .sm-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .sm-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .sm-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .sm-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .sm-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .sm-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .sm-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .sm-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .sm-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .sm-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .sm-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .sm-margin-5px-tb {margin-top:5px; margin-bottom:5px;}
    .sm-margin-10px-tb {margin-top:10px; margin-bottom:10px;}
    .sm-margin-15px-tb {margin-top:15px; margin-bottom:15px;}
    .sm-margin-20px-tb {margin-top:20px; margin-bottom:20px;}
    .sm-margin-25px-tb {margin-top:25px; margin-bottom:25px;}
    .sm-margin-30px-tb {margin-top:30px; margin-bottom:30px;}
    .sm-margin-35px-tb {margin-top:35px; margin-bottom:35px;}
    .sm-margin-40px-tb {margin-top:40px; margin-bottom:40px;}
    .sm-margin-45px-tb {margin-top:45px; margin-bottom:45px;}
    .sm-margin-50px-tb {margin-top:50px; margin-bottom:50px;}
    .sm-margin-55px-tb {margin-top:55px; margin-bottom:55px;}
    .sm-margin-60px-tb {margin-top:60px; margin-bottom:60px;}
    .sm-margin-65px-tb {margin-top:65px; margin-bottom:65px;}
    .sm-margin-70px-tb {margin-top:70px; margin-bottom:70px;}
    .sm-margin-75px-tb {margin-top:75px; margin-bottom:75px;}
    .sm-margin-80px-tb {margin-top:80px; margin-bottom:80px;}
    .sm-margin-85px-tb {margin-top:85px; margin-bottom:85px;}
    .sm-margin-90px-tb {margin-top:90px; margin-bottom:90px;}
    .sm-margin-95px-tb {margin-top:95px; margin-bottom:95px;}
    .sm-margin-100px-tb {margin-top:100px; margin-bottom:100px;}

    /* header padding top */
    .sm-header-padding-top { padding-top: 82px;}
    .sm-header-margin-top { margin-top: 80px;}

    /* padding */
    .sm-no-padding {padding:0 !important}
    .sm-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important}
    .sm-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important}
    .sm-no-padding-top {padding-top:0 !important}
    .sm-no-padding-bottom {padding-bottom:0 !important}
    .sm-no-padding-left {padding-left:0 !important}
    .sm-no-padding-right {padding-right:0 !important}
    .sm-padding-one-all {padding:1%;}
    .sm-padding-one-half-all {padding:1.5%;}
    .sm-padding-two-all {padding:2%;}
    .sm-padding-two-half-all {padding:2.5%;}
    .sm-padding-three-all {padding:3%;}
    .sm-padding-three-half-all {padding:3.5%;}
    .sm-padding-four-all {padding:4%;}
    .sm-padding-four-half-all {padding:4.5%;}
    .sm-padding-five-all {padding:5%;}
    .sm-padding-five-half-all {padding:5.5%;}
    .sm-padding-six-all {padding:6%;}
    .sm-padding-six-half-all {padding:6.5%;}
    .sm-padding-seven-all {padding:7%;}
    .sm-padding-seven-half-all {padding:7.5%;}
    .sm-padding-eight-all {padding:8%;}
    .sm-padding-eight-half-all {padding:8.5%;}
    .sm-padding-nine-all {padding:9%;}
    .sm-padding-nine-half-all {padding:9.5%;}
    .sm-padding-ten-all {padding:10%;}
    .sm-padding-ten-half-all {padding:10.5%;}
    .sm-padding-eleven-all {padding:11%;}
    .sm-padding-eleven-half-all {padding:11.5%;}
    .sm-padding-twelve-all {padding:12%;}
    .sm-padding-twelve-half-all {padding:12.5%;}
    .sm-padding-thirteen-all {padding:13%;}
    .sm-padding-thirteen-half-all {padding:13.5%;}
    .sm-padding-fourteen-all {padding:14%;}
    .sm-padding-fourteen-half-all {padding:14.5%;}
    .sm-padding-fifteen-all {padding:15%;}
    .sm-padding-fifteen-half-all {padding:15.5%;}
    .sm-padding-sixteen-all {padding:16%;}
    .sm-padding-sixteen-half-all {padding:16.5%;}
    .sm-padding-seventeen-all {padding:17%;}
    .sm-padding-seventeen-half-all {padding:17.5%;}
    .sm-padding-eighteen-all {padding:18%;}
    .sm-padding-eighteen-half-all {padding:18.5%;}
    .sm-padding-nineteen-all {padding:19%;}
    .sm-padding-nineteen-half-all {padding:19.5%;}
    .sm-padding-twenty-all {padding:20%;}
    .sm-padding-twenty-half-all {padding:20.5%;}
    .sm-padding-twenty-one-all {padding:21%;}
    .sm-padding-twenty-one-half-all {padding:21.5%;}
    .sm-padding-twenty-two-all {padding:22%;}
    .sm-padding-twenty-two-half-all {padding:22.5%;}
    .sm-padding-twenty-three-all {padding:23%;}
    .sm-padding-twenty-three-half-all {padding:23.5%;}
    .sm-padding-twenty-four-all {padding:24%;}
    .sm-padding-twenty-four-half-all {padding:24.5%;}
    .sm-padding-twenty-five-all {padding:25%;}
    .sm-padding-5px-all {padding:5px;}
    .sm-padding-10px-all {padding:10px;}
    .sm-padding-15px-all {padding:15px;}
    .sm-padding-20px-all {padding:20px;}
    .sm-padding-25px-all {padding:25px;}
    .sm-padding-30px-all {padding:30px;}
    .sm-padding-35px-all {padding:35px;}
    .sm-padding-40px-all {padding:40px;}
    .sm-padding-45px-all {padding:45px;}
    .sm-padding-50px-all {padding:50px;}
    .sm-padding-55px-all {padding:55px;}
    .sm-padding-60px-all {padding:60px;}
    .sm-padding-65px-all {padding:65px;}
    .sm-padding-70px-all {padding:70px;}
    .sm-padding-75px-all {padding:75px;}
    .sm-padding-80px-all {padding:80px;}
    .sm-padding-85px-all {padding:85px;}
    .sm-padding-90px-all {padding:90px;}
    .sm-padding-95px-all {padding:95px;}
    .sm-padding-100px-all {padding:100px;}

    /* padding top */
    .sm-padding-one-top {padding-top:1%;}
    .sm-padding-one-half-top {padding-top:1.5%;}
    .sm-padding-two-top {padding-top:2%;}
    .sm-padding-two-half-top {padding-top:2.5%;}
    .sm-padding-three-top {padding-top:3%;}
    .sm-padding-three-half-top {padding-top:3.5%;}
    .sm-padding-four-top {padding-top:4%;}
    .sm-padding-four-half-top {padding-top:4.5%;}
    .sm-padding-five-top {padding-top:5%;}
    .sm-padding-five-half-top {padding-top:5.5%;}
    .sm-padding-six-top {padding-top:6%;}
    .sm-padding-six-half-top {padding-top:6.5%;}
    .sm-padding-seven-top {padding-top:7%;}
    .sm-padding-seven-half-top {padding-top:7.5%;}
    .sm-padding-eight-top {padding-top:8%;}
    .sm-padding-eight-half-top {padding-top:8.5%;}
    .sm-padding-nine-top {padding-top:9%;}
    .sm-padding-nine-half-top {padding-top:9.5%;}
    .sm-padding-ten-top {padding-top:10%;}
    .sm-padding-ten-half-top {padding-top:10.5%;}
    .sm-padding-eleven-top {padding-top:11%;}
    .sm-padding-eleven-half-top {padding-top:11.5%;}
    .sm-padding-twelve-top {padding-top:12%;}
    .sm-padding-twelve-half-top {padding-top:12.5%;}
    .sm-padding-thirteen-top {padding-top:13%;}
    .sm-padding-thirteen-half-top {padding-top:13.5%;}
    .sm-padding-fourteen-top {padding-top:14%;}
    .sm-padding-fourteen-half-top {padding-top:14.5%;}
    .sm-padding-fifteen-top {padding-top:15%;}
    .sm-padding-fifteen-half-top {padding-top:15.5%;}
    .sm-padding-sixteen-top {padding-top:16%;}
    .sm-padding-sixteen-half-top {padding-top:16.5%;}
    .sm-padding-seventeen-top {padding-top:17%;}
    .sm-padding-seventeen-half-top {padding-top:17.5%;}
    .sm-padding-eighteen-top {padding-top:18%;}
    .sm-padding-eighteen-half-top {padding-top:18.5%;}
    .sm-padding-nineteen-top {padding-top:19%;}
    .sm-padding-nineteen-half-top {padding-top:19.5%;}
    .sm-padding-twenty-top {padding-top:20%;}
    .sm-padding-twenty-half-top {padding-top:20.5%;}
    .sm-padding-twenty-one-top {padding-top:21%;}
    .sm-padding-twenty-one-half-top {padding-top:21.5%;}
    .sm-padding-twenty-two-top {padding-top:22%;}
    .sm-padding-twenty-two-half-top {padding-top:22.5%;}
    .sm-padding-twenty-three-top {padding-top:23%;}
    .sm-padding-twenty-three-half-top {padding-top:23.5%;}
    .sm-padding-twenty-four-top {padding-top:24%;}
    .sm-padding-twenty-four-half-top {padding-top:24.5%;}
    .sm-padding-twenty-five-top {padding-top:25%;}
    .sm-padding-5px-top {padding-top:5px;}
    .sm-padding-10px-top {padding-top:10px;}
    .sm-padding-15px-top {padding-top:15px;}
    .sm-padding-20px-top {padding-top:20px !important;}
    .sm-padding-25px-top {padding-top:25px;}
    .sm-padding-30px-top {padding-top:30px;}
    .sm-padding-35px-top {padding-top:35px;}
    .sm-padding-40px-top {padding-top:40px;}
    .sm-padding-45px-top {padding-top:45px;}
    .sm-padding-50px-top {padding-top:50px !important;}
    .sm-padding-55px-top {padding-top:55px;}
    .sm-padding-60px-top {padding-top:60px;}
    .sm-padding-65px-top {padding-top:65px;}
    .sm-padding-70px-top {padding-top:70px;}
    .sm-padding-75px-top {padding-top:75px;}
    .sm-padding-80px-top {padding-top:80px;}
    .sm-padding-85px-top {padding-top:85px;}
    .sm-padding-90px-top {padding-top:90px;}
    .sm-padding-95px-top {padding-top:95px;}
    .sm-padding-100px-top {padding-top:100px;}
    .sm-padding-120px-top {padding-top:120px !important;}


    /* padding bottom */
    .sm-padding-one-bottom {padding-bottom:1%;}
    .sm-padding-one-half-bottom {padding-bottom:1.5%;}
    .sm-padding-two-bottom {padding-bottom:2%;}
    .sm-padding-two-half-bottom {padding-bottom:2.5%;}
    .sm-padding-three-bottom {padding-bottom:3%;}
    .sm-padding-three-half-bottom {padding-bottom:3.5%;}
    .sm-padding-four-bottom {padding-bottom:4%;}
    .sm-padding-four-half-bottom {padding-bottom:4.5%;}
    .sm-padding-five-bottom {padding-bottom:5%;}
    .sm-padding-five-half-bottom {padding-bottom:5.5%;}
    .sm-padding-six-bottom {padding-bottom:6%;}
    .sm-padding-six-half-bottom {padding-bottom:6.5%;}
    .sm-padding-seven-bottom {padding-bottom:7%;}
    .sm-padding-seven-half-bottom {padding-bottom:7.5%;}
    .sm-padding-eight-bottom {padding-bottom:8%;}
    .sm-padding-eight-half-bottom {padding-bottom:8.5%;}
    .sm-padding-nine-bottom {padding-bottom:9%;}
    .sm-padding-nine-half-bottom {padding-bottom:9.5%;}
    .sm-padding-ten-bottom {padding-bottom:10%;}
    .sm-padding-ten-half-bottom {padding-bottom:10.5%;}
    .sm-padding-eleven-bottom {padding-bottom:11%;}
    .sm-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .sm-padding-twelve-bottom {padding-bottom:12%;}
    .sm-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .sm-padding-thirteen-bottom {padding-bottom:13%;}
    .sm-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .sm-padding-fourteen-bottom {padding-bottom:14%;}
    .sm-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .sm-padding-fifteen-bottom {padding-bottom:15%;}
    .sm-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .sm-padding-sixteen-bottom {padding-bottom:16%;}
    .sm-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .sm-padding-seventeen-bottom {padding-bottom:17%;}
    .sm-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .sm-padding-eighteen-bottom {padding-bottom:18%;}
    .sm-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .sm-padding-nineteen-bottom {padding-bottom:19%;}
    .sm-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .sm-padding-twenty-bottom {padding-bottom:20%;}
    .sm-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .sm-padding-twenty-one-bottom {padding-bottom:21%;}
    .sm-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .sm-padding-twenty-two-bottom {padding-bottom:22%;}
    .sm-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .sm-padding-twenty-three-bottom {padding-bottom:23%;}
    .sm-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .sm-padding-twenty-four-bottom {padding-bottom:24%;}
    .sm-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .sm-padding-twenty-five-bottom {padding-bottom:25%;}
    .sm-padding-5px-bottom {padding-bottom:5px;}
    .sm-padding-10px-bottom {padding-bottom:10px;}
    .sm-padding-15px-bottom {padding-bottom:15px;}
    .sm-padding-20px-bottom {padding-bottom:20px !important;}
    .sm-padding-25px-bottom {padding-bottom:25px;}
    .sm-padding-30px-bottom {padding-bottom:30px;}
    .sm-padding-35px-bottom {padding-bottom:35px;}
    .sm-padding-40px-bottom {padding-bottom:40px;}
    .sm-padding-45px-bottom {padding-bottom:45px;}
    .sm-padding-50px-bottom {padding-bottom:50px;}
    .sm-padding-55px-bottom {padding-bottom:55px;}
    .sm-padding-60px-bottom {padding-bottom:60px;}
    .sm-padding-65px-bottom {padding-bottom:65px;}
    .sm-padding-70px-bottom {padding-bottom:70px;}
    .sm-padding-75px-bottom {padding-bottom:75px;}
    .sm-padding-80px-bottom {padding-bottom:80px;}
    .sm-padding-85px-bottom {padding-bottom:85px;}
    .sm-padding-90px-bottom {padding-bottom:90px;}
    .sm-padding-95px-bottom {padding-bottom:95px;}
    .sm-padding-100px-bottom {padding-bottom:100px;}

    /* padding right */
    .sm-padding-one-right {padding-right:1%;}
    .sm-padding-one-half-right {padding-right:1.5%;}
    .sm-padding-two-right {padding-right:2%;}
    .sm-padding-two-half-right {padding-right:2.5%;}
    .sm-padding-three-right {padding-right:3%;}
    .sm-padding-three-half-right {padding-right:3.5%;}
    .sm-padding-four-right {padding-right:4%;}
    .sm-padding-four-half-right {padding-right:4.5%;}
    .sm-padding-five-right {padding-right:5%;}
    .sm-padding-five-half-right {padding-right:5.5%;}
    .sm-padding-six-right {padding-right:6%;}
    .sm-padding-six-half-right {padding-right:6.5%;}
    .sm-padding-seven-right {padding-right:7%;}
    .sm-padding-seven-half-right {padding-right:7.5%;}
    .sm-padding-eight-right {padding-right:8%;}
    .sm-padding-eight-half-right {padding-right:8.5%;}
    .sm-padding-nine-right {padding-right:9%;}
    .sm-padding-nine-half-right {padding-right:9.5%;}
    .sm-padding-ten-right {padding-right:10%;}
    .sm-padding-ten-half-right {padding-right:10.5%;}
    .sm-padding-eleven-right {padding-right:11%;}
    .sm-padding-eleven-half-right {padding-right:11.5%;}
    .sm-padding-twelve-right {padding-right:12%;}
    .sm-padding-twelve-half-right {padding-right:12.5%;}
    .sm-padding-thirteen-right {padding-right:13%;}
    .sm-padding-thirteen-half-right {padding-right:13.5%;}
    .sm-padding-fourteen-right {padding-right:14%;}
    .sm-padding-fourteen-half-right {padding-right:14.5%;}
    .sm-padding-fifteen-right {padding-right:15%;}
    .sm-padding-fifteen-half-right {padding-right:15.5%;}
    .sm-padding-sixteen-right {padding-right:16%;}
    .sm-padding-sixteen-half-right {padding-right:16.5%;}
    .sm-padding-seventeen-right {padding-right:17%;}
    .sm-padding-seventeen-half-right {padding-right:17.5%;}
    .sm-padding-eighteen-right {padding-right:18%;}
    .sm-padding-eighteen-half-right {padding-right:18.5%;}
    .sm-padding-nineteen-right {padding-right:19%;}
    .sm-padding-nineteen-half-right {padding-right:19.5%;}
    .sm-padding-twenty-right {padding-right:20%;}
    .sm-padding-twenty-hlaf-right {padding-right:20.5%;}
    .sm-padding-twenty-one-right {padding-right:21%;}
    .sm-padding-twenty-one-hlaf-right {padding-right:21.5%;}
    .sm-padding-twenty-two-right {padding-right:22%;}
    .sm-padding-twenty-two-hlaf-right {padding-right:22.5%;}
    .sm-padding-twenty-three-right {padding-right:23%;}
    .sm-padding-twenty-three-hlaf-right {padding-right:23.5%;}
    .sm-padding-twenty-four-right {padding-right:24%;}
    .sm-padding-twenty-four-hlaf-right {padding-right:24.5%;}
    .sm-padding-twenty-five-right {padding-right:25%;}
    .sm-padding-5px-right {padding-right:5px;}
    .sm-padding-10px-right {padding-right:10px;}
    .sm-padding-15px-right {padding-right:15px;}
    .sm-padding-20px-right {padding-right:20px;}
    .sm-padding-25px-right {padding-right:25px;}
    .sm-padding-30px-right {padding-right:30px;}
    .sm-padding-35px-right {padding-right:35px;}
    .sm-padding-40px-right {padding-right:40px;}
    .sm-padding-45px-right {padding-right:45px;}
    .sm-padding-50px-right {padding-right:50px;}
    .sm-padding-55px-right {padding-right:55px;}
    .sm-padding-60px-right {padding-right:60px;}
    .sm-padding-65px-right {padding-right:65px;}
    .sm-padding-70px-right {padding-right:70px;}
    .sm-padding-75px-right {padding-right:75px;}
    .sm-padding-80px-right {padding-right:80px;}
    .sm-padding-85px-right {padding-right:85px;}
    .sm-padding-90px-right {padding-right:90px;}
    .sm-padding-95px-right {padding-right:95px;}
    .sm-padding-100px-right {padding-right:100px;}

    /* padding left */
    .sm-padding-one-left {padding-left:1%;}
    .sm-padding-one-half-left {padding-left:1.5%;}
    .sm-padding-two-left {padding-left:2%;}
    .sm-padding-two-half-left {padding-left:2.5%;}
    .sm-padding-three-left {padding-left:3%;}
    .sm-padding-three-half-left {padding-left:3.5%;}
    .sm-padding-four-left {padding-left:4%;}
    .sm-padding-four-half-left {padding-left:4.5%;}
    .sm-padding-five-left {padding-left:5%;}
    .sm-padding-five-half-left {padding-left:5.5%;}
    .sm-padding-six-left {padding-left:6%;}
    .sm-padding-six-half-left {padding-left:6.5%;}
    .sm-padding-seven-left {padding-left:7%;}
    .sm-padding-seven-half-left {padding-left:7.5%;}
    .sm-padding-eight-left {padding-left:8%;}
    .sm-padding-eight-half-left {padding-left:8.5%;}
    .sm-padding-nine-left {padding-left:9%;}
    .sm-padding-nine-half-left {padding-left:9.5%;}
    .sm-padding-ten-left {padding-left:10%;}
    .sm-padding-ten-half-left {padding-left:10.5%;}
    .sm-padding-eleven-left {padding-left:11%;}
    .sm-padding-eleven-half-left {padding-left:11.5%;}
    .sm-padding-twelve-left {padding-left:12%;}
    .sm-padding-twelve-half-left {padding-left:12.5%;}
    .sm-padding-thirteen-left {padding-left:13%;}
    .sm-padding-thirteen-half-left {padding-left:13.5%;}
    .sm-padding-fourteen-left {padding-left:14%;}
    .sm-padding-fourteen-half-left {padding-left:14.5%;}
    .sm-padding-fifteen-left {padding-left:15%;}
    .sm-padding-fifteen-half-left {padding-left:15.5%;}
    .sm-padding-sixteen-left {padding-left:16%;}
    .sm-padding-sixteen-half-left {padding-left:16.5%;}
    .sm-padding-seventeen-left {padding-left:17%;}
    .sm-padding-seventeen-half-left {padding-left:17.5%;}
    .sm-padding-eighteen-left {padding-left:18%;}
    .sm-padding-eighteen-half-left {padding-left:18.5%;}
    .sm-padding-nineteen-left {padding-left:19%;}
    .sm-padding-nineteen-half-left {padding-left:19.5%;}
    .sm-padding-twenty-left {padding-left:20%;}
    .sm-padding-twenty-half-left {padding-left:20.5%;}
    .sm-padding-twenty-one-left {padding-left:21%;}
    .sm-padding-twenty-one-half-left {padding-left:21.5%;}
    .sm-padding-twenty-two-left {padding-left:22%;}
    .sm-padding-twenty-two-half-left {padding-left:22.5%;}
    .sm-padding-twenty-three-left {padding-left:23%;}
    .sm-padding-twenty-three-half-left {padding-left:23.5%;}
    .sm-padding-twenty-four-left {padding-left:24%;}
    .sm-padding-twenty-four-half-left {padding-left:24.5%;}
    .sm-padding-twenty-five-left {padding-left:25%;}
    .sm-padding-5px-left {padding-left:5px;}
    .sm-padding-10px-left {padding-left:10px;}
    .sm-padding-15px-left {padding-left:15px;}
    .sm-padding-20px-left {padding-left:20px;}
    .sm-padding-25px-left {padding-left:25px;}
    .sm-padding-30px-left {padding-left:30px;}
    .sm-padding-35px-left {padding-left:35px;}
    .sm-padding-40px-left {padding-left:40px;}
    .sm-padding-45px-left {padding-left:45px;}
    .sm-padding-50px-left {padding-left:50px;}
    .sm-padding-55px-left {padding-left:55px;}
    .sm-padding-60px-left {padding-left:60px;}
    .sm-padding-65px-left {padding-left:65px;}
    .sm-padding-70px-left {padding-left:70px;}
    .sm-padding-75px-left {padding-left:75px;}
    .sm-padding-80px-left {padding-left:80px;}
    .sm-padding-85px-left {padding-left:85px;}
    .sm-padding-90px-left {padding-left:90px;}
    .sm-padding-95px-left {padding-left:95px;}
    .sm-padding-100px-left {padding-left:100px;}

    /* padding top bottom */
    .sm-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .sm-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .sm-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .sm-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .sm-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .sm-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .sm-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .sm-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .sm-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .sm-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .sm-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .sm-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .sm-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .sm-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .sm-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .sm-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .sm-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .sm-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .sm-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .sm-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .sm-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .sm-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .sm-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .sm-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .sm-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .sm-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .sm-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .sm-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .sm-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .sm-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .sm-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .sm-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .sm-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .sm-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .sm-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .sm-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .sm-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .sm-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .sm-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .sm-padding-twenty-one-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .sm-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .sm-padding-twenty-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .sm-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .sm-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .sm-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .sm-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .sm-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .sm-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .sm-padding-twenty-five-tb {padding-top:25%; padding-bottom:25%;}
    .sm-padding-5px-tb {padding-top:5px; padding-bottom:5px;}
    .sm-padding-10px-tb {padding-top:10px; padding-bottom:10px;}
    .sm-padding-15px-tb {padding-top:15px; padding-bottom:15px;}
    .sm-padding-20px-tb {padding-top:20px; padding-bottom:20px;}
    .sm-padding-25px-tb {padding-top:25px; padding-bottom:25px;}
    .sm-padding-30px-tb {padding-top:30px; padding-bottom:30px;}
    .sm-padding-35px-tb {padding-top:35px; padding-bottom:35px;}
    .sm-padding-40px-tb {padding-top:40px; padding-bottom:40px;}
    .sm-padding-45px-tb {padding-top:45px; padding-bottom:45px;}
    .sm-padding-50px-tb {padding-top:50px; padding-bottom:50px;}
    .sm-padding-55px-tb {padding-top:55px; padding-bottom:55px;}
    .sm-padding-60px-tb {padding-top:60px; padding-bottom:60px;}
    .sm-padding-65px-tb {padding-top:65px; padding-bottom:65px;}
    .sm-padding-70px-tb {padding-top:70px; padding-bottom:70px;}
    .sm-padding-75px-tb {padding-top:75px; padding-bottom:75px;}
    .sm-padding-80px-tb {padding-top:80px; padding-bottom:80px;}
    .sm-padding-85px-tb {padding-top:85px; padding-bottom:85px;}
    .sm-padding-90px-tb {padding-top:90px; padding-bottom:90px;}
    .sm-padding-95px-tb {padding-top:95px; padding-bottom:95px;}
    .sm-padding-100px-tb {padding-top:100px; padding-bottom:100px;}

    /* padding left right */
    .sm-padding-one-lr {padding-left:1%; padding-right:1%;}
    .sm-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .sm-padding-two-lr {padding-left:2%; padding-right:2%;}
    .sm-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .sm-padding-three-lr {padding-left:3%; padding-right:3%;}
    .sm-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .sm-padding-four-lr {padding-left:4%; padding-right:4%;}
    .sm-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .sm-padding-five-lr {padding-left:5%; padding-right:5%;}
    .sm-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .sm-padding-six-lr {padding-left:6%; padding-right:6%;}
    .sm-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .sm-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .sm-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .sm-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .sm-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .sm-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .sm-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .sm-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .sm-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .sm-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .sm-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .sm-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .sm-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .sm-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .sm-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .sm-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .sm-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .sm-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .sm-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .sm-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .sm-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .sm-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .sm-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .sm-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .sm-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .sm-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .sm-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .sm-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .sm-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .sm-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .sm-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .sm-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .sm-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .sm-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .sm-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .sm-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .sm-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .sm-padding-twenty-lr {padding-left:25%; padding-right:25%;}
    .sm-padding-5px-lr {padding-left:5px; padding-right:5px;}
    .sm-padding-10px-lr {padding-left:10px; padding-right:10px;}
    .sm-padding-15px-lr {padding-left:15px; padding-right:15px;}
    .sm-padding-20px-lr {padding-left:20px; padding-right:20px;}
    .sm-padding-25px-lr {padding-left:25px; padding-right:25px;}
    .sm-padding-30px-lr {padding-left:30px; padding-right:30px;}
    .sm-padding-35px-lr {padding-left:35px; padding-right:35px;}
    .sm-padding-40px-lr {padding-left:40px; padding-right:40px;}
    .sm-padding-45px-lr {padding-left:45px; padding-right:45px;}
    .sm-padding-50px-lr {padding-left:50px; padding-right:50px;}
    .sm-padding-55px-lr {padding-left:55px; padding-right:55px;}
    .sm-padding-60px-lr {padding-left:60px; padding-right:60px;}
    .sm-padding-65px-lr {padding-left:65px; padding-right:65px;}
    .sm-padding-70px-lr {padding-left:70px; padding-right:70px;}
    .sm-padding-75px-lr {padding-left:75px; padding-right:75px;}
    .sm-padding-80px-lr {padding-left:80px; padding-right:80px;}
    .sm-padding-85px-lr {padding-left:85px; padding-right:85px;}
    .sm-padding-90px-lr {padding-left:90px; padding-right:90px;}
    .sm-padding-95px-lr {padding-left:95px; padding-right:95px;}
    .sm-padding-100px-lr {padding-left:100px; padding-right:100px;}

    /* col height  */
    .one-fifth-screen {height:600px;}
    .one-fourth-screen {height:500px;}
    .one-third-screen {height:350px;}
    .one-second-screen {height:300px;}
    .extra-small-screen {height:250px;}

    /* top */
    .sm-top-auto {top:auto}
    .sm-top-inherit {top: inherit !important;}
    .sm-top-0 {top:0 !important}
    .sm-top-1 {top:1px}
    .sm-top-2 {top:2px}
    .sm-top-3 {top:3px}
    .sm-top-4 {top:4px}
    .sm-top-5 {top:5px}
    .sm-top-6 {top:6px}
    .sm-top-7 {top:7px}
    .sm-top-8 {top:8px}
    .sm-top-9 {top:9px}
    .sm-top-10 {top:10px}
    .sm-top-12 {top:12px}
    .sm-top-15 {top:15px}
    .sm-top-17 {top:17px}
    .sm-top-20 {top:20px}
    .sm-top-25 {top:25px}
    .sm-top-30 {top:30px}
    .sm-top-35 {top:35px}
    .sm-top-40 {top:40px}
    .sm-top-45 {top:45px}
    .sm-top-50 {top:50px}
    .sm-top-minus1 {top:-1px}
    .sm-top-minus2 {top:-2px}
    .sm-top-minus3 {top:-3px}
    .sm-top-minus4 {top:-4px}
    .sm-top-minus5 {top:-5px}
    .sm-top-minus6 {top:-6px}
    .sm-top-minus7 {top:-7px}
    .sm-top-minus8 {top:-8px}
    .sm-top-minus9 {top:-9px}
    .sm-top-minus10 {top:-10px}
    .sm-top-minus15 {top:-15px}
    .sm-top-minus20 {top:-20px}
    .sm-top-minus25 {top:-25px}
    .sm-top-minus30 {top:-30px}
    .sm-top-minus35 {top:-35px}
    .sm-top-minus40 {top:-40px}
    .sm-top-minus45 {top:-45px}
    .sm-top-minus50 {top:-50px}

    /* bottom */
    .sm-bottom-auto {bottom:auto}
    .sm-bottom-0 {bottom:0}
    .sm-bottom-1 {bottom:1px}
    .sm-bottom-2 {bottom:2px}
    .sm-bottom-3 {bottom:3px}
    .sm-bottom-4 {bottom:4px}
    .sm-bottom-5 {bottom:5px}
    .sm-bottom-6 {bottom:6px}
    .sm-bottom-7 {bottom:7px}
    .sm-bottom-8 {bottom:8px}
    .sm-bottom-9 {bottom:9px}
    .sm-bottom-10 {bottom:10px}
    .sm-bottom-20 {bottom:20px}
    .sm-bottom-25 {bottom:25px}
    .sm-bottom-30 {bottom:30px}
    .sm-bottom-35 {bottom:35px}
    .sm-bottom-40 {bottom:40px}
    .sm-bottom-45 {bottom:45px}
    .sm-bottom-50 {bottom:50px}
    .sm-bottom-minus30 {bottom:-30px}
    .sm-bottom-minus50 {bottom:-50px}
    .sm-bottom-minus55 { bottom: -55px; }
    .sm-bottom-minus60 { bottom: -60px; }

    /* left */
    .sm-left-auto {left:auto}
    .sm-left-0 {left:0}
    .sm-left-1 {left:1px}
    .sm-left-2 {left:2px}
    .sm-left-3 {left:3px}
    .sm-left-4 {left:4px}
    .sm-left-5 {left:5px}
    .sm-left-6 {left:6px}
    .sm-left-7 {left:7px}
    .sm-left-8 {left:8px}
    .sm-left-9 {left:9px}
    .sm-left-10 {left:10px}

    /* right */
    .sm-right-auto {right:auto}
    .sm-right-0 {right:0}
    .sm-right-1 {right:1px}
    .sm-right-2 {right:2px}
    .sm-right-3 {right:3px}
    .sm-right-4 {right:4px}
    .sm-right-5 {right:5px}
    .sm-right-6 {right:6px}
    .sm-right-7 {right:7px}
    .sm-right-8 {right:8px}
    .sm-right-9 {right:9px}
    .sm-right-10 {right:10px}

    /* width */
    .sm-w-10 {width:10% !important;}
    .sm-w-15 {width:15% !important;}
    .sm-w-20 {width:20% !important;}
    .sm-w-25 {width:25% !important;}
    .sm-w-30 {width:30% !important;}
    .sm-w-35 {width:35% !important;}
    .sm-w-40 {width:40% !important;}
    .sm-w-45 {width:45% !important;}
    .sm-w-50 {width:50% !important;}
    .sm-w-55 {width:55% !important;}
    .sm-w-60 {width:60% !important;}
    .sm-w-65 {width:65% !important;}
    .sm-w-70 {width:70% !important;}
    .sm-w-75 {width:75% !important;}
    .sm-w-80 {width:80% !important;}
    .sm-w-85 {width:85% !important;}
    .sm-w-90 {width:90% !important;}
    .sm-w-95 {width:95% !important;}
    .sm-w-100 {width:100% !important;}
    .sm-w-auto {width: auto !important}

    .sm-w-50px {width:50px !important;}
    .sm-w-70px {width:70px !important;}
    .sm-w-75px {width:75px !important;}
    .sm-w-80px {width:80px !important;}
    .sm-w-85px {width:85px !important;}
    .sm-w-90px {width:90px !important;}
    .sm-w-95px {width:95px !important;}
    .sm-w-100px {width:100px !important;}
    .sm-w-120px {width:120px !important;}
    .sm-w-130px {width:130px !important;}
    .sm-w-150px {width:150px !important;}
    .sm-w-180px {width:180px !important;}
    .sm-w-200px {width:200px !important;}
    .sm-w-250px {width:250px !important;}
    .sm-w-300px {width:300px !important;}
    .sm-w-350px {width:350px !important;}
    .sm-w-400px {width:400px !important;}
    .sm-w-450px {width:450px !important;}
    .sm-w-500px {width:500px !important;}
    .sm-w-550px {width:550px !important;}
    .sm-w-600px {width:600px !important;}
    .sm-w-650px {width:650px !important;}
    .sm-w-700px {width:700px !important;}
    .sm-w-750px {width:750px !important;}
    .sm-w-800px {width:800px !important;}
    .sm-w-850px {width:850px !important;}
    .sm-w-900px {width:900px !important;}
    .sm-w-950px {width:950px !important;}
    .sm-w-1000px {width:1000px !important;}

    /* height */
    .sm-h-50 {height: 50% !important}
    .sm-h-100 {height: 100% !important}
    .sm-h-auto {height:auto !important}
    .sm-h-10px {height: 10px !important}
    .sm-h-20px {height: 20px !important}
    .sm-h-30px {height: 30px !important}
    .sm-h-40px {height: 40px !important}
    .sm-h-50px {height: 50px !important}
    .sm-h-60px {height: 60px !important}
    .sm-h-70px {height: 70px !important}
    .sm-h-80px {height: 80px !important}
    .sm-h-90px {height: 90px !important}
    .sm-h-100px {height: 100px !important}
    .sm-h-150px {height: 150px !important}
    .sm-h-200px {height: 200px !important}
    .sm-h-250px {height: 250px !important}
    .sm-h-300px {height: 300px !important}
    .sm-h-350px {height: 350px !important}
    .sm-h-400px {height: 400px !important}
    .sm-h-450px {height: 450px !important}
    .sm-h-500px {height: 500px !important}
    .sm-h-550px {height: 550px !important}
    .sm-h-600px {height: 600px !important}
    .sm-h-650px {height: 650px !important}
    .sm-h-700px {height: 700px !important}

    /* line height */
    .sm-line-height-10 {line-height:10px;}
    .sm-line-height-18 {line-height:18px;}
    .sm-line-height-20 {line-height:20px}
    .sm-line-height-24 {line-height:24px}
    .sm-line-height-22 {line-height:22px}
    .sm-line-height-26 {line-height:26px}
    .sm-line-height-28 {line-height:28px}
    .sm-line-height-30 {line-height:30px}
    .sm-line-height-35 {line-height:35px}
    .sm-line-height-40 {line-height:40px}
    .sm-line-height-45 {line-height:45px}
    .sm-line-height-50 {line-height:50px}
    .sm-line-height-55 {line-height:55px}
    .sm-line-height-60 {line-height:60px}
    .sm-line-height-65 {line-height:65px}
    .sm-line-height-70 {line-height:70px}
    .sm-line-height-75 {line-height:75px}
    .sm-line-height-80 {line-height:80px}
    .sm-line-height-85 {line-height:85px}
    .sm-line-height-90 {line-height:90px}
    .sm-line-height-95 {line-height:95px}
    .sm-line-height-100 {line-height:100px}

    .page-title-large h1 {font-size: 35px; line-height: 35px;}
    .page-title-medium h1 { font-size: 25px; line-height: 30px;}
    .title-large {font-size: 38px; line-height:44px}
    .title-extra-large {font-size: 35px; line-height:40px}

    /* position */
    .sm-letter-spacing-0 {letter-spacing: 0;}

    /* border */
    .sm-no-border-top {border-top:0 !important}
    .sm-no-border-bottom {border-bottom:0 !important}
    .sm-no-border-right {border-right:0 !important}
    .sm-no-border-left {border-left:0 !important}
    .sm-no-border { border: 0 !important}

    .span_hide {display: none;}

    /* custom */
    .section_hide {display: none !important;}
    /*section {padding: 50px 0;}*/
    section.big-section {padding:80px 0;}
    section.extra-big-section {padding:100px 0;}
    section.half-section {padding:25px 0;}
    .sm-col-2-nth .col-sm-6:nth-child(2n+1) {clear: left;}
    .sm-col-3-nth .col-sm-4:nth-child(3n+1) {clear: left;}
    .sm-col-4-nth .col-sm-3:nth-child(4n+1) {clear: left;}
    .mobile-height, .swiper-full-screen { height: auto !important; display: none;}
    .mobile-height .full-screen, .swiper-full-screen .full-screen {height: 480px !important; min-height: 480px !important}
    .overlap-section {margin-top: 0}

    /* blockquote */
    .blog-image blockquote { padding: 20px; }

    /* slider */
    .slider-half-screen { height: 420px;}

    /* slider navigation */
    .slider-navigation.swiper-button-next, .slider-navigation-style-01.swiper-button-next { right: 5px; }
    .slider-navigation.swiper-button-prev, .slider-navigation-style-01.swiper-button-prev { left: 5px; }
    .arrow-big.swiper-button-next, .arrow-big.swiper-button-prev { font-size: 24px; width: 40px; height: 40px; }
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction { bottom: 20px; }

    /* portfolio grid */
    .hover-option8 .grid-item .portfolio-icon {right: 39px; top: 34px;}

    /* swiper-bottom-scrollbar-full */
    .swiper-bottom-scrollbar-full .swiper-slide img {max-height: 100%;}
    .swiper-bottom-scrollbar-full .swiper-slide:hover img {max-height: 100%; }

    /* feature box style 4 */
    .grid-item.feature-box-4 figure figcaption {width: 90%;}

    /* swipe carousel  */
    .swiper-full-screen .swiper-pagination {bottom: 20px;}

    /* counter box style 3 */
    .counter-box-3 .counter-box {width: 25%;}
    .counter-box-3 .counter-box .number {font-size: 16px; line-height: 16px;}
    .counter-box-3 .counter-box span {font-size: 12px; line-height: 24px;}
    .counter-box-3 .counter-box:after { top: -1px;}

    /* counter box style 4 */
    .icon-box .icon-box-holder i { padding-top: 10px;}

    /* feature box 4*/
    .feature-box-4 .grid-item figcaption {padding:0 20px;}
    .feature-box-4 figure figcaption {padding: 10% 5%; text-align: center}

    /* feature box 5*/
    .feature-box-5 {padding-left: 0; text-align: center}
    .feature-box-5 i {display: block; width: 100%; position: inherit; margin-bottom: 15px}

    /* feature box 9 */
    .feature-box-9:nth-child(3) {clear: left;}

    /* feature box 10 */
    .feature-box-10 .number-center::before, .feature-box-10 .number-center::after {height:0}

    /* feature box style 12 */
    .feature-box-12 .hover-content-box { width: calc( 100% - 30px ); margin-left: 15px; margin-right: 15px;}

    /* feature box style 17 */
    .feature-box.feature-box-17 .box .content {top: 25px;}

    /* hover option3 */
    .hover-option3 .grid-item figure {bottom: 80px}
    .hover-option3 .grid-item figure figcaption {padding: 0 30px}

    /* header social icon */
    .header-searchbar { padding-left: 5px;}
    .header-searchbar a i { margin-right: 0; font-size: 12px; }
    .header-right { margin-left: 0;}

    /* header with sticky topbar */
    .header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top, .sticky.header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top, .sticky.header-with-topbar.header-appear.fixed-topbar nav.navbar.bootsnav.navbar-top {top: 30px;}

    /* header style */
    .navbar-nav li > a, nav.navbar.navbar-default ul.nav > li > a, nav.navbar-brand-top.navbar.navbar-default ul.nav > li > a, header .navbar-nav li > a, header nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a, header nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a, nav.navbar.bootsnav li.dropdown ul.mega-menu-full li > a, header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a, header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a, nav.navbar.bootsnav li.dropdown ul.mega-menu-full li.dropdown-header { padding-left: 15px; padding-right: 15px; }
    .navbar-nav > li.dropdown > .fas { right: 0; }
    nav.navbar.bootsnav li.dropdown .mega-menu-full { padding: 0 15px; }
    nav.navbar.bootsnav ul.nav > li.dropdown > ul.dropdown-menu, nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu.mega-menu { padding-left: 15px !important; padding-right: 15px !important; }
    header nav.navbar-default { padding-left: 0; padding-right: 0; }
    header nav.nav-box-width.navbar-default, header nav.nav-box-width { padding: 0 0; }
    .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl, .top-header-area>.container-lg, .top-header-area>.container-md, .top-header-area>.container-sm, .top-header-area>.container-xl, .top-header-area>.container-xxl { padding-left: 15px; padding-right: 15px; }
    nav.navbar .container-fluid { padding-left: 15px; padding-right: 15px; }

    /* home-page-7 */
    .head-text {font-size: 30px; line-height: 36px;}
    .special-char-extra-large {font-size:130px; line-height: 110px;}

    /* swiper pagination */
    .swiper-container-horizontal > .swiper-pagination-bullets { bottom: 20px; }
    .swiper-pagination-square-bottom.swiper-pagination-bullets {bottom: -35px}
    .swiper-number-pagination .swiper-pagination-bullets {bottom: auto; top: 25% !important;}
    .swiper-vertical-pagination > .swiper-pagination-bullets { right: 20px; }

    /* verticle slder homepage 12 */
    .swiper-container-autoheight .swiper-slide {height: auto}
    .swiper-container-autoheight .swiper-slide:last-child {margin-bottom: 0;}
    .swiper-bottom-scrollbar-full .swiper-slide img {opacity: 1; filter: grayscale(0); -webkit-filter: grayscale(0); -moz-filter: grayscale(0); -ms-filter: grayscale(0); -o-filter: grayscale(0); filter: contrast(130%); -webkit-filter: contrast(130%); -moz-filter: contrast(130%); -ms-filter: contrast(130%); -o-filter: contrast(130%);  width: 100%; max-height: 100%}
    .hover-title-box .separator {position: relative; left: 0; top: 70px}
    .swiper-bottom-scrollbar-full .bottom-text {opacity: 1;}
    .swiper-auto-slide.swiper-container {height: calc(100% - 93px); top: 78px;}
    .swiper-bottom-scrollbar-full .bottom-text { bottom: 25px; }
    .hover-title-box { opacity: 1; text-align: center; top: inherit; transform: translate(-50%, -30px); -moz-transform: translate(-50%, -30px); -webkit-transform: translate(-50%, -30px); -o-transform: translate(-50%, -30px); -ms-transform: translate(-50%, -30px); bottom: 0; left: 50%; right: 0; display: block; }

    /* swiper button style3 */
    .swiper-button-prev.swiper-prev-style3, .swiper-button-next.swiper-next-style3 {position: relative; bottom: 24px; display: inline-block; transform: rotate(-90deg); -moz-transform: rotate(-90deg); -webkit-transform: rotate(-90deg); -o-transform: rotate(-90deg); -ms-transform: rotate(-90deg); left: 50%; margin: 0;}
    .swiper-button-prev.swiper-prev-style3 {left: 50%; margin: 0 0 0 -85px;}

    /* left nav */
    .sidebar-part1, .bottom-menu-icon {width: 50px}
    .bottom-menu-icon {padding: 8px 15px;}

    /* testimonial-style2 */
    .testimonial-style2 .profile-box {margin-top: -25px;}

    /* left-sidebar style 2 */
    .sidebar-part2 { width: 280px; left: -280px;}
    .sidebar-part2 .right-bg {right: 15px;}

    /* link style 2 */
    .full-width-pull-menu .link-style-2 ul li a {font-size: 26px; line-height: 60px}
    .full-width-pull-menu .link-style-2 ul li a {line-height:42px; font-size: 22px;}
    .full-width-pull-menu .link-style-2 .icon-social-medium a:first-child .fab {margin-left: 0;}
    .full-width-pull-menu .link-style-2 .dropdown ul li a {line-height:16px; font-size:12px; padding: 3px 0;}
    .link-style-2 .dropdown-backdrop {display: none}

    /* close button */
    .close-button-menu {top:35px; right:12px;}

    /* full width pull menu */
    .full-width-pull-menu .dropdown span.dropdown-toggle {width:30px; height:30px; top:21px; right: 0;}
    .full-width-pull-menu .dropdown .dropdown-toggle:before {right:15px; top:10px}
    .full-width-pull-menu .dropdown .dropdown-toggle:after {right:10px; top:15px}

    /* btn */
    .btn-dual .btn { margin:10px auto !important; display: table; min-width: 160px; }

    /* search form style */
    .search-form {width: 100%}

    /* hamburger menu */
    .animation-box {padding: 90px 0 30px;}
    .animation-box .menu-middle { overflow-y: auto; display: inherit;}
    .hamburger-menu-links {margin-bottom: 15px;}
    .hamburger-menu-links li {width:100%; margin-bottom: 15px}
    .hamburger-menu-links {font-size: 30px}
    .hamburger-menu .hamburger-menu-wrepper .btn-hamburger {right: 15px;}
    .hamburger-menu-wrepper { padding: 20px 15px; }
    .menu-middle { width: 100%; }
    .hamburger-menu-links { font-size: 20px; line-height: 24px; }
    .hamburger-menu.show-menu .hamburger-menu-wrepper .btn-hamburger { right: 15px; top: 22px; margin: 0; }

    /* footer */
    .social-style-2 ul li { margin:2px 5px;}
    .latest-post li div {float: left; width: 70%;}
    .latest-post li {width: 100%;}
    .footer-widget-area .list-unstyled { margin-bottom: 0;}
    .instagram-follow-api li {padding: 0 5px 10px}
    .list-style-7 li {padding-bottom: 8px; margin-bottom: 5px;}

    /* sidebar nav style 2 */
    .left-nav-sidebar .container { width: 100%; }
    .sidebar-part2 .sidebar-middle { padding: 15px 0 100px; }
    .sidebar-part2 .widget ul {bottom: -12px;}

    /* homepage 14 */
    .content-right-slider .swiper-button-next, .swiper-button-next.slider-long-arrow-white, .swiper-container-rtl .swiper-button-prev.slider-long-arrow-white {right: 10px;}
    .content-right-slider .swiper-button-prev, .swiper-button-prev.slider-long-arrow-white, .swiper-container-rtl .swiper-button-next.slider-long-arrow-white {left: 10px;}
    .content-right-slider .swiper-button-next, .content-right-slider .swiper-button-prev, .swiper-button-prev.slider-long-arrow-white, .swiper-container-rtl .swiper-button-next.slider-long-arrow-white, .swiper-button-next.slider-long-arrow-white, .swiper-container-rtl .swiper-button-prev.slider-long-arrow-white { background-size: 20px auto; width: 25px; height: 25px;}

    /* swiper bootom scroll bar for portfoio personal */
    .swiper-bottom-scrollbar-full.swiper-container {height: 100%; top: 0; padding-top: 50px; overflow: hidden; float: left; display: block; position: relative;}
    .swiper-bottom-scrollbar-full .swiper-wrapper {display: block; overflow-y: auto; overflow-x: hidden; transform: inherit !important; transition-duration: inherit !important;}
    .swiper-bottom-scrollbar-full .swiper-wrapper .swiper-slide { margin: 0 !important; }
    body.xs-overflow-hidden { overflow: auto !important; }

    /* homepage 19 */
    .swiper-vertical-pagination .box-arrow {height: 50px; position: absolute; right: -35px; bottom: 0; width: 50px;}
    .down-section {bottom:40px;}
    .after-before-separator::before, .after-before-separator::after {margin: 0 10px; width: 25px;}

    /* social media icon style 3*/
    .social-style-3 ul li { margin: 0 8px; }

    /* tab */
    .nav-tabs { text-align: center;}
    .portfolio-filter-tab-1 li { margin: 5px 0; width: 100%; }
    .portfolio-filter-tab-2 li.nav-item {width: 100%; margin-bottom: 5px}

    /* tab style 1 */
    .tab-style1 .nav-tabs li {width: 100%; margin-bottom: 30px;}
    .tab-style1 .nav-tabs li a { padding: 0; }

    /* tab style 2 */
    .tab-style2 .nav-tabs > li > a.active { top: 0}
    .tab-style2 .tab-content {padding: 30px 0 0}
    .tab-style2 .nav-tabs li {display: block; margin-right: 0; width: 100%;}

    /* tab style 3 */
    .tab-style3 .tab-content {padding: 30px 0 0}
    .tab-style3 .nav-tabs li {display: block; text-align: left; margin:0; padding: 0; border-bottom: 1px solid rgba(0,0,0,.1)}
    .tab-style3 .nav-tabs li a { padding: 8px 0 !important; }

    /* tab style 4 */
    .tab-style4 .nav-tabs li { float: none; }

    /* tab style 5 */
    .tab-style5 .tab-box { position: inherit; transform: inherit; left: 0; top: 0; width: 100%; margin-bottom: 25px;}
    .tab-style5 .nav-tabs li {font-size: 17px;}
    .tab-style5 .nav-tabs .nav-link { border-bottom: 1px solid rgba(255,255,255,.3) !important; width: 100%; }
    .tab-style5 .nav-tabs .nav-link.active { padding-left: 0; width: 100%; border-bottom-width: 1px !important; }

    /* team style 2 */
    .team-style-2 figure:hover figcaption .overlay-content { top: 20px; }

    /* team  */
    .team-image img {width: 100%;}
    .banner-style2 figure:hover figcaption { right: 10%;}
    .banner-style2 figcaption {width: 80%;}
    .text-decoration-line-through-black::before, .text-decoration-line-through-deep-pink:before {display: none}

    /* counter */
    .counter-box-5 .counter-box .number {font-size: 30px; line-height: 30px;}
    .counter-box-5 .counter-box {width: 20%}
    .counter-box-5 .counter-box span {letter-spacing: 1px; font-size: 11px;}

    .navigation-area { right: 0;}
    .swiper-button-prev.swiper-prev-style4, .swiper-button-next.swiper-next-style4 { transform: scale(0.8); -moz-transform: scale(0.8); -ms-transform: scale(0.8); -webkit-transform: scale(0.8); -o-transform: scale(0.8); top: 5px; right: -4px;}
    .swiper-button-next.swiper-next-style4 { top: 14px;}
    .blog-nav-link {padding: 10px 0; font-size: 12px;}
    .blog-nav-link-next { padding-right: 30px;}
    .blog-nav-link-prev {padding-left: 30px;}
    .blog-listing-comment {width: 100%; text-align: center;}
    .blog-listing-comment li {display: inline-block; float: none}
    .blog-text .name {width: 100%; text-align: center;}
    .text-outside-line:after {display: none}
    .text-outside-line:before {display: none}
    .input-border-bottom {margin-bottom: 10px;}
    .sidebar-part3 { top: 6px;}
    .blog-text .name:nth-child(2) { padding-top: 0; padding-bottom: 0; }

    /* top logo */
    .navbar.navbar-brand-top.bootsnav .navbar-toggler { top: 4px;}
    .navbar-brand-top .brand-top-menu-right { margin-top: 1px; margin-left: 0; }

    /* slider image */
    .parallax-category-text { background: #000 none repeat scroll 0 0; color: #fff; opacity: 1; padding: 5px 18px;}

    /* hover option 6 */
    .hover-option6 .grid-item .portfolio-icon i.fa-search {  top: 12px; }

    /* header style */
    header .container, header .container-fluid, header .container-lg, header .container-md, header .container-sm, header .container-xl, header .container-xxl { padding-right: 0; padding-left: 0; }

    .mfp-close, .mfp-close:active {top: 0; right: 0;}
    button.mfp-arrow {background-color: #000; height: 50px; width: 50px; opacity: 1}
    .mfp-arrow:before {margin: 0 auto; width: 100%; height: 100%; display: inline-block; vertical-align: middle; text-align: center; line-height: 50px; border: 0}
    .mfp-arrow, .mfp-arrow:active, .mfp-arrow:focus {margin-top: -25px}
    .mfp-arrow-left {left: 6px}
    .mfp-arrow-right {right: 6px}
    #search-header {width: 90%; margin: 0 auto}
    .search-form .search-input {font-size: 16px; padding-top: 10px; padding-bottom: 10px; line-height: 34px;}
    .search-form .search-button {font-size: 12px; top: 20px;}

    /* social style */
    .social-style-5 ul li { margin: 5px;}
    .social-style-1 ul li {margin: 0 5px;}

    /* blog comment */
    .child-comment {padding-left: 20px;}
    .blog-comment li ul.child-comment {margin-left: 0}
    .blog-comment li ul.child-comment li {margin-top: 30px}
    img.alignleft, img.alignright {margin: 0 auto 15px; float: none; width: 100%;}
    blockquote {margin: 30px 0; padding: 15px 25px;}
    blockquote p {font-size: 16px; line-height: 28px;}

    /* blog caption */
    .wp-caption { max-width: 100%; }
    .wp-caption.alignleft, .wp-caption.alignright {float: none; margin: 5px 0 30px;}

    /* icon type */
    .icon-medium {font-size: 30px}
    .icon-extra-medium {font-size: 30px}
    .icon-large {font-size: 40px}
    .extra-small-icon li, .small-icon li, .medium-icon li, .large-icon li, .extra-large-icon li {margin-bottom: 10px;}

    .uranus.tparrows:before {font-size: 20px;}

    .demo-heading {font-size: 24px; line-height: 30px;}
    .tab-style4 .tab-content {padding-left: 15px; border-left: 0; }
    .tab-style4 .nav-tabs {display: block !important; padding-right: 15px; margin-bottom: 30px;}
    .tab-style4 .nav-tabs li {border-right: 1px solid #e5e5e5;}

    /* latest post */
    aside .latest-post li { padding: 10px 0; }

    /* custome large icon size for mobile */
    .large-icon.mobile-small li { margin: 0 6px 8px }
    .large-icon.mobile-small a { font-size: 20px; width: 50px; height: 50px; line-height: 48px; }

    .text-outside-line-left { padding-left: 90px; }
    .text-outside-line-left:before { left:0; width: 80px; }

    /* scroll down */
    .scroll-down-section { bottom: -35px; }
    .scroll-down-section div { height: 70px;}

    /* small arrow */
    .swiper-button-prev.slider-small-arrow-white, .swiper-button-prev.slider-small-arrow-black { left: 0; }
    .swiper-button-next.slider-small-arrow-white, .swiper-button-next.slider-small-arrow-black { right: 0; }

    /* play icon */
    .icon-play-style-2 .video-icon { width: 90px; height: 90px; margin-top: 0; }

    /* grid */
    .grid.sm-grid-6col li { width: 16.67%; }
    .grid.sm-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.sm-grid-5col li { width: 20%; }
    .grid.sm-grid-5col li.grid-item-double { width: 40%; }
    .grid.sm-grid-4col li { width: 25%; }
    .grid.sm-grid-4col li.grid-item-double { width: 50%; }
    .grid.sm-grid-3col li { width: 33.33%; }
    .grid.sm-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.sm-grid-2col li { width: 50%; }
    .grid.sm-grid-2col li.grid-item-double { width: 100%; }
    .grid.sm-grid-1col li { width: 100%; }
    .grid.sm-grid-1col li.grid-item-double { width: 100%; }

    /* display */
    .sm-overflow-auto { overflow: auto !important; }

    /* nav box width */
    header nav.nav-box-width { padding: 0;}
}

@media (max-width: 640px) {
    /* special character */
    .special-char-medium {font-size: 60px;}

    /* accordion-style2 */
    .accordion-style2 .panel-body {padding: 20px 10px}
    .accordion-style2 .panel .panel-heading { padding: 15px 10px 16px 10px}
    .accordion-style2 .tab-tag { margin-right: 3px}
    .accordion-style2 .panel-heading i { right: 10px; }

    /* accordion-style3 */
    .accordion-style3 .panel-body {padding: 20px 10px}
    .accordion-style3 .panel .panel-heading { padding: 15px 10px 16px 10px}
    .accordion-style3 .tab-tag { margin-right: 3px}
    .accordion-style3 .panel-title > i { right: 10px; }

    /* hover style 7 */
    .hover-option7 .grid-item figcaption {padding: 20px}
    .hover-option7 .grid-item figure:hover img {top: -83px}

    /* breadcrumb */
    .breadcrumb ul > li:after {padding: 0 3px 0 5px}
}

@media (max-width: 575px) {
    /* grid */
    .grid.xs-grid-6col li { width: 16.67%; }
    .grid.xs-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.xs-grid-5col li { width: 20%; }
    .grid.xs-grid-5col li.grid-item-double { width: 40%; }
    .grid.xs-grid-4col li { width: 25%; }
    .grid.xs-grid-4col li.grid-item-double { width: 50%; }
    .grid.xs-grid-3col li { width: 33.33%; }
    .grid.xs-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.xs-grid-2col li { width: 50%; }
    .grid.xs-grid-2col li.grid-item-double { width: 100%; }
    .grid.xs-grid-1col li { width: 100%; }
    .grid.xs-grid-1col li.grid-item-double { width: 100%; }

    /* gutter type */
    .grid.gutter-very-small { margin: 0 -3px }
    .grid.gutter-very-small li { padding: 3px 3px; }
    .grid.gutter-small { margin: 0 -5px }
    .grid.gutter-small li { padding:7px 5px; }
    .grid.gutter-medium { margin: 0 -7px }
    .grid.gutter-medium li { padding:7px 7px; }
    .grid.gutter-large { margin: 0 -10px }
    .grid.gutter-large li { padding:7px 10px; }
    .grid.gutter-extra-large { margin: 0 -15px }
    .grid.gutter-extra-large li { padding: 15px; }
    .grid.gutter-double-extra-large { margin: 0 -15px }
    .grid.gutter-double-extra-large li { padding:15px 15px; }
    .grid.gutter-triple-extra-large { margin: 0 -15px }
    .grid.gutter-triple-extra-large li { padding:15px 15px; }

    /* instagram feed */
    aside .instafeed-grid.gutter-small li, footer .instafeed-grid.gutter-small li { padding:5px 5px; }

    /* margin */
    .xs-margin-one-all {margin:1%;}
    .xs-margin-one-half-all {margin:1.5%;}
    .xs-margin-two-all {margin:2%;}
    .xs-margin-two-half-all {margin:2.5%;}
    .xs-margin-three-all {margin:3%;}
    .xs-margin-three-half-all {margin:3.5%;}
    .xs-margin-four-all {margin:4%;}
    .xs-margin-four-half-all {margin:4.5%;}
    .xs-margin-five-all {margin:5%;}
    .xs-margin-five-half-all {margin:5.5%;}
    .xs-margin-six-all {margin:6%;}
    .xs-margin-six-half-all {margin:6.5%;}
    .xs-margin-seven-all {margin:7%;}
    .xs-margin-seven-half-all {margin:7.5%;}
    .xs-margin-eight-all {margin:8%;}
    .xs-margin-eight-half-all {margin:8.5%;}
    .xs-margin-nine-all {margin:9%;}
    .xs-margin-nine-half-all {margin:9.5%;}
    .xs-margin-ten-all {margin:10%;}
    .xs-margin-ten-half-all {margin:10.5%;}
    .xs-margin-eleven-all {margin:11%;}
    .xs-margin-eleven-half-all {margin:11.5%;}
    .xs-margin-twelve-all {margin:12%;}
    .xs-margin-twelve-half-all {margin:12.5%;}
    .xs-margin-thirteen-all {margin:13%;}
    .xs-margin-thirteen-half-all {margin:13.5%;}
    .xs-margin-fourteen-all {margin:14%;}
    .xs-margin-fourteen-half-all {margin:14.5%;}
    .xs-margin-fifteen-all {margin:15%;}
    .xs-margin-fifteen-half-all {margin:15.5%;}
    .xs-margin-sixteen-all {margin:16%;}
    .xs-margin-sixteen-half-all {margin:16.5%;}
    .xs-margin-seventeen-all {margin:17%;}
    .xs-margin-seventeen-half-all {margin:17.5%;}
    .xs-margin-eighteen-all {margin:18%;}
    .xs-margin-eighteen-half-all {margin:18.5%;}
    .xs-margin-nineteen-all {margin:19%;}
    .xs-margin-nineteen-half-all {margin:19.5%;}
    .xs-margin-twenty-all {margin:20%;}
    .xs-margin-twenty-half-all {margin:20.5%;}
    .xs-margin-twenty-one-all {margin:21%;}
    .xs-margin-twenty-one-half-all {margin:21.5%;}
    .xs-margin-twenty-two-all {margin:22%;}
    .xs-margin-twenty-two-half-all {margin:22.5%;}
    .xs-margin-twenty-three-all {margin:23%;}
    .xs-margin-twenty-three-half-all {margin:23.5%;}
    .xs-margin-twenty-four-all {margin:24%;}
    .xs-margin-twenty-four-half-all {margin:24.5%;}
    .xs-margin-twenty-five-all {margin:25%;}
    .xs-no-margin {margin:0 !important}
    .xs-no-margin-lr {margin-left: 0 !important; margin-right: 0 !important}
    .xs-no-margin-tb {margin-top: 0 !important; margin-bottom: 0 !important}
    .xs-no-margin-top {margin-top:0 !important}
    .xs-no-margin-bottom {margin-bottom:0 !important}
    .xs-no-margin-left {margin-left:0 !important}
    .xs-no-margin-right {margin-right:0 !important}
    .xs-margin-lr-auto {margin-left:auto !important; margin-right:auto !important}
    .xs-margin-auto {margin: 0 auto !important;}
    .xs-margin-auto-lr { margin-left: auto !important; margin-right: auto !important }

    /* margin top */
    .xs-margin-one-top {margin-top:1%;}
    .xs-margin-one-half-top {margin-top:1.5%;}
    .xs-margin-two-top {margin-top:2%;}
    .xs-margin-two-half-top {margin-top:2.5%;}
    .xs-margin-three-top {margin-top:3%;}
    .xs-margin-three-half-top {margin-top:3.5%;}
    .xs-margin-four-top {margin-top:4%;}
    .xs-margin-four-half-top {margin-top:4.5%;}
    .xs-margin-five-top {margin-top:5%;}
    .xs-margin-five-half-top {margin-top:5.5%;}
    .xs-margin-six-top {margin-top:6%;}
    .xs-margin-six-half-top {margin-top:6.5%;}
    .xs-margin-seven-top {margin-top:7%;}
    .xs-margin-seven-half-top {margin-top:7.5%;}
    .xs-margin-eight-top {margin-top:8%;}
    .xs-margin-eight-half-top {margin-top:8.5%;}
    .xs-margin-nine-top {margin-top:9%;}
    .xs-margin-nine-half-top {margin-top:9.5%;}
    .xs-margin-ten-top {margin-top:10%;}
    .xs-margin-ten-half-top {margin-top:10.5%;}
    .xs-margin-eleven-top {margin-top:11%;}
    .xs-margin-eleven-half-top {margin-top:11.5%;}
    .xs-margin-twelve-top {margin-top:12%;}
    .xs-margin-twelve-half-top {margin-top:12.5%;}
    .xs-margin-thirteen-top {margin-top:13%;}
    .xs-margin-thirteen-half-top {margin-top:13.5%;}
    .xs-margin-fourteen-top {margin-top:14%;}
    .xs-margin-fourteen-half-top {margin-top:14.5%;}
    .xs-margin-fifteen-top {margin-top:15%;}
    .xs-margin-fifteen-half-top {margin-top:15.5%;}
    .xs-margin-sixteen-top {margin-top:16%;}
    .xs-margin-sixteen-half-top {margin-top:16.5%;}
    .xs-margin-seventeen-top {margin-top:17%;}
    .xs-margin-seventeen-half-top {margin-top:17.5%;}
    .xs-margin-eighteen-top {margin-top:18%;}
    .xs-margin-eighteen-half-top {margin-top:18.5%;}
    .xs-margin-nineteen-top {margin-top:19%;}
    .xs-margin-nineteen-half-top {margin-top:19.5%;}
    .xs-margin-twenty-top {margin-top:20%;}
    .xs-margin-twenty-half-top {margin-top:20.5%;}
    .xs-margin-twenty-one-top {margin-top:21%;}
    .xs-margin-twenty-one-half-top {margin-top:21.5%;}
    .xs-margin-twenty-two-top {margin-top:22%;}
    .xs-margin-twenty-two-half-top {margin-top:22.5%;}
    .xs-margin-twenty-three-top {margin-top:23%;}
    .xs-margin-twenty-three-half-top {margin-top:23.5%;}
    .xs-margin-twenty-four-top {margin-top:24%;}
    .xs-margin-twenty-four-half-top {margin-top:24.5%;}
    .xs-margin-twenty-five-top {margin-top:25%;}
    .xs-margin-5px-top {margin-top:5px;}
    .xs-margin-10px-top {margin-top:10px;}
    .xs-margin-15px-top {margin-top:15px;}
    .xs-margin-20px-top {margin-top:20px;}
    .xs-margin-25px-top {margin-top:25px;}
    .xs-margin-30px-top {margin-top:30px;}
    .xs-margin-35px-top {margin-top:35px;}
    .xs-margin-40px-top {margin-top:40px;}
    .xs-margin-45px-top {margin-top:45px;}
    .xs-margin-50px-top {margin-top:50px;}
    .xs-margin-55px-top {margin-top:55px;}
    .xs-margin-60px-top {margin-top:60px;}
    .xs-margin-65px-top {margin-top:65px;}
    .xs-margin-70px-top {margin-top:70px;}
    .xs-margin-75px-top {margin-top:75px;}
    .xs-margin-80px-top {margin-top:80px;}
    .xs-margin-85px-top {margin-top:85px;}
    .xs-margin-90px-top {margin-top:90px;}
    .xs-margin-95px-top {margin-top:95px;}
    .xs-margin-100px-top {margin-top:100px;}

    /* margin bottom */
    .xs-margin-one-bottom {margin-bottom:1%;}
    .xs-margin-one-half-bottom {margin-bottom:1.5%;}
    .xs-margin-two-bottom {margin-bottom:2%;}
    .xs-margin-two-half-bottom {margin-bottom:2.5%;}
    .xs-margin-three-bottom {margin-bottom:3%;}
    .xs-margin-three-half-bottom {margin-bottom:3.5%;}
    .xs-margin-four-bottom {margin-bottom:4%;}
    .xs-margin-four-half-bottom {margin-bottom:4.5%;}
    .xs-margin-five-bottom {margin-bottom:5%;}
    .xs-margin-five-half-bottom {margin-bottom:5.5%;}
    .xs-margin-six-bottom {margin-bottom:6%;}
    .xs-margin-six-half-bottom {margin-bottom:6.5%;}
    .xs-margin-seven-bottom {margin-bottom:7%;}
    .xs-margin-seven-half-bottom {margin-bottom:7.5%;}
    .xs-margin-eight-bottom {margin-bottom:8%;}
    .xs-margin-eight-half-bottom {margin-bottom:8.5%;}
    .xs-margin-nine-bottom {margin-bottom:9%;}
    .xs-margin-nine-half-bottom {margin-bottom:9.5%;}
    .xs-margin-ten-bottom {margin-bottom:10%;}
    .xs-margin-ten-half-bottom {margin-bottom:10.5%;}
    .xs-margin-eleven-bottom {margin-bottom:11%;}
    .xs-margin-eleven-half-bottom {margin-bottom:11.5%;}
    .xs-margin-twelve-bottom {margin-bottom:12%;}
    .xs-margin-twelve-half-bottom {margin-bottom:12.5%;}
    .xs-margin-thirteen-bottom {margin-bottom:13%;}
    .xs-margin-thirteen-half-bottom {margin-bottom:13.5%;}
    .xs-margin-fourteen-bottom {margin-bottom:14%;}
    .xs-margin-fourteen-half-bottom {margin-bottom:14.5%;}
    .xs-margin-fifteen-bottom {margin-bottom:15%;}
    .xs-margin-fifteen-half-bottom {margin-bottom:15.5%;}
    .xs-margin-sixteen-bottom {margin-bottom:16%;}
    .xs-margin-sixteen-half-bottom {margin-bottom:16.5%;}
    .xs-margin-seventeen-bottom {margin-bottom:17%;}
    .xs-margin-seventeen-half-bottom {margin-bottom:17.5%;}
    .xs-margin-eighteen-bottom {margin-bottom:18%;}
    .xs-margin-eighteen-half-bottom {margin-bottom:18.5%;}
    .xs-margin-nineteen-bottom {margin-bottom:19%;}
    .xs-margin-nineteen-half-bottom {margin-bottom:19.5%;}
    .xs-margin-twenty-bottom {margin-bottom:20%;}
    .xs-margin-twenty-half-bottom {margin-bottom:20.5%;}
    .xs-margin-twenty-one-bottom {margin-bottom:21%;}
    .xs-margin-twenty-one-half-bottom {margin-bottom:21.5%;}
    .xs-margin-twenty-two-bottom {margin-bottom:22%;}
    .xs-margin-twenty-two-half-bottom {margin-bottom:22.5%;}
    .xs-margin-twenty-three-bottom {margin-bottom:23%;}
    .xs-margin-twenty-three-half-bottom {margin-bottom:23.5%;}
    .xs-margin-twenty-four-bottom {margin-bottom:24%;}
    .xs-margin-twenty-four-half-bottom {margin-bottom:24.5%;}
    .xs-margin-twenty-five-bottom {margin-bottom:25%;}
    .xs-margin-5px-bottom {margin-bottom:5px;}
    .xs-margin-10px-bottom {margin-bottom:10px;}
    .xs-margin-15px-bottom {margin-bottom:15px;}
    .xs-margin-20px-bottom {margin-bottom:20px;}
    .xs-margin-25px-bottom {margin-bottom:25px;}
    .xs-margin-30px-bottom {margin-bottom:30px;}
    .xs-margin-35px-bottom {margin-bottom:35px;}
    .xs-margin-40px-bottom {margin-bottom:40px;}
    .xs-margin-45px-bottom {margin-bottom:45px;}
    .xs-margin-50px-bottom {margin-bottom:50px;}
    .xs-margin-55px-bottom {margin-bottom:55px;}
    .xs-margin-60px-bottom {margin-bottom:60px;}
    .xs-margin-65px-bottom {margin-bottom:65px;}
    .xs-margin-70px-bottom {margin-bottom:70px;}
    .xs-margin-75px-bottom {margin-bottom:75px;}
    .xs-margin-80px-bottom {margin-bottom:80px;}
    .xs-margin-85px-bottom {margin-bottom:85px;}
    .xs-margin-90px-bottom {margin-bottom:90px;}
    .xs-margin-95px-bottom {margin-bottom:95px;}
    .xs-margin-100px-bottom {margin-bottom:100px;}

    /* margin right */
    .xs-margin-one-right {margin-right:1%;}
    .xs-margin-one-half-right {margin-right:1.5%;}
    .xs-margin-two-right {margin-right:2%;}
    .xs-margin-two-half-right {margin-right:2.5%;}
    .xs-margin-three-right {margin-right:3%;}
    .xs-margin-three-half-right {margin-right:3.5%;}
    .xs-margin-four-right {margin-right:4%;}
    .xs-margin-four-half-right {margin-right:4.5%;}
    .xs-margin-five-right {margin-right:5%;}
    .xs-margin-five-half-right {margin-right:5.5%;}
    .xs-margin-six-right {margin-right:6%;}
    .xs-margin-six-half-right {margin-right:6.5%;}
    .xs-margin-seven-right {margin-right:7%;}
    .xs-margin-seven-half-right {margin-right:7.5%;}
    .xs-margin-eight-right {margin-right:8%;}
    .xs-margin-eight-half-right {margin-right:8.5%;}
    .xs-margin-nine-right {margin-right:9%;}
    .xs-margin-nine-half-right {margin-right:9.5%;}
    .xs-margin-ten-right {margin-right:10%;}
    .xs-margin-ten-half-right {margin-right:10.5%;}
    .xs-margin-eleven-right {margin-right:11%;}
    .xs-margin-eleven-half-right {margin-right:11.5%;}
    .xs-margin-twelve-right {margin-right:12%;}
    .xs-margin-twelve-half-right {margin-right:12.5%;}
    .xs-margin-thirteen-right {margin-right:13%;}
    .xs-margin-thirteen-half-right {margin-right:13.5%;}
    .xs-margin-fourteen-right {margin-right:14%;}
    .xs-margin-fourteen-half-right {margin-right:14.5%;}
    .xs-margin-fifteen-right {margin-right:15%;}
    .xs-margin-fifteen-half-right {margin-right:15.5%;}
    .xs-margin-sixteen-right {margin-right:16%;}
    .xs-margin-sixteen-half-right {margin-right:16.5%;}
    .xs-margin-seventeen-right {margin-right:17%;}
    .xs-margin-seventeen-half-right {margin-right:17.5%;}
    .xs-margin-eighteen-right {margin-right:18%;}
    .xs-margin-eighteen-half-right {margin-right:18.5%;}
    .xs-margin-nineteen-right {margin-right:19%;}
    .xs-margin-nineteen-half-right {margin-right:19.5%;}
    .xs-margin-twenty-right {margin-right:20%;}
    .xs-margin-twenty-half-right {margin-right:20.5%;}
    .xs-margin-twenty-one-right {margin-right:21%;}
    .xs-margin-twenty-one-half-right {margin-right:21.5%;}
    .xs-margin-twenty-two-right {margin-right:22%;}
    .xs-margin-twenty-two-half-right {margin-right:22.5%;}
    .xs-margin-twenty-three-right {margin-right:23%;}
    .xs-margin-twenty-three-half-right {margin-right:23.5%;}
    .xs-margin-twenty-four-right {margin-right:24%;}
    .xs-margin-twenty-four-half-right {margin-right:24.5%;}
    .xs-margin-twenty-five-right {margin-right:25%;}
    .xs-margin-10px-right {margin-right:10px;}
    .xs-margin-15px-right {margin-right:15px;}
    .xs-margin-20px-right {margin-right:20px;}
    .xs-margin-25px-right {margin-right:25px;}
    .xs-margin-30px-right {margin-right:30px;}
    .xs-margin-35px-right {margin-right:35px;}
    .xs-margin-40px-right {margin-right:40px;}
    .xs-margin-45px-right {margin-right:45px;}
    .xs-margin-50px-right {margin-right:50px;}
    .xs-margin-55px-right {margin-right:55px;}
    .xs-margin-60px-right {margin-right:60px;}
    .xs-margin-65px-right {margin-right:65px;}
    .xs-margin-70px-right {margin-right:70px;}
    .xs-margin-75px-right {margin-right:75px;}
    .xs-margin-80px-right {margin-right:80px;}
    .xs-margin-85px-right {margin-right:85px;}
    .xs-margin-90px-right {margin-right:90px;}
    .xs-margin-95px-right {margin-right:95px;}
    .xs-margin-100px-right {margin-right:100px;}

    /* margin left */
    .xs-margin-one-left {margin-left:1%;}
    .xs-margin-one-half-left {margin-left:1.5%;}
    .xs-margin-two-left {margin-left:2%;}
    .xs-margin-two-half-left {margin-left:2.5%;}
    .xs-margin-three-left {margin-left:3%;}
    .xs-margin-three-half-left {margin-left:3.5%;}
    .xs-margin-four-left {margin-left:4%;}
    .xs-margin-four-half-left {margin-left:4.5%;}
    .xs-margin-five-left {margin-left:5%;}
    .xs-margin-five-half-left {margin-left:5.5%;}
    .xs-margin-six-left {margin-left:6%;}
    .xs-margin-six-half-left {margin-left:6.5%;}
    .xs-margin-seven-left {margin-left:7%;}
    .xs-margin-seven-half-left {margin-left:7.5%;}
    .xs-margin-eight-left {margin-left:8%;}
    .xs-margin-eight-half-left {margin-left:8.5%;}
    .xs-margin-nine-left {margin-left:9%;}
    .xs-margin-nine-half-left {margin-left:9.5%;}
    .xs-margin-ten-left {margin-left:10%;}
    .xs-margin-ten-half-left {margin-left:10.5%;}
    .xs-margin-eleven-left {margin-left:11%;}
    .xs-margin-eleven-half-left {margin-left:11.5%;}
    .xs-margin-twelve-left {margin-left:12%;}
    .xs-margin-twelve-half-left {margin-left:12.5%;}
    .xs-margin-thirteen-left {margin-left:13%;}
    .xs-margin-thirteen-half-left {margin-left:13.5%;}
    .xs-margin-fourteen-left {margin-left:14%;}
    .xs-margin-fourteen-half-left {margin-left:14.5%;}
    .xs-margin-fifteen-left {margin-left:15%;}
    .xs-margin-fifteen-half-left {margin-left:15.5%;}
    .xs-margin-sixteen-left {margin-left:16%;}
    .xs-margin-sixteen-half-left {margin-left:16.5%;}
    .xs-margin-seventeen-left {margin-left:17%;}
    .xs-margin-seventeen-half-left {margin-left:17.5%;}
    .xs-margin-eighteen-left {margin-left:18%;}
    .xs-margin-eighteen-half-left {margin-left:18.5%;}
    .xs-margin-nineteen-left {margin-left:19%;}
    .xs-margin-nineteen-half-left {margin-left:19.5%;}
    .xs-margin-twenty-left {margin-left:20%;}
    .xs-margin-twenty-half-left {margin-left:20.5%;}
    .xs-margin-twenty-one-left {margin-left:21%;}
    .xs-margin-twenty-one-half-left {margin-left:21.5%;}
    .xs-margin-twenty-two-left {margin-left:22%;}
    .xs-margin-twenty-two-half-left {margin-left:22.5%;}
    .xs-margin-twenty-three-left {margin-left:23%;}
    .xs-margin-twenty-three-half-left {margin-left:23.5%;}
    .xs-margin-twenty-four-left {margin-left:24%;}
    .xs-margin-twenty-four-half-left {margin-left:24.5%;}
    .xs-margin-twenty-five-left {margin-left:25%;}
    .xs-margin-5px-left {margin-left:5px;}
    .xs-margin-10px-left {margin-left:10px;}
    .xs-margin-15px-left {margin-left:15px;}
    .xs-margin-20px-left {margin-left:20px;}
    .xs-margin-25px-left {margin-left:25px;}
    .xs-margin-30px-left {margin-left:30px;}
    .xs-margin-35px-left {margin-left:35px;}
    .xs-margin-40px-left {margin-left:40px;}
    .xs-margin-45px-left {margin-left:45px;}
    .xs-margin-50px-left {margin-left:50px;}
    .xs-margin-55px-left {margin-left:55px;}
    .xs-margin-60px-left {margin-left:60px;}
    .xs-margin-65px-left {margin-left:65px;}
    .xs-margin-70px-left {margin-left:70px;}
    .xs-margin-75px-left {margin-left:75px;}
    .xs-margin-80px-left {margin-left:80px;}
    .xs-margin-85px-left {margin-left:85px;}
    .xs-margin-90px-left {margin-left:90px;}
    .xs-margin-95px-left {margin-left:95px;}
    .xs-margin-100px-left {margin-left:100px;}

    /* margin left right */
    .xs-margin-one-lr {margin-left:1%; margin-right:1%;}
    .xs-margin-one-lr {margin-left:1.5%; margin-right:1.5%;}
    .xs-margin-two-lr {margin-left:2%; margin-right:2%;}
    .xs-margin-two-lr {margin-left:2.5%; margin-right:2.5%;}
    .xs-margin-three-lr {margin-left:3%; margin-right:3%;}
    .xs-margin-three-lr {margin-left:3.5%; margin-right:3.5%;}
    .xs-margin-four-lr {margin-left:4%; margin-right:4%;}
    .xs-margin-four-lr {margin-left:4.5%; margin-right:4.5%;}
    .xs-margin-five-lr {margin-left:5%; margin-right:5%;}
    .xs-margin-five-lr {margin-left:5.5%; margin-right:5.5%;}
    .xs-margin-six-lr {margin-left:6%; margin-right:6%;}
    .xs-margin-six-lr {margin-left:6.5%; margin-right:6.5%;}
    .xs-margin-seven-lr {margin-left:7%; margin-right:7%;}
    .xs-margin-seven-lr {margin-left:7.5%; margin-right:7.5%;}
    .xs-margin-eight-lr {margin-left:8%; margin-right:8%;}
    .xs-margin-eight-lr {margin-left:8.5%; margin-right:8.5%;}
    .xs-margin-nine-lr {margin-left:9%; margin-right:9%;}
    .xs-margin-nine-lr {margin-left:9.5%; margin-right:9.5%;}
    .xs-margin-ten-lr {margin-left:10%; margin-right:10%;}
    .xs-margin-ten-lr {margin-left:10.5%; margin-right:10.5%;}
    .xs-margin-eleven-lr {margin-left:11%; margin-right:11%;}
    .xs-margin-eleven-lr {margin-left:11.5%; margin-right:11.5%;}
    .xs-margin-twelve-lr {margin-left:12%; margin-right:12%;}
    .xs-margin-twelve-lr {margin-left:12.5%; margin-right:12.5%;}
    .xs-margin-thirteen-lr {margin-left:13%; margin-right:13%;}
    .xs-margin-thirteen-half-lr {margin-left:13.5%; margin-right:13.5%;}
    .xs-margin-fourteen-lr {margin-left:14%; margin-right:14%;}
    .xs-margin-fourteen-half-lr {margin-left:14.5%; margin-right:14.5%;}
    .xs-margin-fifteen-lr {margin-left:15%; margin-right:15%;}
    .xs-margin-fifteen-half-lr {margin-left:15.5%; margin-right:15.5%;}
    .xs-margin-sixteen-lr {margin-left:16%; margin-right:16%;}
    .xs-margin-sixteen-half-lr {margin-left:16.5%; margin-right:16.5%;}
    .xs-margin-seventeen-lr {margin-left:17%; margin-right:17%;}
    .xs-margin-seventeen-half-lr {margin-left:17.5%; margin-right:17.5%;}
    .xs-margin-eighteen-lr {margin-left:18%; margin-right:18%;}
    .xs-margin-eighteen-half-lr {margin-left:18.5%; margin-right:18.5%;}
    .xs-margin-nineteen-lr {margin-left:19%; margin-right:19%;}
    .xs-margin-nineteen-half-lr {margin-left:19.5%; margin-right:19.5%;}
    .xs-margin-twenty-lr {margin-left:20%; margin-right:20%;}
    .xs-margin-twenty-half-lr {margin-left:20.5%; margin-right:20.5%;}
    .xs-margin-twenty-one-lr {margin-left:21%; margin-right:21%;}
    .xs-margin-twenty-one-half-lr {margin-left:21.5%; margin-right:21.5%;}
    .xs-margin-twenty-two-lr {margin-left:22%; margin-right:22%;}
    .xs-margin-twenty-two-half-lr {margin-left:22.5%; margin-right:22.5%;}
    .xs-margin-twenty-three-lr {margin-left:23%; margin-right:23%;}
    .xs-margin-twenty-three-half-lr {margin-left:23.5%; margin-right:23.5%;}
    .xs-margin-twenty-four-lr {margin-left:24%; margin-right:24%;}
    .xs-margin-twenty-four-half-lr {margin-left:24.5%; margin-right:24.5%;}
    .xs-margin-twenty-five-lr {margin-left:25%; margin-right:25%;}
    .xs-margin-5px-lr {margin-left:5px; margin-right:5px;}
    .xs-margin-10px-lr {margin-left:10px; margin-right:10px;}
    .xs-margin-15px-lr {margin-left:15px; margin-right:15px;}
    .xs-margin-20px-lr {margin-left:20px; margin-right:20px;}
    .xs-margin-25px-lr {margin-left:25px; margin-right:25px;}
    .xs-margin-30px-lr {margin-left:30px; margin-right:30px;}
    .xs-margin-35px-lr {margin-left:35px; margin-right:35px;}
    .xs-margin-40px-lr {margin-left:40px; margin-right:40px;}
    .xs-margin-45px-lr {margin-left:45px; margin-right:45px;}
    .xs-margin-50px-lr {margin-left:50px; margin-right:50px;}
    .xs-margin-55px-lr {margin-left:55px; margin-right:55px;}
    .xs-margin-60px-lr {margin-left:60px; margin-right:60px;}
    .xs-margin-65px-lr {margin-left:65px; margin-right:65px;}
    .xs-margin-70px-lr {margin-left:70px; margin-right:70px;}
    .xs-margin-75px-lr {margin-left:75px; margin-right:75px;}
    .xs-margin-80px-lr {margin-left:80px; margin-right:80px;}
    .xs-margin-85px-lr {margin-left:85px; margin-right:85px;}
    .xs-margin-90px-lr {margin-left:90px; margin-right:90px;}
    .xs-margin-95px-lr {margin-left:95px; margin-right:95px;}
    .xs-margin-100px-lr {margin-left:100px; margin-right:100px;}

    /* margin top bottom */
    .xs-margin-one-tb {margin-top:1%; margin-bottom:1%;}
    .xs-margin-one-tb {margin-top:1.5%; margin-bottom:1.5%;}
    .xs-margin-two-tb {margin-top:2%; margin-bottom:2%;}
    .xs-margin-two-tb {margin-top:2.5%; margin-bottom:2.5%;}
    .xs-margin-three-tb {margin-top:3%; margin-bottom:3%;}
    .xs-margin-three-tb {margin-top:3.5%; margin-bottom:3.5%;}
    .xs-margin-four-tb {margin-top:4%; margin-bottom:4%;}
    .xs-margin-four-tb {margin-top:4.5%; margin-bottom:4.5%;}
    .xs-margin-five-tb {margin-top:5%; margin-bottom:5%;}
    .xs-margin-five-tb {margin-top:5.5%; margin-bottom:5.5%;}
    .xs-margin-six-tb {margin-top:6%; margin-bottom:6%;}
    .xs-margin-six-tb {margin-top:6.5%; margin-bottom:6.5%;}
    .xs-margin-seven-tb {margin-top:7%; margin-bottom:7%;}
    .xs-margin-seven-tb {margin-top:7.5%; margin-bottom:7.5%;}
    .xs-margin-eight-tb {margin-top:8%; margin-bottom:8%;}
    .xs-margin-eight-tb {margin-top:8.5%; margin-bottom:8.5%;}
    .xs-margin-nine-tb {margin-top:9%; margin-bottom:9%;}
    .xs-margin-nine-tb {margin-top:9.5%; margin-bottom:9.5%;}
    .xs-margin-ten-tb {margin-top:10%; margin-bottom:10%;}
    .xs-margin-ten-tb {margin-top:10.5%; margin-bottom:10.5%;}
    .xs-margin-eleven-tb {margin-top:11%; margin-bottom:11%;}
    .xs-margin-eleven-tb {margin-top:11.5%; margin-bottom:11.5%;}
    .xs-margin-twelve-tb {margin-top:12%; margin-bottom:12%;}
    .xs-margin-twelve-tb {margin-top:12.5%; margin-bottom:12.5%;}
    .xs-margin-thirteen-tb {margin-top:13%; margin-bottom:13%;}
    .xs-margin-thirteen-half-tb {margin-top:13.5%; margin-bottom:13.5%;}
    .xs-margin-fourteen-tb {margin-top:14%; margin-bottom:14%;}
    .xs-margin-fourteen-half-tb {margin-top:14.5%; margin-bottom:14.5%;}
    .xs-margin-fifteen-tb {margin-top:15%; margin-bottom:15%;}
    .xs-margin-fifteen-half-tb {margin-top:15.5%; margin-bottom:15.5%;}
    .xs-margin-sixteen-tb {margin-top:16%; margin-bottom:16%;}
    .xs-margin-sixteen-half-tb {margin-top:16.5%; margin-bottom:16.5%;}
    .xs-margin-seventeen-tb {margin-top:17%; margin-bottom:17%;}
    .xs-margin-seventeen-half-tb {margin-top:17.5%; margin-bottom:17.5%;}
    .xs-margin-eighteen-tb {margin-top:18%; margin-bottom:18%;}
    .xs-margin-eighteen-half-tb {margin-top:18.5%; margin-bottom:18.5%;}
    .xs-margin-nineteen-tb {margin-top:19%; margin-bottom:19%;}
    .xs-margin-nineteen-half-tb {margin-top:19.5%; margin-bottom:19.5%;}
    .xs-margin-twenty-tb {margin-top:20%; margin-bottom:20%;}
    .xs-margin-twenty-half-tb {margin-top:20.5%; margin-bottom:20.5%;}
    .xs-margin-twenty-one-tb {margin-top:21%; margin-bottom:21%;}
    .xs-margin-twenty-one-half-tb {margin-top:21.5%; margin-bottom:21.5%;}
    .xs-margin-twenty-two-tb {margin-top:22%; margin-bottom:22%;}
    .xs-margin-twenty-two-half-tb {margin-top:22.5%; margin-bottom:22.5%;}
    .xs-margin-twenty-three-tb {margin-top:23%; margin-bottom:23%;}
    .xs-margin-twenty-three-half-tb {margin-top:23.5%; margin-bottom:23.5%;}
    .xs-margin-twenty-four-tb {margin-top:24%; margin-bottom:24%;}
    .xs-margin-twenty-four-half-tb {margin-top:24.5%; margin-bottom:24.5%;}
    .xs-margin-twenty-five-tb {margin-top:25%; margin-bottom:25%;}
    .xs-margin-5px-tb {margin-top:5px; margin-bottom:5px;}
    .xs-margin-10px-tb {margin-top:10px; margin-bottom:10px;}
    .xs-margin-15px-tb {margin-top:15px; margin-bottom:15px;}
    .xs-margin-20px-tb {margin-top:20px; margin-bottom:20px;}
    .xs-margin-25px-tb {margin-top:25px; margin-bottom:25px;}
    .xs-margin-30px-tb {margin-top:30px; margin-bottom:30px;}
    .xs-margin-35px-tb {margin-top:35px; margin-bottom:35px;}
    .xs-margin-40px-tb {margin-top:40px; margin-bottom:40px;}
    .xs-margin-45px-tb {margin-top:45px; margin-bottom:45px;}
    .xs-margin-50px-tb {margin-top:50px; margin-bottom:50px;}
    .xs-margin-55px-tb {margin-top:55px; margin-bottom:55px;}
    .xs-margin-60px-tb {margin-top:60px; margin-bottom:60px;}
    .xs-margin-65px-tb {margin-top:65px; margin-bottom:65px;}
    .xs-margin-70px-tb {margin-top:70px; margin-bottom:70px;}
    .xs-margin-75px-tb {margin-top:75px; margin-bottom:75px;}
    .xs-margin-80px-tb {margin-top:80px; margin-bottom:80px;}
    .xs-margin-85px-tb {margin-top:85px; margin-bottom:85px;}
    .xs-margin-90px-tb {margin-top:90px; margin-bottom:90px;}
    .xs-margin-95px-tb {margin-top:95px; margin-bottom:95px;}
    .xs-margin-100px-tb {margin-top:100px; margin-bottom:100px;}

    /* padding */
    .xs-no-padding {padding:0 !important}
    .xs-no-padding-lr {padding-left: 0 !important; padding-right: 0 !important}
    .xs-no-padding-tb {padding-top: 0 !important; padding-bottom: 0 !important}
    .xs-no-padding-top {padding-top:0 !important}
    .xs-no-padding-bottom {padding-bottom:0 !important}
    .xs-no-padding-left {padding-left:0 !important}
    .xs-no-padding-right {padding-right:0 !important}
    .xs-padding-one-all {padding:1%;}
    .xs-padding-one-half-all {padding:1.5%;}
    .xs-padding-two-all {padding:2%;}
    .xs-padding-two-half-all {padding:2.5%;}
    .xs-padding-three-all {padding:3%;}
    .xs-padding-three-half-all {padding:3.5%;}
    .xs-padding-four-all {padding:4%;}
    .xs-padding-four-half-all {padding:4.5%;}
    .xs-padding-five-all {padding:5%;}
    .xs-padding-five-half-all {padding:5.5%;}
    .xs-padding-six-all {padding:6%;}
    .xs-padding-six-half-all {padding:6.5%;}
    .xs-padding-seven-all {padding:7%;}
    .xs-padding-seven-half-all {padding:7.5%;}
    .xs-padding-eight-all {padding:8%;}
    .xs-padding-eight-half-all {padding:8.5%;}
    .xs-padding-nine-all {padding:9%;}
    .xs-padding-nine-half-all {padding:9.5%;}
    .xs-padding-ten-all {padding:10%;}
    .xs-padding-ten-half-all {padding:10.5%;}
    .xs-padding-eleven-all {padding:11%;}
    .xs-padding-eleven-half-all {padding:11.5%;}
    .xs-padding-twelve-all {padding:12%;}
    .xs-padding-twelve-half-all {padding:12.5%;}
    .xs-padding-thirteen-all {padding:13%;}
    .xs-padding-thirteen-half-all {padding:13.5%;}
    .xs-padding-fourteen-all {padding:14%;}
    .xs-padding-fourteen-half-all {padding:14.5%;}
    .xs-padding-fifteen-all {padding:15%;}
    .xs-padding-fifteen-half-all {padding:15.5%;}
    .xs-padding-sixteen-all {padding:16%;}
    .xs-padding-sixteen-half-all {padding:16.5%;}
    .xs-padding-seventeen-all {padding:17%;}
    .xs-padding-seventeen-half-all {padding:17.5%;}
    .xs-padding-eighteen-all {padding:18%;}
    .xs-padding-eighteen-half-all {padding:18.5%;}
    .xs-padding-nineteen-all {padding:19%;}
    .xs-padding-nineteen-half-all {padding:19.5%;}
    .xs-padding-twenty-all {padding:20%;}
    .xs-padding-twenty-half-all {padding:20.5%;}
    .xs-padding-twenty-one-all {padding:21%;}
    .xs-padding-twenty-one-half-all {padding:21.5%;}
    .xs-padding-twenty-two-all {padding:22%;}
    .xs-padding-twenty-two-half-all {padding:22.5%;}
    .xs-padding-twenty-three-all {padding:23%;}
    .xs-padding-twenty-three-half-all {padding:23.5%;}
    .xs-padding-twenty-four-all {padding:24%;}
    .xs-padding-twenty-four-half-all {padding:24.5%;}
    .xs-padding-twenty-five-all {padding:25%;}
    .xs-padding-5px-all {padding:5px;}
    .xs-padding-10px-all {padding:10px;}
    .xs-padding-15px-all {padding:15px;}
    .xs-padding-20px-all {padding:20px;}
    .xs-padding-25px-all {padding:25px;}
    .xs-padding-30px-all {padding:30px;}
    .xs-padding-35px-all {padding:35px;}
    .xs-padding-40px-all {padding:40px;}
    .xs-padding-45px-all {padding:45px;}
    .xs-padding-50px-all {padding:50px;}
    .xs-padding-55px-all {padding:55px;}
    .xs-padding-60px-all {padding:60px;}
    .xs-padding-65px-all {padding:65px;}
    .xs-padding-70px-all {padding:70px;}
    .xs-padding-75px-all {padding:75px;}
    .xs-padding-80px-all {padding:80px;}
    .xs-padding-85px-all {padding:85px;}
    .xs-padding-90px-all {padding:90px;}
    .xs-padding-95px-all {padding:95px;}
    .xs-padding-100px-all {padding:100px;}

    /* padding top */
    .xs-padding-one-top {padding-top:1%;}
    .xs-padding-one-half-top {padding-top:1.5%;}
    .xs-padding-two-top {padding-top:2%;}
    .xs-padding-two-half-top {padding-top:2.5%;}
    .xs-padding-three-top {padding-top:3%;}
    .xs-padding-three-half-top {padding-top:3.5%;}
    .xs-padding-four-top {padding-top:4%;}
    .xs-padding-four-half-top {padding-top:4.5%;}
    .xs-padding-five-top {padding-top:5%;}
    .xs-padding-five-half-top {padding-top:5.5%;}
    .xs-padding-six-top {padding-top:6%;}
    .xs-padding-six-half-top {padding-top:6.5%;}
    .xs-padding-seven-top {padding-top:7%;}
    .xs-padding-seven-half-top {padding-top:7.5%;}
    .xs-padding-eight-top {padding-top:8%;}
    .xs-padding-eight-half-top {padding-top:8.5%;}
    .xs-padding-nine-top {padding-top:9%;}
    .xs-padding-nine-half-top {padding-top:9.5%;}
    .xs-padding-ten-top {padding-top:10%;}
    .xs-padding-ten-half-top {padding-top:10.5%;}
    .xs-padding-eleven-top {padding-top:11%;}
    .xs-padding-eleven-half-top {padding-top:11.5%;}
    .xs-padding-twelve-top {padding-top:12%;}
    .xs-padding-twelve-half-top {padding-top:12.5%;}
    .xs-padding-thirteen-top {padding-top:13%;}
    .xs-padding-thirteen-half-top {padding-top:13.5%;}
    .xs-padding-fourteen-top {padding-top:14%;}
    .xs-padding-fourteen-half-top {padding-top:14.5%;}
    .xs-padding-fifteen-top {padding-top:15%;}
    .xs-padding-fifteen-half-top {padding-top:15.5%;}
    .xs-padding-sixteen-top {padding-top:16%;}
    .xs-padding-sixteen-half-top {padding-top:16.5%;}
    .xs-padding-seventeen-top {padding-top:17%;}
    .xs-padding-seventeen-half-top {padding-top:17.5%;}
    .xs-padding-eighteen-top {padding-top:18%;}
    .xs-padding-eighteen-half-top {padding-top:18.5%;}
    .xs-padding-nineteen-top {padding-top:19%;}
    .xs-padding-nineteen-half-top {padding-top:19.5%;}
    .xs-padding-twenty-top {padding-top:20%;}
    .xs-padding-twenty-half-top {padding-top:20.5%;}
    .xs-padding-twenty-one-top {padding-top:21%;}
    .xs-padding-twenty-one-half-top {padding-top:21.5%;}
    .xs-padding-twenty-two-top {padding-top:22%;}
    .xs-padding-twenty-two-half-top {padding-top:22.5%;}
    .xs-padding-twenty-three-top {padding-top:23%;}
    .xs-padding-twenty-three-half-top {padding-top:23.5%;}
    .xs-padding-twenty-four-top {padding-top:24%;}
    .xs-padding-twenty-four-half-top {padding-top:24.5%;}
    .xs-padding-twenty-five-top {padding-top:25%;}
    .xs-padding-5px-top {padding-top:5px;}
    .xs-padding-10px-top {padding-top:10px;}
    .xs-padding-15px-top {padding-top:15px !important;}
    .xs-padding-20px-top {padding-top:20px;}
    .xs-padding-25px-top {padding-top:25px;}
    .xs-padding-30px-top {padding-top:30px;}
    .xs-padding-35px-top {padding-top:35px;}
    .xs-padding-40px-top {padding-top:40px;}
    .xs-padding-45px-top {padding-top:45px;}
    .xs-padding-50px-top {padding-top:50px;}
    .xs-padding-55px-top {padding-top:55px;}
    .xs-padding-60px-top {padding-top:60px;}
    .xs-padding-65px-top {padding-top:65px;}
    .xs-padding-70px-top {padding-top:70px;}
    .xs-padding-75px-top {padding-top:75px;}
    .xs-padding-80px-top {padding-top:80px;}
    .xs-padding-85px-top {padding-top:85px;}
    .xs-padding-90px-top {padding-top:90px;}
    .xs-padding-95px-top {padding-top:95px;}
    .xs-padding-100px-top {padding-top:100px;}

    /* padding bottom */
    .xs-padding-one-bottom {padding-bottom:1%;}
    .xs-padding-one-half-bottom {padding-bottom:1.5%;}
    .xs-padding-two-bottom {padding-bottom:2%;}
    .xs-padding-two-half-bottom {padding-bottom:2.5%;}
    .xs-padding-three-bottom {padding-bottom:3%;}
    .xs-padding-three-half-bottom {padding-bottom:3.5%;}
    .xs-padding-four-bottom {padding-bottom:4%;}
    .xs-padding-four-half-bottom {padding-bottom:4.5%;}
    .xs-padding-five-bottom {padding-bottom:5%;}
    .xs-padding-five-half-bottom {padding-bottom:5.5%;}
    .xs-padding-six-bottom {padding-bottom:6%;}
    .xs-padding-six-half-bottom {padding-bottom:6.5%;}
    .xs-padding-seven-bottom {padding-bottom:7%;}
    .xs-padding-seven-half-bottom {padding-bottom:7.5%;}
    .xs-padding-eight-bottom {padding-bottom:8%;}
    .xs-padding-eight-half-bottom {padding-bottom:8.5%;}
    .xs-padding-nine-bottom {padding-bottom:9%;}
    .xs-padding-nine-half-bottom {padding-bottom:9.5%;}
    .xs-padding-ten-bottom {padding-bottom:10%;}
    .xs-padding-ten-half-bottom {padding-bottom:10.5%;}
    .xs-padding-eleven-bottom {padding-bottom:11%;}
    .xs-padding-eleven-half-bottom {padding-bottom:11.5%;}
    .xs-padding-twelve-bottom {padding-bottom:12%;}
    .xs-padding-twelve-half-bottom {padding-bottom:12.5%;}
    .xs-padding-thirteen-bottom {padding-bottom:13%;}
    .xs-padding-thirteen-half-bottom {padding-bottom:13.5%;}
    .xs-padding-fourteen-bottom {padding-bottom:14%;}
    .xs-padding-fourteen-half-bottom {padding-bottom:14.5%;}
    .xs-padding-fifteen-bottom {padding-bottom:15%;}
    .xs-padding-fifteen-half-bottom {padding-bottom:15.5%;}
    .xs-padding-sixteen-bottom {padding-bottom:16%;}
    .xs-padding-sixteen-half-bottom {padding-bottom:16.5%;}
    .xs-padding-seventeen-bottom {padding-bottom:17%;}
    .xs-padding-seventeen-half-bottom {padding-bottom:17.5%;}
    .xs-padding-eighteen-bottom {padding-bottom:18%;}
    .xs-padding-eighteen-half-bottom {padding-bottom:18.5%;}
    .xs-padding-nineteen-bottom {padding-bottom:19%;}
    .xs-padding-nineteen-half-bottom {padding-bottom:19.5%;}
    .xs-padding-twenty-bottom {padding-bottom:20%;}
    .xs-padding-twenty-half-bottom {padding-bottom:20.5%;}
    .xs-padding-twenty-one-bottom {padding-bottom:21%;}
    .xs-padding-twenty-one-half-bottom {padding-bottom:21.5%;}
    .xs-padding-twenty-two-bottom {padding-bottom:22%;}
    .xs-padding-twenty-two-half-bottom {padding-bottom:22.5%;}
    .xs-padding-twenty-three-bottom {padding-bottom:23%;}
    .xs-padding-twenty-three-half-bottom {padding-bottom:23.5%;}
    .xs-padding-twenty-four-bottom {padding-bottom:24%;}
    .xs-padding-twenty-four-half-bottom {padding-bottom:24.5%;}
    .xs-padding-twenty-five-bottom {padding-bottom:25%;}
    .xs-padding-5px-bottom {padding-bottom:5px;}
    .xs-padding-10px-bottom {padding-bottom:10px;}
    .xs-padding-15px-bottom {padding-bottom:15px;}
    .xs-padding-20px-bottom {padding-bottom:20px;}
    .xs-padding-25px-bottom {padding-bottom:25px;}
    .xs-padding-30px-bottom {padding-bottom:30px;}
    .xs-padding-35px-bottom {padding-bottom:35px;}
    .xs-padding-40px-bottom {padding-bottom:40px;}
    .xs-padding-45px-bottom {padding-bottom:45px;}
    .xs-padding-50px-bottom {padding-bottom:50px;}
    .xs-padding-55px-bottom {padding-bottom:55px;}
    .xs-padding-60px-bottom {padding-bottom:60px;}
    .xs-padding-65px-bottom {padding-bottom:65px;}
    .xs-padding-70px-bottom {padding-bottom:70px;}
    .xs-padding-75px-bottom {padding-bottom:75px;}
    .xs-padding-80px-bottom {padding-bottom:80px;}
    .xs-padding-85px-bottom {padding-bottom:85px;}
    .xs-padding-90px-bottom {padding-bottom:90px;}
    .xs-padding-95px-bottom {padding-bottom:95px;}
    .xs-padding-100px-bottom {padding-bottom:100px;}

    /* padding right */
    .xs-padding-one-right {padding-right:1%;}
    .xs-padding-one-half-right {padding-right:1.5%;}
    .xs-padding-two-right {padding-right:2%;}
    .xs-padding-two-half-right {padding-right:2.5%;}
    .xs-padding-three-right {padding-right:3%;}
    .xs-padding-three-half-right {padding-right:3.5%;}
    .xs-padding-four-right {padding-right:4%;}
    .xs-padding-four-half-right {padding-right:4.5%;}
    .xs-padding-five-right {padding-right:5%;}
    .xs-padding-five-half-right {padding-right:5.5%;}
    .xs-padding-six-right {padding-right:6%;}
    .xs-padding-six-half-right {padding-right:6.5%;}
    .xs-padding-seven-right {padding-right:7%;}
    .xs-padding-seven-half-right {padding-right:7.5%;}
    .xs-padding-eight-right {padding-right:8%;}
    .xs-padding-eight-half-right {padding-right:8.5%;}
    .xs-padding-nine-right {padding-right:9%;}
    .xs-padding-nine-half-right {padding-right:9.5%;}
    .xs-padding-ten-right {padding-right:10%;}
    .xs-padding-ten-half-right {padding-right:10.5%;}
    .xs-padding-eleven-right {padding-right:11%;}
    .xs-padding-eleven-half-right {padding-right:11.5%;}
    .xs-padding-twelve-right {padding-right:12%;}
    .xs-padding-twelve-half-right {padding-right:12.5%;}
    .xs-padding-thirteen-right {padding-right:13%;}
    .xs-padding-thirteen-half-right {padding-right:13.5%;}
    .xs-padding-fourteen-right {padding-right:14%;}
    .xs-padding-fourteen-half-right {padding-right:14.5%;}
    .xs-padding-fifteen-right {padding-right:15%;}
    .xs-padding-fifteen-half-right {padding-right:15.5%;}
    .xs-padding-sixteen-right {padding-right:16%;}
    .xs-padding-sixteen-half-right {padding-right:16.5%;}
    .xs-padding-seventeen-right {padding-right:17%;}
    .xs-padding-seventeen-half-right {padding-right:17.5%;}
    .xs-padding-eighteen-right {padding-right:18%;}
    .xs-padding-eighteen-half-right {padding-right:18.5%;}
    .xs-padding-nineteen-right {padding-right:19%;}
    .xs-padding-nineteen-half-right {padding-right:19.5%;}
    .xs-padding-twenty-right {padding-right:20%;}
    .xs-padding-twenty-hlaf-right {padding-right:20.5%;}
    .xs-padding-twenty-one-right {padding-right:21%;}
    .xs-padding-twenty-one-hlaf-right {padding-right:21.5%;}
    .xs-padding-twenty-two-right {padding-right:22%;}
    .xs-padding-twenty-two-hlaf-right {padding-right:22.5%;}
    .xs-padding-twenty-three-right {padding-right:23%;}
    .xs-padding-twenty-three-hlaf-right {padding-right:23.5%;}
    .xs-padding-twenty-four-right {padding-right:24%;}
    .xs-padding-twenty-four-hlaf-right {padding-right:24.5%;}
    .xs-padding-twenty-five-right {padding-right:25%;}
    .xs-padding-5px-right {padding-right:5px;}
    .xs-padding-10px-right {padding-right:10px;}
    .xs-padding-15px-right {padding-right:15px;}
    .xs-padding-20px-right {padding-right:20px;}
    .xs-padding-25px-right {padding-right:25px;}
    .xs-padding-30px-right {padding-right:30px;}
    .xs-padding-35px-right {padding-right:35px;}
    .xs-padding-40px-right {padding-right:40px;}
    .xs-padding-45px-right {padding-right:45px;}
    .xs-padding-50px-right {padding-right:50px;}
    .xs-padding-55px-right {padding-right:55px;}
    .xs-padding-60px-right {padding-right:60px;}
    .xs-padding-65px-right {padding-right:65px;}
    .xs-padding-70px-right {padding-right:70px;}
    .xs-padding-75px-right {padding-right:75px;}
    .xs-padding-80px-right {padding-right:80px;}
    .xs-padding-85px-right {padding-right:85px;}
    .xs-padding-90px-right {padding-right:90px;}
    .xs-padding-95px-right {padding-right:95px;}
    .xs-padding-100px-right {padding-right:100px;}

    /* padding left */
    .xs-padding-one-left {padding-left:1%;}
    .xs-padding-one-half-left {padding-left:1.5%;}
    .xs-padding-two-left {padding-left:2%;}
    .xs-padding-two-half-left {padding-left:2.5%;}
    .xs-padding-three-left {padding-left:3%;}
    .xs-padding-three-half-left {padding-left:3.5%;}
    .xs-padding-four-left {padding-left:4%;}
    .xs-padding-four-half-left {padding-left:4.5%;}
    .xs-padding-five-left {padding-left:5%;}
    .xs-padding-five-half-left {padding-left:5.5%;}
    .xs-padding-six-left {padding-left:6%;}
    .xs-padding-six-half-left {padding-left:6.5%;}
    .xs-padding-seven-left {padding-left:7%;}
    .xs-padding-seven-half-left {padding-left:7.5%;}
    .xs-padding-eight-left {padding-left:8%;}
    .xs-padding-eight-half-left {padding-left:8.5%;}
    .xs-padding-nine-left {padding-left:9%;}
    .xs-padding-nine-half-left {padding-left:9.5%;}
    .xs-padding-ten-left {padding-left:10%;}
    .xs-padding-ten-half-left {padding-left:10.5%;}
    .xs-padding-eleven-left {padding-left:11%;}
    .xs-padding-eleven-half-left {padding-left:11.5%;}
    .xs-padding-twelve-left {padding-left:12%;}
    .xs-padding-twelve-half-left {padding-left:12.5%;}
    .xs-padding-thirteen-left {padding-left:13%;}
    .xs-padding-thirteen-half-left {padding-left:13.5%;}
    .xs-padding-fourteen-left {padding-left:14%;}
    .xs-padding-fourteen-half-left {padding-left:14.5%;}
    .xs-padding-fifteen-left {padding-left:15%;}
    .xs-padding-fifteen-half-left {padding-left:15.5%;}
    .xs-padding-sixteen-left {padding-left:16%;}
    .xs-padding-sixteen-half-left {padding-left:16.5%;}
    .xs-padding-seventeen-left {padding-left:17%;}
    .xs-padding-seventeen-half-left {padding-left:17.5%;}
    .xs-padding-eighteen-left {padding-left:18%;}
    .xs-padding-eighteen-half-left {padding-left:18.5%;}
    .xs-padding-nineteen-left {padding-left:19%;}
    .xs-padding-nineteen-half-left {padding-left:19.5%;}
    .xs-padding-twenty-left {padding-left:20%;}
    .xs-padding-twenty-half-left {padding-left:20.5%;}
    .xs-padding-twenty-one-left {padding-left:21%;}
    .xs-padding-twenty-one-half-left {padding-left:21.5%;}
    .xs-padding-twenty-two-left {padding-left:22%;}
    .xs-padding-twenty-two-half-left {padding-left:22.5%;}
    .xs-padding-twenty-three-left {padding-left:23%;}
    .xs-padding-twenty-three-half-left {padding-left:23.5%;}
    .xs-padding-twenty-four-left {padding-left:24%;}
    .xs-padding-twenty-four-half-left {padding-left:24.5%;}
    .xs-padding-twenty-five-left {padding-left:25%;}
    .xs-padding-5px-left {padding-left:5px;}
    .xs-padding-10px-left {padding-left:10px;}
    .xs-padding-15px-left {padding-left:15px;}
    .xs-padding-20px-left {padding-left:20px;}
    .xs-padding-25px-left {padding-left:25px;}
    .xs-padding-30px-left {padding-left:30px;}
    .xs-padding-35px-left {padding-left:35px;}
    .xs-padding-40px-left {padding-left:40px;}
    .xs-padding-45px-left {padding-left:45px;}
    .xs-padding-50px-left {padding-left:50px;}
    .xs-padding-55px-left {padding-left:55px;}
    .xs-padding-60px-left {padding-left:60px;}
    .xs-padding-65px-left {padding-left:65px;}
    .xs-padding-70px-left {padding-left:70px;}
    .xs-padding-75px-left {padding-left:75px;}
    .xs-padding-80px-left {padding-left:80px;}
    .xs-padding-85px-left {padding-left:85px;}
    .xs-padding-90px-left {padding-left:90px;}
    .xs-padding-95px-left {padding-left:95px;}
    .xs-padding-100px-left {padding-left:100px;}

    /* padding top bottom */
    .xs-padding-one-tb {padding-top:1%; padding-bottom:1%;}
    .xs-padding-one-half-tb {padding-top:1.5%; padding-bottom:1.5%;}
    .xs-padding-two-tb {padding-top:2%; padding-bottom:2%;}
    .xs-padding-two-half-tb {padding-top:2.5%; padding-bottom:2.5%;}
    .xs-padding-three-tb {padding-top:3%; padding-bottom:3%;}
    .xs-padding-three-half-tb {padding-top:3.5%; padding-bottom:3.5%;}
    .xs-padding-four-tb {padding-top:4%; padding-bottom:4%;}
    .xs-padding-four-half-tb {padding-top:4.5%; padding-bottom:4.5%;}
    .xs-padding-five-tb {padding-top:5%; padding-bottom:5%;}
    .xs-padding-five-half-tb {padding-top:5.5%; padding-bottom:5.5%;}
    .xs-padding-six-tb {padding-top:6%; padding-bottom:6%;}
    .xs-padding-six-half-tb {padding-top:6.5%; padding-bottom:6.5%;}
    .xs-padding-seven-tb {padding-top:7%; padding-bottom:7%;}
    .xs-padding-seven-half-tb {padding-top:7.5%; padding-bottom:7.5%;}
    .xs-padding-eight-tb {padding-top:8%; padding-bottom:8%;}
    .xs-padding-eight-half-tb {padding-top:8.5%; padding-bottom:8.5%;}
    .xs-padding-nine-tb {padding-top:9%; padding-bottom:9%;}
    .xs-padding-nine-half-tb {padding-top:9.5%; padding-bottom:9.5%;}
    .xs-padding-ten-tb {padding-top:10%; padding-bottom:10%;}
    .xs-padding-ten-half-tb {padding-top:10.5%; padding-bottom:10.5%;}
    .xs-padding-eleven-tb {padding-top:11%; padding-bottom:11%;}
    .xs-padding-eleven-half-tb {padding-top:11.5%; padding-bottom:11.5%;}
    .xs-padding-twelve-tb {padding-top:12%; padding-bottom:12%;}
    .xs-padding-twelve-half-tb {padding-top:12.5%; padding-bottom:12.5%;}
    .xs-padding-thirteen-tb {padding-top:13%; padding-bottom:13%;}
    .xs-padding-thirteen-half-tb {padding-top:13.5%; padding-bottom:13.5%;}
    .xs-padding-fourteen-tb {padding-top:14%; padding-bottom:14%;}
    .xs-padding-fourteen-half-tb {padding-top:14.5%; padding-bottom:14.5%;}
    .xs-padding-fifteen-tb {padding-top:15%; padding-bottom:15%;}
    .xs-padding-fifteen-half-tb {padding-top:15.5%; padding-bottom:15.5%;}
    .xs-padding-sixteen-tb {padding-top:16%; padding-bottom:16%;}
    .xs-padding-sixteen-half-tb {padding-top:16.5%; padding-bottom:16.5%;}
    .xs-padding-seventeen-tb {padding-top:17%; padding-bottom:17%;}
    .xs-padding-seventeen-half-tb {padding-top:17.5%; padding-bottom:17.5%;}
    .xs-padding-eighteen-tb {padding-top:18%; padding-bottom:18%;}
    .xs-padding-eighteen-half-tb {padding-top:18.5%; padding-bottom:18.5%;}
    .xs-padding-nineteen-tb {padding-top:19%; padding-bottom:19%;}
    .xs-padding-nineteen-half-tb {padding-top:19.5%; padding-bottom:19.5%;}
    .xs-padding-twenty-tb {padding-top:20%; padding-bottom:20%;}
    .xs-padding-twenty-one-half-tb {padding-top:20.5%; padding-bottom:20.5%;}
    .xs-padding-twenty-one-tb {padding-top:21%; padding-bottom:21%;}
    .xs-padding-twenty-half-tb {padding-top:21.5%; padding-bottom:21.5%;}
    .xs-padding-twenty-two-tb {padding-top:22%; padding-bottom:22%;}
    .xs-padding-twenty-two-half-tb {padding-top:22.5%; padding-bottom:22.5%;}
    .xs-padding-twenty-three-tb {padding-top:23%; padding-bottom:23%;}
    .xs-padding-twenty-three-half-tb {padding-top:23.5%; padding-bottom:23.5%;}
    .xs-padding-twenty-four-tb {padding-top:24%; padding-bottom:24%;}
    .xs-padding-twenty-four-half-tb {padding-top:24.5%; padding-bottom:24.5%;}
    .xs-padding-twenty-five-tb {padding-top:25%; padding-bottom:25%;}
    .xs-padding-5px-tb {padding-top:5px; padding-bottom:5px;}
    .xs-padding-10px-tb {padding-top:10px; padding-bottom:10px;}
    .xs-padding-15px-tb {padding-top:15px !important; padding-bottom:15px !important;}
    .xs-padding-20px-tb {padding-top:20px; padding-bottom:20px;}
    .xs-padding-25px-tb {padding-top:25px; padding-bottom:25px;}
    .xs-padding-30px-tb {padding-top:30px; padding-bottom:30px;}
    .xs-padding-35px-tb {padding-top:35px; padding-bottom:35px;}
    .xs-padding-40px-tb {padding-top:40px; padding-bottom:40px;}
    .xs-padding-45px-tb {padding-top:45px; padding-bottom:45px;}
    .xs-padding-50px-tb {padding-top:50px; padding-bottom:50px;}
    .xs-padding-55px-tb {padding-top:55px; padding-bottom:55px;}
    .xs-padding-60px-tb {padding-top:60px; padding-bottom:60px;}
    .xs-padding-65px-tb {padding-top:65px; padding-bottom:65px;}
    .xs-padding-70px-tb {padding-top:70px; padding-bottom:70px;}
    .xs-padding-75px-tb {padding-top:75px; padding-bottom:75px;}
    .xs-padding-80px-tb {padding-top:80px; padding-bottom:80px;}
    .xs-padding-85px-tb {padding-top:85px; padding-bottom:85px;}
    .xs-padding-90px-tb {padding-top:90px; padding-bottom:90px;}
    .xs-padding-95px-tb {padding-top:95px; padding-bottom:95px;}
    .xs-padding-100px-tb {padding-top:100px; padding-bottom:100px;}

    /* padding left right */
    .xs-padding-one-lr {padding-left:1%; padding-right:1%;}
    .xs-padding-one-half-lr {padding-left:1.5%; padding-right:1.5%;}
    .xs-padding-two-lr {padding-left:2%; padding-right:2%;}
    .xs-padding-two-half-lr {padding-left:2.5%; padding-right:2.5%;}
    .xs-padding-three-lr {padding-left:3%; padding-right:3%;}
    .xs-padding-three-half-lr {padding-left:3.5%; padding-right:3.5%;}
    .xs-padding-four-lr {padding-left:4%; padding-right:4%;}
    .xs-padding-four-half-lr {padding-left:4.5%; padding-right:4.5%;}
    .xs-padding-five-lr {padding-left:5%; padding-right:5%;}
    .xs-padding-five-half-lr {padding-left:5.5%; padding-right:5.5%;}
    .xs-padding-six-lr {padding-left:6%; padding-right:6%;}
    .xs-padding-six-half-lr {padding-left:6.5%; padding-right:6.5%;}
    .xs-padding-seven-lr {padding-left:7%; padding-right:7%;}
    .xs-padding-seven-half-lr {padding-left:7.5%; padding-right:7.5%;}
    .xs-padding-eight-lr {padding-left:8%; padding-right:8%;}
    .xs-padding-eight-half-lr {padding-left:8.5%; padding-right:8.5%;}
    .xs-padding-nine-lr {padding-left:9%; padding-right:9%;}
    .xs-padding-nine-half-lr {padding-left:9.5%; padding-right:9.5%;}
    .xs-padding-ten-lr {padding-left:10%; padding-right:10%;}
    .xs-padding-ten-half-lr {padding-left:10.5%; padding-right:10.5%;}
    .xs-padding-eleven-lr {padding-left:11%; padding-right:11%;}
    .xs-padding-eleven-half-lr {padding-left:11.5%; padding-right:11.5%;}
    .xs-padding-twelve-lr {padding-left:12%; padding-right:12%;}
    .xs-padding-twelve-half-lr {padding-left:12.5%; padding-right:12.5%;}
    .xs-padding-thirteen-lr {padding-left:13%; padding-right:13%;}
    .xs-padding-thirteen-half-lr {padding-left:13.5%; padding-right:13.5%;}
    .xs-padding-fourteen-lr {padding-left:14%; padding-right:14%;}
    .xs-padding-fourteen-half-lr {padding-left:14.5%; padding-right:14.5%;}
    .xs-padding-fifteen-lr {padding-left:15%; padding-right:15%;}
    .xs-padding-fifteen-half-lr {padding-left:15.5%; padding-right:15.5%;}
    .xs-padding-sixteen-lr {padding-left:16%; padding-right:16%;}
    .xs-padding-sixteen-half-lr {padding-left:16.5%; padding-right:16.5%;}
    .xs-padding-seventeen-lr {padding-left:17%; padding-right:17%;}
    .xs-padding-seventeen-half-lr {padding-left:17.5%; padding-right:17.5%;}
    .xs-padding-eighteen-lr {padding-left:18%; padding-right:18%;}
    .xs-padding-eighteen-half-lr {padding-left:18.5%; padding-right:18.5%;}
    .xs-padding-nineteen-lr {padding-left:19%; padding-right:19%;}
    .xs-padding-nineteen-half-lr {padding-left:19.5%; padding-right:19.5%;}
    .xs-padding-twenty-lr {padding-left:20%; padding-right:20%;}
    .xs-padding-twenty-half-lr {padding-left:20.5%; padding-right:20.5%;}
    .xs-padding-twenty-one-lr {padding-left:21%; padding-right:21%;}
    .xs-padding-twenty-one-half-lr {padding-left:21.5%; padding-right:21.5%;}
    .xs-padding-twenty-two-lr {padding-left:22%; padding-right:22%;}
    .xs-padding-twenty-two-half-lr {padding-left:22.5%; padding-right:22.5%;}
    .xs-padding-twenty-three-lr {padding-left:23%; padding-right:23%;}
    .xs-padding-twenty-three-half-lr {padding-left:23.5%; padding-right:23.5%;}
    .xs-padding-twenty-four-lr {padding-left:24%; padding-right:24%;}
    .xs-padding-twenty-four-half-lr {padding-left:24.5%; padding-right:24.5%;}
    .xs-padding-twenty-lr {padding-left:25%; padding-right:25%;}
    .xs-padding-5px-lr {padding-left:5px; padding-right:5px;}
    .xs-padding-10px-lr {padding-left:10px; padding-right:10px;}
    .xs-padding-15px-lr {padding-left:15px; padding-right:15px;}
    .xs-padding-20px-lr {padding-left:20px; padding-right:20px;}
    .xs-padding-25px-lr {padding-left:25px; padding-right:25px;}
    .xs-padding-30px-lr {padding-left:30px; padding-right:30px;}
    .xs-padding-35px-lr {padding-left:35px; padding-right:35px;}
    .xs-padding-40px-lr {padding-left:40px; padding-right:40px;}
    .xs-padding-45px-lr {padding-left:45px; padding-right:45px;}
    .xs-padding-50px-lr {padding-left:50px; padding-right:50px;}
    .xs-padding-55px-lr {padding-left:55px; padding-right:55px;}
    .xs-padding-60px-lr {padding-left:60px; padding-right:60px;}
    .xs-padding-65px-lr {padding-left:65px; padding-right:65px;}
    .xs-padding-70px-lr {padding-left:70px; padding-right:70px;}
    .xs-padding-75px-lr {padding-left:75px; padding-right:75px;}
    .xs-padding-80px-lr {padding-left:80px; padding-right:80px;}
    .xs-padding-85px-lr {padding-left:85px; padding-right:85px;}
    .xs-padding-90px-lr {padding-left:90px; padding-right:90px;}
    .xs-padding-95px-lr {padding-left:95px; padding-right:95px;}
    .xs-padding-100px-lr {padding-left:100px; padding-right:100px;}

    /* width */
    .xs-w-10 {width:10% !important;}
    .xs-w-15 {width:15% !important;}
    .xs-w-20 {width:20% !important;}
    .xs-w-25 {width:25% !important;}
    .xs-w-30 {width:30% !important;}
    .xs-w-35 {width:35% !important;}
    .xs-w-40 {width:40% !important;}
    .xs-w-45 {width:45% !important;}
    .xs-w-50 {width:50% !important;}
    .xs-w-55 {width:55% !important;}
    .xs-w-60 {width:60% !important;}
    .xs-w-65 {width:65% !important;}
    .xs-w-70 {width:70% !important;}
    .xs-w-75 {width:75% !important;}
    .xs-w-80 {width:80% !important;}
    .xs-w-85 {width:85% !important;}
    .xs-w-90 {width:90% !important;}
    .xs-w-95 {width:95% !important;}
    .xs-w-100 {width:100% !important;}
    .xs-w-auto {width: auto !important}

    .xs-w-50px {width:50px !important;}
    .xs-w-70px {width:70px !important;}
    .xs-w-75px {width:75px !important;}
    .xs-w-80px {width:80px !important;}
    .xs-w-85px {width:85px !important;}
    .xs-w-90px {width:90px !important;}
    .xs-w-95px {width:95px !important;}
    .xs-w-100px {width:100px !important;}
    .xs-w-120px {width:120px !important;}
    .xs-w-130px {width:130px !important;}
    .xs-w-150px {width:150px !important;}
    .xs-w-180px {width:180px !important;}
    .xs-w-200px {width:200px !important;}
    .xs-w-250px {width:250px !important;}
    .xs-w-300px {width:300px !important;}
    .xs-w-350px {width:350px !important;}
    .xs-w-400px {width:400px !important;}
    .xs-w-450px {width:450px !important;}
    .xs-w-500px {width:500px !important;}
    .xs-w-550px {width:550px !important;}
    .xs-w-600px {width:600px !important;}
    .xs-w-650px {width:650px !important;}
    .xs-w-700px {width:700px !important;}
    .xs-w-750px {width:750px !important;}
    .xs-w-800px {width:800px !important;}
    .xs-w-850px {width:850px !important;}
    .xs-w-900px {width:900px !important;}
    .xs-w-950px {width:950px !important;}
    .xs-w-1000px {width:1000px !important;}

    /* height */
    .xs-h-50 {height: 50% !important}
    .xs-h-100 {height: 100% !important}
    .xs-h-auto {height:auto !important}
    .xs-h-10px {height: 10px !important}
    .xs-h-20px {height: 20px !important}
    .xs-h-30px {height: 30px !important}
    .xs-h-40px {height: 40px !important}
    .xs-h-50px {height: 50px !important}
    .xs-h-60px {height: 60px !important}
    .xs-h-70px {height: 70px !important}
    .xs-h-80px {height: 80px !important}
    .xs-h-90px {height: 90px !important}
    .xs-h-100px {height: 100px !important}
    .xs-h-150px {height: 150px !important}
    .xs-h-200px {height: 200px !important}
    .xs-h-250px {height: 250px !important}
    .xs-h-300px {height: 300px !important}
    .xs-h-350px {height: 350px !important}
    .xs-h-400px {height: 400px !important}
    .xs-h-450px {height: 450px !important}
    .xs-h-500px {height: 500px !important}
    .xs-h-550px {height: 550px !important}
    .xs-h-600px {height: 600px !important}
    .xs-h-650px {height: 650px !important}
    .xs-h-700px {height: 700px !important}

    /* line height */
    .xs-line-height-10 {line-height:10px;}
    .xs-line-height-18 {line-height:18px;}
    .xs-line-height-20 {line-height:20px}
    .xs-line-height-24 {line-height:24px}
    .xs-line-height-22 {line-height:22px}
    .xs-line-height-26 {line-height:26px}
    .xs-line-height-28 {line-height:28px}
    .xs-line-height-30 {line-height:30px}
    .xs-line-height-35 {line-height:35px}
    .xs-line-height-40 {line-height:40px}
    .xs-line-height-45 {line-height:45px}
    .xs-line-height-50 {line-height:50px}
    .xs-line-height-55 {line-height:55px}
    .xs-line-height-60 {line-height:60px}
    .xs-line-height-65 {line-height:65px}
    .xs-line-height-70 {line-height:70px}
    .xs-line-height-75 {line-height:75px}
    .xs-line-height-80 {line-height:80px}
    .xs-line-height-85 {line-height:85px}
    .xs-line-height-90 {line-height:90px}
    .xs-line-height-95 {line-height:95px}
    .xs-line-height-100 {line-height:100px}

    /* border */
    .xs-no-border-top {border-top:0 !important}
    .xs-no-border-bottom {border-bottom:0 !important}
    .xs-no-border-right {border-right:0 !important}
    .xs-no-border-left {border-left:0 !important}
    .xs-no-border { border: 0 !important}

    /* top */
    .xs-top-auto {top:auto}
    .xs-top-inherit {top: inherit !important;}
    .xs-top-0 {top:0 !important}
    .xs-top-1 {top:1px}
    .xs-top-2 {top:2px}
    .xs-top-3 {top:3px}
    .xs-top-4 {top:4px}
    .xs-top-5 {top:5px}
    .xs-top-6 {top:6px}
    .xs-top-7 {top:7px}
    .xs-top-8 {top:8px}
    .xs-top-9 {top:9px}
    .xs-top-10 {top:10px}
    .xs-top-12 {top:12px}
    .xs-top-15 {top:15px}
    .xs-top-17 {top:17px}
    .xs-top-20 {top:20px}
    .xs-top-25 {top:25px}
    .xs-top-30 {top:30px}
    .xs-top-35 {top:35px}
    .xs-top-40 {top:40px}
    .xs-top-45 {top:45px}
    .xs-top-50 {top:50px}
    .xs-top-minus1 {top:-1px}
    .xs-top-minus2 {top:-2px}
    .xs-top-minus3 {top:-3px}
    .xs-top-minus4 {top:-4px}
    .xs-top-minus5 {top:-5px}
    .xs-top-minus6 {top:-6px}
    .xs-top-minus7 {top:-7px}
    .xs-top-minus8 {top:-8px}
    .xs-top-minus9 {top:-9px}
    .xs-top-minus10 {top:-10px}
    .xs-top-minus15 {top:-15px}
    .xs-top-minus20 {top:-20px}
    .xs-top-minus25 {top:-25px}
    .xs-top-minus30 {top:-30px}
    .xs-top-minus35 {top:-35px}
    .xs-top-minus40 {top:-40px}
    .xs-top-minus45 {top:-45px}
    .xs-top-minus50 {top:-50px}

    /* bottom */
    .xs-bottom-auto {bottom:auto}
    .xs-bottom-0 {bottom:0}
    .xs-bottom-1 {bottom:1px}
    .xs-bottom-2 {bottom:2px}
    .xs-bottom-3 {bottom:3px}
    .xs-bottom-4 {bottom:4px}
    .xs-bottom-5 {bottom:5px}
    .xs-bottom-6 {bottom:6px}
    .xs-bottom-7 {bottom:7px}
    .xs-bottom-8 {bottom:8px}
    .xs-bottom-9 {bottom:9px}
    .xs-bottom-10 {bottom:10px}
    .xs-bottom-20 {bottom:20px}
    .xs-bottom-25 {bottom:25px}
    .xs-bottom-30 {bottom:30px}
    .xs-bottom-35 {bottom:35px}
    .xs-bottom-40 {bottom:40px}
    .xs-bottom-45 {bottom:45px}
    .xs-bottom-50 {bottom:50px}
    .xs-bottom-minus30 {bottom:-30px}
    .xs-bottom-minus50 {bottom:-50px}
    .xs-bottom-minus55 { bottom: -55px; }
    .xs-bottom-minus60 { bottom: -60px; }

    /* left */
    .xs-left-auto {left:auto}
    .xs-left-0 {left:0}
    .xs-left-1 {left:1px}
    .xs-left-2 {left:2px}
    .xs-left-3 {left:3px}
    .xs-left-4 {left:4px}
    .xs-left-5 {left:5px}
    .xs-left-6 {left:6px}
    .xs-left-7 {left:7px}
    .xs-left-8 {left:8px}
    .xs-left-9 {left:9px}
    .xs-left-10 {left:10px}

    /* right */
    .xs-right-auto {right:auto}
    .xs-right-0 {right:0}
    .xs-right-1 {right:1px}
    .xs-right-2 {right:2px}
    .xs-right-3 {right:3px}
    .xs-right-4 {right:4px}
    .xs-right-5 {right:5px}
    .xs-right-6 {right:6px}
    .xs-right-7 {right:7px}
    .xs-right-8 {right:8px}
    .xs-right-9 {right:9px}
    .xs-right-10 {right:10px}

    /* counter */
    .counter-box-5 .counter-box {width: 25%}

    /* counter box style 4 */
    .counter-box-4 .counter::after {display: none;}

    /* feature box style 7 */
    .feature-box.feature-box-7 .box { border-left: 0; }
    .feature-box.feature-box-7 div:first-child .box { border-bottom: 0; }

    /* feature box style 14 */
    .feature-box-14 > div:nth-child(even) { border-right: 0}
    .feature-box-14 > div:nth-child(odd) { border-right: 0}

    /* feature-box-11 */
    .feature-box-11 .progress-line:after {visibility: hidden;}
}

/* ipad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    /* slider image */
    .parallax {background-attachment: inherit; background-position: center;}
}

/* ipad Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    /* slider image */
    .parallax {background-attachment: inherit !important; background-position: center !important;}
}

@media screen and (max-width: 55.1875em){
    .cbp-spmenu-horizontal {font-size: 75%;height: 110px;}
    .cbp-spmenu-top {top: -110px;}
    .cbp-spmenu-bottom {bottom: -110px;}
}

@media screen and (max-height: 26.375em){
    .cbp-spmenu-vertical {font-size: 90%;width: 190px;}
    .cbp-spmenu-left,.cbp-spmenu-push-toleft {left: -190px;}
    .cbp-spmenu-right {right: -190px;}
    .cbp-spmenu-push-toright {left: 190px;}
}

@media screen and (max-height: 320px){
    nav.navbar.bootsnav.no-full .navbar-collapse {max-height: 240px;}
}

@media only screen and (max-device-width: 1024px){
    table.dataTable td.dataTables_empty {
        width: 1% !important;
        text-align-last: center !important;
        border: hidden !important;
    }
}
